import { Component, OnInit, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { CommonConstants } from '../core/common.constants';
import { MessageModel } from '../core/message.model';
import { BuyerService } from '../service/buyer.service';
import { FirebaseHelperService } from '../core/firebase-helper.service';
import { finalize } from 'rxjs/operators';
import { ScrollEvent } from 'ngx-scroll-event';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip';
import * as JSZipSupport from 'jszip';
import * as moment from 'moment';
import { BuyerEventManager } from '../buyer-event.manager';
import { Router, ActivatedRoute } from '@angular/router';
import { BuyerSellerModal, SellerProfileUser } from '../buyermodal';
import { environment } from '../../../environments/environment';
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
// jQuery Sign $
declare let $: any;

@Component({
  selector: 'app-safetybox',
  templateUrl: './safetybox.component.html',
  styleUrls: ['./safetybox.component.scss'],

})
export class SafetyboxComponent implements OnInit {


  version: string;
  userFirstname: string;
  buyerId: number;
  buyerDisplayName: string;
  sellerDisplayName: string;
  agreedProjects: Array<any> = [];
  acceptedProjects: Array<any> = [];
  archiveProjects: Array<any> = [];
  completedProjects: Array<any> = [];
  archiveCompletedProjects: Array<any> = [];
  isCancelledArchive: boolean;
  // tslint:disable-next-line:no-inferrable-types
  righttabIdentify: string = 'summary';

  listofsellers: Array<any> = [];
  listofPreferedsellers: Array<any> = [];
  listofWaitingsellers: Array<any> = [];
  listofDeclinedsellers: Array<any> = [];
  indexpreffered: number;
  indexwaiting: number;
  indexdeclined: number;

  // Bussiness Information
  loaderModalActivate: boolean;
  buyerSellerModal: BuyerSellerModal;
  bussinessinfo: any = {};
  user: SellerProfileUser = new SellerProfileUser();
  @ViewChild('chartTarget') chartTarget: ElementRef;
  chart: Highcharts.ChartObject;
  uploadedimages: Array<any> = [];
  reviewList: Array<any> = [];
  isConfirmDecline: boolean;
  @ViewChild('closeDeclineModal') closeDeclineModal: ElementRef;

  projectDetails: any = {};
  isVisibleNewProject: boolean;
  loaderActivate: boolean;
  loaderMiddleActivate: boolean;
  loaderSummaryActivate: boolean;
  isDataAvailable: boolean;
  // tslint:disable-next-line:no-inferrable-types
  isActiveIndex: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  isActiveRequestIndex: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  isActiveArchiveIndex: number = 0;
  projectId: number;
  currentSellerId: number;
  currentSellerUserId: number;
  summaryInformation: any = {};
  projectOfferAgreements: Array<any> = [];
  agreementInformation: any = {};
  @ViewChild('closeSummaryModal') closeSummaryModal: ElementRef;
  @ViewChild('closereviewModalCenter') closereviewModalCenter: ElementRef;
  @ViewChild('closeprofileModalCenter') closeprofileModalCenter: ElementRef;
  @ViewChild('reviewHiddenButton') reviewHiddenButton: ElementRef;

  tokenreview: string;
  reviewObject: any = {
    token: '',
    rate_final_result: 0,
    rate_treatment: 0,
    rate_price: 0,
    rate_time: 0,
    rate_communication: 0,
    rate_recommendation: 0,
    comment: ''
  };

  summaryObject: any = {
    total_price: '',
    project_start_date: '',
    project_end_date: '',
    offer_expiry_date: '',
    sign_url: '',
    is_ongoing_bill: false,
    work_hourly_cost: 0,
    work_flow_hourly_cost: 0,
    sub_contract_cost_rate: 0,
    other_cost_rate: 0,
    travel_charge_details: ''
  };
  declineReason: string;
  isDeclineSelected: boolean;
  selectedSellerCompanyName: string;

   // tslint:disable-next-line:no-inferrable-types
   tabIndentification: string = 'activeProjects';

  // messaging section properties
  public messages: MessageModel[] = [];
  public initialSetOfMessages: Array<any> = []; // at initiall all the old messages are loaded from fire base and saved into this
  // tslint:disable-next-line:no-inferrable-types
  public messageToSend: string = '';

  public fileDataDisplay: Array<any> = [];
  public fileData: Array<any> = [];
  public currentUserId: number = null;
  // tslint:disable-next-line:no-inferrable-types
  public currentUserProfilePic: string = '';
  // tslint:disable-next-line:no-inferrable-types
  public recipientProfilePicNameInitials: string = '';
  // tslint:disable-next-line:no-inferrable-types
  private attachedFileType: string = '';
  public attachedFileDisplayUrl = '';
  public attachedFileDisplayName = '';
  // tslint:disable-next-line:no-inferrable-types
  public isScrollToBottom: boolean = true;
  // tslint:disable-next-line:no-inferrable-types
  public uploadingAttachment: boolean = false;
  // tslint:disable-next-line:no-inferrable-types
  public isLoadingAttachments: boolean = false;
  // tslint:disable-next-line:no-inferrable-types
  public loadingMoreMessages: boolean = false;
  // tslint:disable-next-line:no-inferrable-types
  private attachmentUrl: string = '';
  // tslint:disable-next-line:no-inferrable-types
  private messagesScrollStartIndex: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  private noOfMessagesPerScroll: number = 10;
  @ViewChild('messageTextBox') messageTextBox: ElementRef;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @ViewChildren('selectFileCheckBox') selectFileCheckBoxs: any;
  @ViewChild('deleteFilesConfirmationModal') deleteFilesConfirmationModal: ElementRef;
  fileuploaderrormessage: string;

  // files section properties
  public activityFiles: any[] = [];
  public selectedFileType: string = CommonConstants.FILE_TYPE_ALL;
  // tslint:disable-next-line:no-inferrable-types
  public noOfAllActivityFiles: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public loadingMoreFiles: boolean = false;
  public selectedFiles: any[] = [];
  // tslint:disable-next-line:no-inferrable-types
  public totalCount: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public imageCount: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public docCount: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public audioCount: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public videoCount: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public otherCount: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  public environmentFilter: string = 'external_activities';
  // tslint:disable-next-line:no-inferrable-types
  private filesStartNo: number = 0;
  // tslint:disable-next-line:no-inferrable-types
  private noOfFilesPerLoad: number = 12;
  @ViewChild('selectAllFilesCheckBox') public selectAllFilesCheckBox: any;
  // files section - constant data for html
  public fileTypeAll: string = CommonConstants.FILE_TYPE_ALL;
  public fileTypeImage: string = CommonConstants.FILE_TYPE_IMAGE;
  public fileTypeDoc: string = CommonConstants.FILE_TYPE_DOC;
  public fileTypeAudio: string = CommonConstants.FILE_TYPE_AUDIO;
  public fileTypeVideo: string = CommonConstants.FILE_TYPE_VIDEO;
  public fileTypeOther: string = CommonConstants.FILE_TYPE_OTHER;
  // tslint:disable-next-line:no-inferrable-types
  activeformatindex: number = 0;
  viewimageFilePath: string;

  unReadMessageCount: Array<any> = [];
  isInitialNotification: boolean;
  isAgreedNotification: boolean;
  isAcceptedNotification: boolean;
  defaultActiverequestProjectname: string;
  isActiverequestEnable: boolean;

  defaultActiveProjectname: string;
  isActiveProjectEnable: boolean;
  isDisableForPrefferedWaitingSellers: boolean;

  buyerEeviewInformation: any = {};
  isReviewfromOverview: boolean;
  isAdditionalAgreement: boolean;
  additionalagreementId: number;
  additionalAgreement: any = {};

  // Move to Header
  isMessageAvailable: boolean;
  activeSellerType: string;
  summaryActivities: Array<any> = [];
  certificates: Array<any> = [];

  active_request_safety_box_link: string;
  active_project_safety_box_link: string;
  isQueryparams: boolean;
  declineSeller: string;
  isOpenRequest: boolean;
  isAlreadyExistsReview: boolean;
  unsubscribeMessage: any;
  todisplayreviewSellercompanyname: string;
  archiveNotification: Array<any> = [];
  displayarchiveprojectlistnames: string;
  refreshIntervalId: any;
  buyerInformation: any = {};
  isAlreadyExitemail: boolean;
  isBuyerProfileEditable: boolean;
  fileDataPro: Array<any> = [];

  constructor(private buyerService: BuyerService, private firebaseHelperService: FirebaseHelperService,
    private buyereventManager: BuyerEventManager, private router: Router, private route: ActivatedRoute,
    private translate: TranslateService, private location: PlatformLocation, private authService: AuthService) {

    location.onPopState(() => {
      history.forward();
      this.router.navigateByUrl('/buyer/safetybox');
      return;
    });

    this.buyereventManager.sendActiveRequestSubmitEmitter.subscribe((mode) => {
      if (mode) {
        this.isActiverequestEnable = mode;
        this.callTabIdentify('activeRequests');
      }
    });

    this.buyereventManager.sendAgreedProjectEmitter.subscribe((mode) => {
      if (mode) {
        this.isActiveProjectEnable = mode;
        this.callTabIdentify('activeProjects');
      }
    });

    this.buyereventManager.sendActiveRequestProjectNameSubmitEmitter.subscribe((mode) => {
      this.defaultActiverequestProjectname = mode;
    });

    this.buyereventManager.sendAgreedProjectNameEmitter.subscribe((mode) => {
      this.defaultActiveProjectname = mode;
    });

  }

  ngOnInit() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      this.router.navigate(['/mobileview']);
      return;
    }
    this.version = environment.VERSION;
    this.buyerSellerModal = new BuyerSellerModal();
    this.active_request_safety_box_link = environment.frontendlink +
    '/seller/safetybox?requestto=safetybox&requesttype=maindecline&selectedtab=agreement&projectid=';
    this.active_project_safety_box_link = environment.frontendlink +
    '/seller/safetybox?requestto=safetybox&requesttype=additionaldecline&selectedtab=agreement&projectid=';
    this.userFirstname = localStorage.getItem('localEmailAddress');
    this.route.params.subscribe(params => {
      if (params['review'] === 'review') {
        if (params['token']) {
          this.tokenreview = params['token'];
          this.getSellerInformation(this.tokenreview);
          // if (navigator.userAgent.match('IOS') || navigator.userAgent.match('ios') ||
          //   navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPad')) {
          //  window.location.href = 'yossobuyerapp://review/' + this.tokenreview;
          // // window.location.href = 'https://google.com';
          // // alert('what the hell');
          // // return window.location.href;
          // } else {
            this.reviewHiddenButton.nativeElement.click();
          // }
        }
      }
    });

    // Query Parameter
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('requesttype')) {
        if (params['requesttype'] === 'activerequest') {
          this.tabIndentification = 'activeRequests';
        } else {
          this.tabIndentification = 'activeProjects';
        }

        if (params['selectedtab'] === 'agreement') {
          this.righttabIdentify = 'agreement';
          this.isActiveProjectEnable = true;
        } else {
          this.righttabIdentify = 'summary';
        }

        if (params['sellerid']) {
          this.currentSellerId = Number(params['sellerid']);
        }

        if (params['projectid']) {
          this.projectId = Number(params['projectid']);
          this.isQueryparams = true;
        }
      }
    });

   // this.callTabIdentify('activeRequests');
    this.loaderActivate = true;
    this.buyerService.getBuyerInformationByEmailId(this.userFirstname).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(res => {
      console.log(localStorage);
      console.log(res);
      if (res != null) {
        this.currentUserProfilePic = res.first_name.split('')[0] + res.last_name.split('')[0];
        this.buyerId = res.buyer_id;
        this.currentUserId = res.buyer_user_id;

        // After the Buyer Information Called the Project Infomation
        this.getBuyerInformationByBuyerId(this.buyerId);
        this.getActiveProjects(this.userFirstname);
        this.getActiveRequestProjects(this.userFirstname);
        this.getArchiveProjects(this.userFirstname);
        this.getNotificationArchivemoverecntly(this.buyerId);
      } else {
        this.loaderActivate = false;
      }
    }, err => {
      console.log(err);
      this.loaderActivate = false;
    });
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  // Get the Buyer Information By Buyer Id

  getBuyerInformationByBuyerId(buyerid){
    this.buyerService.getBuyerProfileInformation(buyerid).subscribe(res => {
      this.buyerInformation = res;
      if (res.contact_no === '0') {
        this.buyerInformation.contact_no = null;
      }
      console.log(this.buyerInformation);
    }, err => {
      console.log(err);
    });
  }

  //#region  Call Top Buttons
  // Get Seller Information using By Review Token
  getSellerInformation(token) {
    this.buyerService.getSellerInformationUsingReviewToken(token).subscribe(res => {
      if (res != null) {
        this.todisplayreviewSellercompanyname = res.seller_company_name;
        this.isAlreadyExistsReview = true;
      }
    }, err => {
      console.log(err);
    });
  }

  // Go to Home Page
  callCreateServiceRequestFromSafetyBox(): void  {
    this.router.navigate(['buyer/home', btoa(this.buyerId.toString())]);
    this.buyereventManager.sendPreferredList([]);
    this.buyereventManager.sendWaitingList([]);
    this.buyereventManager.isAuthenticatedFromUser(true);
  }

  //
  callCreateServiceRequestFromMap() {
    this.router.navigate(['buyer/servicerequest', 'maprequest', btoa(this.buyerId.toString())],
    { queryParams: { requestfrom: 'mapview' }});
    this.buyereventManager.sendPreferredList([]);
    this.buyereventManager.sendWaitingList([]);
    this.buyereventManager.isAuthenticatedFromUser(true);
  }

  //#endregion

  //#region  Get the List of Active Projects, Active Request and Archive
  // get All Active Request Projects By Username
  getActiveRequestProjects(username: string) {
    this.loaderActivate = true;
    this.buyerService.getActiveRequestProjects(username).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      if (response != null) {
        this.isInitialNotification = true;
        this.acceptedProjects = response;
        if (this.isActiverequestEnable) {
          this.acceptedProjects.filter((item, index) => {
            if (item.project_name === this.defaultActiverequestProjectname) {
              this.callProjectDetails(this.acceptedProjects[index], index);
              return;
            }
          });

        }

        if (this.isQueryparams && this.projectId !== null) {
          this.acceptedProjects.filter((item, index) => {
            if (item.project_id === this.projectId) {
              this.callProjectDetails(this.acceptedProjects[index], index);
              return;
            }
          });
        }

        for (let index = 0; index < this.acceptedProjects.length; index++) {
          this.acceptedProjects[index].preferred_sellers.forEach(element => {
            this.unReadMessageCount.push({
              'projectid': this.acceptedProjects[index].project_id,
              'status': 'accept',
              'sellerid': element.seller_id,
              'count': 0
            });
            this.getLatestUnreadMessageCount(this.acceptedProjects[index].project_id, element.seller_id);
          });
        }
      }
    }, err => {
      console.log(err);
    });
  }


  // Get the Active Projects List By username
  getActiveProjects(username) {
   // this.loaderActivate = true;
    this.buyerService.getActiveProjects(username).pipe(finalize(() => {
     // this.loaderActivate = false;
    })).subscribe(response => {
      if (response != null) {
        this.agreedProjects = response;
        if (!this.isActiveProjectEnable && this.agreedProjects.length > 0) {
          if (this.isQueryparams && this.projectId !== null) {
            this.agreedProjects.filter((item, index) => {
              if (item.project_id === this.projectId) {
                this.callProjectDetails(this.agreedProjects[index], index);
                return;
              }
            });
          } else {
            this.callProjectDetails(this.agreedProjects[0], 0);
          }
        } else {
          if (this.agreedProjects.length > 0) {
            if (this.defaultActiveProjectname != null) {
              this.agreedProjects.filter((item, index) => {
                if (item.project_name === this.defaultActiveProjectname) {
                  this.callProjectDetails(this.agreedProjects[index], index);
                  this.callRightTabIdentify('summary');
                  return;
                }
              });
            } else {
              this.callProjectDetails(this.agreedProjects[0], 0);
            }
          }
        }
        this.isInitialNotification = true;
        for (let index = 0; index < this.agreedProjects.length; index++) {
            this.unReadMessageCount.push({
              'projectid': this.agreedProjects[index].project_id,
              'status': 'active',
              'sellerid': this.agreedProjects[index].agreed_seller.seller_id,
              'count': 0
            });
            this.getLatestUnreadMessageCount(this.agreedProjects[index].project_id, this.agreedProjects[index].agreed_seller.seller_id);
        }
      }
    }, err => {
      console.log(err);
    });
  }


   // Get the Active Projects List By username
  getArchiveProjects(username) {
    this.loaderActivate = true;
    this.buyerService.getArchiveProjectList(username).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(res => {
      if (res != null) {
        this.archiveProjects = res.rejected_projects;
        this.completedProjects = res.completed_projects;
        if (this.isCancelledArchive) {
          this.archiveCompletedProjects = this.archiveProjects;
        } else {
          this.archiveCompletedProjects = this.completedProjects;
        }
      }
    }, err => {
      console.log(err);
    });
  }


   // Complete Change
   callCompletedArchiveTab(selected) {
     if (this.isCancelledArchive && selected === 'completed') {
       this.isCancelledArchive = false;
       this.archiveCompletedProjects = this.completedProjects;
       this.callTabIdentify('archive');
     } else if (!this.isCancelledArchive && selected === 'cancel') {
       this.isCancelledArchive = true;
       this.archiveCompletedProjects = this.archiveProjects;
       this.callTabIdentify('archive');
     }
  }


  // get the Archive Move notification
  getNotificationArchivemoverecntly(buyerid) {
    this.buyerService.get_project_Archive_Notification(buyerid).subscribe(res => {
      if (res != null || res.length > 0) {
        this.archiveNotification = res;
        this.displayarchiveprojectlistnames = this.archiveNotification.map((item) => item.project_name).join(', ');
      }
    }, err => {
      console.log(err);
    });
  }


  // Update the Archive Notifications
  updateArchiveNotification() {
    if (this.archiveNotification.length > 0) {
      const projectIds = this.archiveNotification.map((item) => item.project_id).join(',');
      this.buyerService.updateProjectArchiveNotificationstatus(projectIds).subscribe(res => {
        if (res != null || res.length > 0) {
          this.archiveNotification = [];
        }
      }, err => {
        console.log(err);
      });
    }

  }

  //#endregion

  //#region Message Notification section  MOVE TO HEADER SECTION
    // Get the UnRead Message Count for each project
    // async getLatestUnreadMessageCount(projectid: number, currentseller_id) {
    //  await this.firebaseHelperService.getLatestLogTime(projectid,
    //     currentseller_id, this.currentUserId).subscribe(res => {
    //       if (res.length > 0) {
    //         this.getLatestUnreadMessageCountByLatest(projectid, res[0].payload.val(), currentseller_id);
    //       }
    //     });
    // }
  async getLatestUnreadMessageCount(projectid: number, currentseller_id) {
    await this.firebaseHelperService.getAllMessages(projectid, currentseller_id, this.currentUserId).subscribe(res => {
      this.firebaseHelperService.getLatestLogTime(projectid, currentseller_id, this.currentUserId).then(res1 => {
        if (res1.val()) {
          this.firebaseHelperService.getLatestUnReadMessages(projectid, currentseller_id, res1.val().lastReadTime).then(res2 => {
            if (res2.val()) {
              this.isMessageAvailable = false;
              this.isAgreedNotification = false;
              this.isAcceptedNotification = false;
              this.unReadMessageCount.find(v => v.projectid === projectid && v.sellerid === currentseller_id).count = res2.numChildren();
              this.isAvailableMessages();
            }
          });
        }
      });
    });
  }

  // using Logtime and ProjectId //  get the messages count
  // async getLatestUnreadMessageCountByLatest(projectid, logtime, currentseller_id) {
  //   // tslint:disable-next-line:prefer-const
  //   let logmodi = moment(logtime).add(1, 'm').toDate();
  //   await this.firebaseHelperService.getLatestUnReadMessages(projectid,
  //     currentseller_id, new Date(logmodi).getTime()).subscribe(res => {
  //       if (res.length > 0) {
  //         this.isMessageAvailable = false;
  //         this.isAgreedNotification = false;
  //         this.isAcceptedNotification = false;
  //         this.unReadMessageCount.find(v => v.projectid === projectid && v.sellerid === currentseller_id).count = res.length;
  //         // console.log('will see formula   ' + this.unReadMessageCount);
  //         this.isAvailableMessages();
  //       }
  //     });
  // }

  // To show Notification in the Front page - Filter
    isAvailableMessages() {
      this.unReadMessageCount.filter(item => {
        if (item.count > 0) {
          this.isMessageAvailable = true;
          if (item.status === 'active') {
            this.isAgreedNotification = true;
          } else if (item.status === 'accept') {
            this.isAcceptedNotification = true;
          }
          return;
        }
      });
    }


     //#endregion MOVE END



  // Update Unread Messages
  loadUnreadMessageUpdate(value) {
    this.righttabIdentify = value;
    if (this.isInitialNotification) {
      this.isInitialNotification = false;
      this.firebaseHelperService.updateExternalMessageLog(this.projectId, this.currentSellerId, this.currentUserId);
      this.unReadMessageCount.find(v => v.projectid === this.projectId && v.sellerid === this.currentSellerId).count = 0;
      this.isMessageAvailable = false;
      this.isAgreedNotification = false;
      this.isAcceptedNotification = false;
      this.isAvailableMessages();
      this.getTodisplayMeesageCount(this.projectId);
    } else {
      if (this.righttabIdentify === 'message') {
        this.firebaseHelperService.updateExternalMessageLog(this.projectId, this.currentSellerId, this.currentUserId);
        this.unReadMessageCount.find(v => v.projectid === this.projectId && v.sellerid === this.currentSellerId).count = 0;
        this.isMessageAvailable = false;
        this.isAgreedNotification = false;
        this.isAcceptedNotification = false;
        this.isAvailableMessages();
        this.getTodisplayMeesageCount(this.projectId);
      }
    }
  }


  // Display the Unread Messsage Count
  getTodisplayMeesageCount(projectid) {
    // tslint:disable-next-line:prefer-const
    let count = 0;
    // tslint:disable-next-line:prefer-const
    let object = this.unReadMessageCount.filter(item => item.projectid === projectid);
    if (object.length > 0) {
      object.map(item => {
        count = count + item.count;
      });
      if (count > 0) {
        if (object[0].status === 'active') {
          this.isAgreedNotification = true;
        } else if (object[0].status === 'accept') {
          this.isAcceptedNotification = true;
        }
      }
    }
    return count;
  }

   // Display the Unread Messsage Count for SELLER
   getTodisplaySellerMeesageCount(sellerid) {
    // tslint:disable-next-line:prefer-const
    let count = 0;
    // tslint:disable-next-line:prefer-const
    let object = this.unReadMessageCount.filter(item => item.projectid === this.projectId && item.sellerid === sellerid);
    if (object.length > 0) {
      count = object[0].count;
      if (count > 0) {
        if (object[0].status === 'active') {
          this.isAgreedNotification = true;
        } else if (object[0].status === 'accept') {
          this.isAcceptedNotification = true;
        }
      }
    }
    return count;
  }

   // Identify the Tab Change Left Side
   callTabIdentify(tabvalue): void {
    switch (tabvalue) {
      case 'activeProjects':
        this.tabIndentification = 'activeProjects';
        this.isVisibleNewProject = true;
        if (this.agreedProjects.length > 0) {
          this.isDataAvailable = true;
          this.callProjectDetails(this.agreedProjects[0], 0);
        } else {
          this.isDataAvailable = false;
        }
        break;
      case 'activeRequests':
        this.tabIndentification = 'activeRequests';
        this.isVisibleNewProject = false;
        if (this.acceptedProjects.length > 0) {
          this.isDataAvailable = true;
          this.callProjectDetails(this.acceptedProjects[0], 0);
        } else {
          this.isDataAvailable = false;
        }
        break;
      case 'archive':
        this.isVisibleNewProject = false;
        this.tabIndentification = 'archive';
        if (this.archiveCompletedProjects.length > 0) {
          this.isDataAvailable = true;
          this.callProjectDetails(this.archiveCompletedProjects[0], 0);
        } else {
          this.isDataAvailable = false;
        }
        break;
      default:
    }
  }

  // Call Right Panel Tab Identifier
  callRightTabIdentify(value) {
    this.righttabIdentify = value;
  }




  // Call the Project details
  callProjectDetails(project: any, index: number): void {
    this.activeSellerType = '';
    this.summaryActivities = [];
    this.summaryInformation = {};
   // this.loaderMiddleActivate = true;
    this.isDataAvailable = true;
    if (this.tabIndentification === 'activeProjects') {
      this.isActiveIndex = index;
    } else if (this.tabIndentification === 'activeRequests') {
      this.isActiveRequestIndex = index;
    } else if (this.tabIndentification === 'archive') {
      this.isActiveArchiveIndex = index;
    }


    // Just tab Color change || when switch from open to normal request
    if (this.isOpenRequest) {
      this.callRightTabIdentify('summary');
    }

    this.isOpenRequest = true;
    // Clear Previous Information By changing Project
    this.projectOfferAgreements = [];
    this.messages = [];
    this.initialSetOfMessages = [];
    this.filesStartNo = 0;
    this.activityFiles = [];
    this.selectedFiles = [];
    this.totalCount = 0;
    this.imageCount = 0;
    this.docCount = 0;
    this.audioCount = 0;
    this.videoCount = 0;
    this.otherCount = 0;
    this.listofPreferedsellers = [];
    this.listofWaitingsellers = [];
    this.listofDeclinedsellers = [];

    // End Clear Informations

    this.projectId = project.project_id;
    this.selectedFileType = CommonConstants.FILE_TYPE_ALL;
    this.projectDetails = project;

    if (project.preferred_sellers != null && project.preferred_sellers.length > 0) {
      this.listofPreferedsellers = project.preferred_sellers;
      this.isOpenRequest = false;
    }
    if (project.waiting_sellers != null && project.waiting_sellers.length > 0) {
      this.listofWaitingsellers = project.waiting_sellers;
      this.isOpenRequest = false;
    }

    if (project.declined_sellers != null && project.declined_sellers.length > 0) {
      this.listofDeclinedsellers = project.declined_sellers;
      this.isOpenRequest = false;
    }

    if (this.tabIndentification === 'activeProjects' || this.tabIndentification === 'archive') {
      if (project.agreed_seller != null) {
        this.listofPreferedsellers.push(project.agreed_seller);
        this.isOpenRequest = false;
      }
    }

    if (this.listofPreferedsellers.length > 0) {
      if (this.isQueryparams && this.currentSellerId !== null) {
        this.listofPreferedsellers.filter((item, index1) => {
          if (item.seller_id === this.currentSellerId) {
            this.callSellersDetails(this.listofPreferedsellers[index1], index1, 'accepted');
            return;
          }
        });
      } else {
        this.callSellersDetails(this.listofPreferedsellers[0], 0, 'accepted');
      }
    } else {
      if (this.listofPreferedsellers.length === 0 && this.listofDeclinedsellers.length > 0) {
        this.callSellersDetails(this.listofDeclinedsellers[0], 0, 'declined');
        this.isOpenRequest = false;
      }
    }

    // Display Scope Tab when no preffered sellers and declined sellers
    if (this.isOpenRequest) {
      this.callRightTabIdentify('scopetab');
    }

  //  this.loaderMiddleActivate = false;
    // this.buyerService.getProjectInformationByProjectId(this.projectId).pipe(finalize(() => {
    //    this.loaderMiddleActivate = false;
    // })).subscribe(res => {
    //   if (res != null) {
    //     // this.projectDetails = res;
    //     this.currentUserId = res.buyer_user_id;
    //     // this.getServiceRequestByprojectId(this.projectId, this.currentUserId);
    //     if (this.tabIndentification === 'activeProjects') {
    //       // WILL MOVE SOME WHERE
    //       this.isInitialNotification = true;
    //       //for (let nindex = 0; nindex < this.acceptedProjects.length; nindex++) {
    //         this.unReadMessageCount.push({ 'projectid': this.projectId, 'count': 0 });
    //         this.getLatestUnreadMessageCount(this.projectId, this.listofPreferedsellers[0].seller_id );
    //       //}
    //     }

    //     // WILL MOVE SOME WHERE

    //   }
    // }, err => {
    //   console.log(err);
    // });
  }

  // UTC to GMT
  getConverttoUTCtoGMT(date) {
    // tslint:disable-next-line:prefer-const
     let stillUtc = moment.utc(date).toDate();
     return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
  }

  // Call Sellers By Seller Id
  callSellersDetails(sellerInfo: any, index: number, typename: string): void {
    this.activeSellerType = typename;
    this.summaryActivities = [];

    // Unsubscribe Message All --
    if (this.unsubscribeMessage) {
      this.unsubscribeMessage.unsubscribe();
    }
    // this.isDisableForPrefferedWaitingSellers = false;
    if (this.isQueryparams && this.righttabIdentify === 'agreement' ) {
      this.callRightTabIdentify(this.righttabIdentify);
    }
    switch (typename) {
      case 'accepted':
        this.indexpreffered = index;
        this.indexwaiting = null;
        this.indexdeclined = null;
        if (sellerInfo.is_accepted === undefined || sellerInfo.is_accepted === null) {
          this.isDisableForPrefferedWaitingSellers = true;
        } else {
          this.isDisableForPrefferedWaitingSellers = sellerInfo.is_accepted;
        }

        break;
      case 'waiting':
        this.indexpreffered = null;
        this.indexwaiting = index;
        this.indexdeclined = null;
        break;
      case 'declined':
        this.indexpreffered = null;
        this.indexwaiting = null;
        this.indexdeclined = index;
        this.isDisableForPrefferedWaitingSellers = true;
        break;
      default:
        break;
    }
    this.currentSellerId = sellerInfo.seller_id;
    this.currentSellerUserId = sellerInfo.seller_user_id;
    this.sellerDisplayName = sellerInfo.first_name + ' ' + sellerInfo.last_name ;
    this.recipientProfilePicNameInitials = sellerInfo.seller_image_url;
    this.projectOfferAgreements = [];
    this.getAgreementFilePath(this.projectId, this.currentSellerId);
    this.getCertificatesforBuyer(this.currentSellerId);
    if (this.tabIndentification === 'archive') {
      this.getBuyerReviewInformationForSeller(this.userFirstname, this.projectId, this.currentSellerId);
    }
    this.selectedSellerCompanyName = sellerInfo.company_name;
    this.todisplayreviewSellercompanyname = sellerInfo.company_name;
    this.loaderSummaryActivate = true;
    this.buyerService.get_service_request_summary(this.userFirstname,
      this.currentSellerId, this.projectId).pipe(finalize(() => {
        this.loaderSummaryActivate = false;
      })).subscribe(res => {
        if (res != null) {
          this.summaryActivities = [];
          this.summaryInformation = res;
          // tslint:disable-next-line:no-shadowed-variable
          for (let index = 0; index < res.activities.length; index++) {
            const element = res.activities[index];
            if (element.length > 1) {
              if (element[1] != null) {
                // tslint:disable-next-line:prefer-const
                let obj = { 'activity': element[0], 'date': element[1]};
                this.summaryActivities.push(obj);
              }
            }
          }
          if (this.summaryInformation.service_request_accepted_date != null) {
            this.summaryInformation.service_request_accepted_date =
              this.momentDateFormat(this.summaryInformation.service_request_accepted_date, 'YYYY-MM-DD');
          }

          if (this.summaryInformation.seller_seen_date != null) {
            this.summaryInformation.seller_seen_date =
              this.momentDateFormat(this.summaryInformation.seller_seen_date, 'YYYY-MM-DD');
          }

          if (this.summaryInformation.agreement_created_date != null) {
            this.summaryInformation.agreement_created_date =
              this.momentDateFormat(this.summaryInformation.agreement_created_date, 'YYYY-MM-DD');
          }

        }
      }, err => {
        console.log(err);
      });


    // Remove Previous messages from projects
    this.messages = [];
    this.initialSetOfMessages = [];

    this.messagesScrollStartIndex = 0;
    // Restricting the Calling the Chat and Activity Files
    if (this.activeSellerType === 'accepted' || this.activeSellerType === 'declined') {
      // get all messages initially
      // tslint:disable-next-line:prefer-const
      let getAllMessagesOnIntinSubscription = this.firebaseHelperService.getAllMessages(
        this.projectDetails.project_id, this.currentSellerId, this.projectDetails.buyer_id).subscribe(allMessages => {

          // when no messages are available
          if (allMessages.length === 0) {
            this.messages = [];
            this.initialSetOfMessages = [];
          } else {

            // convert data into message model and attach push key as id

            allMessages.forEach(element => {
              let messageModel = element.payload.val();
              if (messageModel) {
                messageModel = messageModel;
                messageModel['id'] = element.key;
                this.initialSetOfMessages.push(messageModel);
              }
            });

            // tslint:disable-next-line:max-line-length
            // after getting messages initially unsubcribe all messages watching (or else this method will get all the messages everytime database update)
            getAllMessagesOnIntinSubscription.unsubscribe();

            // from the initial set of messages extract set of messages to display on initial
            this.messagesScrollStartIndex = this.initialSetOfMessages.length - 1;
            this.getMessagesToDisplay();

          }

          // open get latest message subscription
          this.getLatestMessages();
          this.isScrollToBottom = true;

        });

      // load Files tab data
      this.environmentFilter = 'external_activities';
      this.activeformatindex = 0;
      this.fileTypeSelected(CommonConstants.FILE_TYPE_ALL);

      // Message Count Reset Option Particular Project and Seller
      if (!this.isInitialNotification && this.righttabIdentify === 'message') {
        this.firebaseHelperService.updateExternalMessageLog(this.projectId, this.currentSellerId, this.currentUserId);
        this.unReadMessageCount.find(v => v.projectid === this.projectId && v.sellerid === this.currentSellerId).count = 0;
        this.isMessageAvailable = false;
        this.isAgreedNotification = false;
        this.isAcceptedNotification = false;
        this.isAvailableMessages();
        this.getTodisplayMeesageCount(this.projectId);
      }


    }


  }


  public messagesContainerOnScroll(event: ScrollEvent) {
    // // if scroll reached top and if window is not scrolled && another message set to display is not generating
    if (!event.isWindowEvent && event.isReachingTop && !this.loadingMoreMessages) {
      this.isScrollToBottom = false;
      this.getMessagesToDisplay();
    }

  }

  // from the initial set of messages, extract set of messages to display on scroll
  getMessagesToDisplay() {
    this.loadingMoreMessages = true;

    if (this.noOfMessagesPerScroll === 0) {
      return;
    }

    // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:prefer-const
    let startIndex = (this.messagesScrollStartIndex - this.noOfMessagesPerScroll < 0) ? 0 :
    this.messagesScrollStartIndex - this.noOfMessagesPerScroll;

    // tslint:disable-next-line:prefer-const
    let messagesToAdd = this.initialSetOfMessages.slice(startIndex, this.messagesScrollStartIndex);
    if (messagesToAdd.length > 0) {
      this.messages = messagesToAdd.concat(this.messages);
    }
    this.messagesScrollStartIndex = startIndex;
    this.loadingMoreMessages = false;
  }

   // get latest messages - and keep this subscription open to get only latest/new message when database is update
  // attach latest message into messages array
  public getLatestMessages() {
    this.loadingMoreMessages = true;

    this.unsubscribeMessage = this.firebaseHelperService.getLatestMessages(this.projectDetails.project_id,
      this.currentSellerId, this.projectDetails.buyer_id)
      .subscribe(latestMessages => {
        // if latest message is not the messages array, add it into array
        if (latestMessages && latestMessages.length > 0) {
          // tslint:disable-next-line:prefer-const
          let messagearray: Array<any> = [];
          if (this.messages.length > 10) {
            messagearray = this.messages.slice(Math.max(this.messages.length - 10, 1));
          } else {
            messagearray = this.messages;
          }
          // tslint:disable-next-line:prefer-const
          let latestMessage = latestMessages[0].payload.val();
          if (latestMessage && (this.messages.length === 0 || messagearray.every(m => m.id !== latestMessages[0].key) )) {
              // tslint:disable-next-line:prefer-const
              let messageModel = <MessageModel>latestMessage;
              messageModel.id = latestMessages[0].key;
              this.messages.push(messageModel);

          }
        }
        this.loadingMoreMessages = false;
      });
  }

   // View Image By Click
   viewImageFile(filepath) {
    this.viewimageFilePath = filepath;
  }

  public fileTypeSelected(selectedFileType: string) {
    this.selectedFileType = selectedFileType;
    this.filesStartNo = 0;
    this.activityFiles = [];
    this.selectedFiles = [];
    if (this.selectAllFilesCheckBox) {
      this.selectAllFilesCheckBox.nativeElement.checked = false;
    }

    if (this.environmentFilter === 'external_activities') {
      this.getActivityFiles(CommonConstants.ACTIVITY_TYPE_EXTERNAL);
    } else {
      this.getActivityFiles(CommonConstants.ACTIVITY_TYPE_INTERNAL);
    }
  }

   // get activity files
   public getActivityFiles(activity_type: string) {
    if (this.filesStartNo > this.activityFiles.length) {
      return;
    }
    this.loadingMoreFiles = true;
    this.buyerService.getActivityFiles(this.projectDetails.project_id, this.currentUserId, activity_type,
      this.selectedFileType, this.filesStartNo, this.noOfFilesPerLoad).subscribe(res => {
      if (res && res.total_count && res.data) {
        this.noOfAllActivityFiles = res.total_count;

        if (this.activityFiles.length > 0) {
          this.activityFiles = this.activityFiles.concat(res.data);
        } else {
          this.activityFiles = res.data;
        }

        this.filesStartNo = this.filesStartNo + this.noOfFilesPerLoad;

        // bind data to counts display
        if (this.selectedFileType === CommonConstants.FILE_TYPE_ALL) {
          this.totalCount = (res.total_count) ? res.total_count : 0;
          this.imageCount = (res.image_count) ? res.image_count : 0;
          this.docCount = (res.doc_count) ? res.doc_count : 0;
          this.audioCount = (res.audio_count) ? res.audio_count : 0;
          this.videoCount = (res.video_count) ? res.video_count : 0;
          this.otherCount = (res.other_count) ? res.other_count : 0;
        }

      } else {
        if (this.activeformatindex === 0) {
          this.totalCount = (res.total_count) ? res.total_count : 0;
          this.imageCount = (res.image_count) ? res.image_count : 0;
          this.docCount = (res.doc_count) ? res.doc_count : 0;
          this.audioCount = (res.audio_count) ? res.audio_count : 0;
          this.videoCount = (res.video_count) ? res.video_count : 0;
          this.otherCount = (res.other_count) ? res.other_count : 0;
        }

      }
      this.loadingMoreFiles = false;

    }, err => {
      console.log(err);
      this.loadingMoreFiles = false;
    });
  }



  // attach file by upload button click
  public attachFileByInputClick(event) {
    this.fileData = [];
    if (event.target.files && event.target.files[0]) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.attachFile(event.target.files[index]);
      }
    }
  }

  // attach file by drop file into drop zone
  public attachFileByDrop(event: UploadEvent) {
    this.fileData = [];
    // tslint:disable-next-line:prefer-const
    // let droppedFile = event.files[0];
    if (event.files && event.files[0]) {
      for (let index = 0; index < event.files.length; index++) {
       // this.attachFile(event.files[index]);
        const fileEntry = event.files[index].fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file) {
            this.attachFile(file);
          }
        });
      }
    }

  }

  // attaching the file that uploaded by drag drop and by button click
  private attachFile(file: File) {
    this.attachedFileDisplayName = '';
    // set attachment file type and validate file size according to file type
    if (!this.setFileTypeAndValidate(file.type, file.size, file.name, 'External')) {
      this.fileData = [];
      return;
    }

    this.fileData.push({ file: file, type: this.attachedFileType });

    // to display attached file
    this.attachedFileDisplayName = file.name;

    if (this.attachedFileType === CommonConstants.FILE_TYPE_IMAGE) {
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.attachedFileDisplayUrl = event.target.result;
        this.fileDataDisplay.push({
          name: this.attachedFileDisplayName,
          url: this.attachedFileDisplayUrl
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.fileDataDisplay.push({
        name: this.attachedFileDisplayName,
        url: this.attachedFileDisplayUrl
      });
    }
  }



  // upload attachments, send message and clear message box
  public sendMessageBtnClicked() {
    // if attachment is available, upload it to s3 bucket
    if (this.fileData.length > 0) {
      this.uploadAttachedFile();
    } else {
      if (this.messageToSend) {
        this.messageToSend = this.messageToSend.substring(0, this.messageToSend.length - 1);
        if (this.messageToSend) {
          this.sendMessage();
        }
      } else {
        this.messageToSend = '';
      }
    }

  }

  // upload attached file into s3 bucket
  public uploadAttachedFile() {
    if (!this.fileData || this.fileData.length <= 0) {
      return;
    }

    let filename = '';
    let link = '';
    this.fileData.forEach((element, index) => {
      this.uploadingAttachment = true;
      this.attachedFileType = element.type;
      filename = element.file.name;
      // call file upload
      this.buyerService.uploadAttachedFile(element.file, this.projectDetails.project_id, this.currentUserId,
        this.attachedFileType, CommonConstants.ACTIVITY_TYPE_EXTERNAL, true).subscribe(res => {
          if (res !== 'No file part') {
            this.attachmentUrl = res.activity_url;
            // send message
            // tslint:disable-next-line:prefer-const
            let newMessage = new MessageModel(this.currentUserId,
              this.currentSellerUserId, '',
              this.attachmentUrl, res.file_type, new Date().toUTCString(), 1, filename);
            this.firebaseHelperService.sendMessage(this.projectDetails.project_id,
              this.currentSellerId, this.currentUserId, newMessage);

              if (this.tabIndentification === 'activeProjects') {
                link = environment.frontendlink +
                  '/buyer/safetybox?requestto=safetybox&requesttype=externalpro&projectid='
                  + this.projectDetails.project_id + '&sellerid=' + this.currentSellerId;
              } else {
                link = environment.frontendlink +
                  '/buyer/safetybox?requestto=safetybox&requesttype=externalreq&projectid='
                  + this.projectDetails.project_id + '&sellerid=' + this.currentSellerId;
              }

            // call Push notification
            this.sendPushNotification(this.projectDetails.project_id,
              this.currentUserId, this.currentSellerUserId,
              this.getpushnotificationfiletype(res.file_type), link);

            // Call IOS Push Notification
            this.sendPushNotification_ios(this.projectDetails.project_id,
              this.currentUserId, this.currentSellerUserId,
              this.getpushnotificationfiletype(res.file_type), link);

            // clear message typing text box and attachment details
            // this.messageToSend = '';
            this.uploadingAttachment = false;
            this.removeAttachedFile();
          }
        }, err => {
          console.log(err);
          this.uploadingAttachment = false;
        });
      if (this.fileData.length - 1 === index) {
        this.removeAttachedFile();
        this.isScrollToBottom = true;
      }
    });

  }

  // Upload Attachments, Send Message for Enter Functions
  public sendEnterFunctionSendMessageBtn($event) {
     this.sendMessageBtnClicked();
  }

  // send message - save message data into firebase database
  private sendMessage() {
    let link = '';
    // send message
    // tslint:disable-next-line:prefer-const
    let newMessage = new MessageModel(this.currentUserId,
      this.currentSellerUserId, this.messageToSend,
      this.attachmentUrl, this.attachedFileType, new Date().toUTCString(), 1, '');
    this.firebaseHelperService.sendMessage(this.projectDetails.project_id,
      this.currentSellerId, this.currentUserId, newMessage);

      if (this.tabIndentification === 'activeProjects') {
        link = environment.frontendlink +
          '/buyer/safetybox?requestto=safetybox&requesttype=externalpro&projectid='
          + this.projectDetails.project_id + '&sellerid=' + this.currentSellerId;
      } else {
        link = environment.frontendlink +
          '/buyer/safetybox?requestto=safetybox&requesttype=externalreq&projectid='
          + this.projectDetails.project_id + '&sellerid=' + this.currentSellerId;
      }

      // call Push notification
      this.sendPushNotification(this.projectDetails.project_id,
        this.currentUserId, this.currentSellerUserId, this.messageToSend, link);

    // Call IOS Push Notification
    this.sendPushNotification_ios(this.projectDetails.project_id,
      this.currentUserId, this.currentSellerUserId, this.messageToSend, link);

    this.removecountFromUnload(this.projectDetails.project_id, this.currentSellerId);
    // clear message typing text box and attachment details
    this.messageToSend = '';
    this.removeAttachedFile();
    this.isScrollToBottom = true;
  }


    // Update Unread Messages
  removecountFromUnload(projectid, currentsellerid) {
   // this.unReadMessageCount.find(v => v.projectid === projectid && v.sellerid === currentsellerid).count = 0;
    this.unReadMessageCount.forEach((item, index) => {
      if (item.projectid === projectid && item.sellerid === currentsellerid) {
        this.unReadMessageCount[index].count = 0;
      }
    });
  // this.isMessageAvailable = false;
  // this.isAgreedNotification = false;
  // this.isAcceptedNotification = false;
  // this.isAvailableMessages();
   // this.getTodisplayMeesageCount(projectid);
  }


  // return thumbnail image to display by attachment url
  public getMessageFileThumbnail(fileType: string, fileUrl: string) {
    return this.buyerService.getThumbnailImages(fileType, fileUrl);
  }

  // convert server timestamp value to date type and return
  public getDate(timestamp: string): Date {
    return new Date(timestamp);
  }


  // push notification file type
  getpushnotificationfiletype(filetype) {
    if (filetype === 'image') {
      return 'Image file';
    } else if (filetype === 'video') {
      return 'Video file';
    } else if (filetype === 'audio') {
      return 'Audio file';
    } else {
      return 'Document';
    }
  }

  // send push notification
  sendPushNotification(projectid, sendderid, recipient_id, message, link) {
    const body = {
      project_id: projectid,
      sender_id: sendderid,
      recipient_id: recipient_id,
      message: message,
      link: link
    };
    this.buyerService.sendPushNotification(body).subscribe(res => {
      if (res != null) {
      }
    }, err => {
      // console.log(err);
    });
  }

  sendPushNotification_ios(projectid, sendderid, recipient_id, message, link) {
    const body = {
      project_id: projectid,
      sender_id: sendderid,
      recipient_id: recipient_id,
      message: message,
      link: link
    };
    this.buyerService.sendPushNotification_ios(body).subscribe(res => {
      if (res != null) {
      }
    }, err => {
      // console.log(err);
    });
  }

 // upload attached file into s3 bucket
 public uploadFileUsingFilesection() {
  if (!this.fileData || this.fileData.length <= 0) {
    return;
  }
  let namedirect = '';
  if (this.environmentFilter === 'external_activities') {
    namedirect = CommonConstants.ACTIVITY_TYPE_EXTERNAL;
  } else {
    namedirect = CommonConstants.ACTIVITY_TYPE_INTERNAL;
  }

  this.fileData.forEach((element, index) => {
    this.uploadingAttachment = true;
    // call file upload
    this.buyerService.uploadAttachedFile(element.file, this.projectDetails.project_id, this.currentUserId,
      this.attachedFileType, namedirect, false).subscribe(res => {
        if (res !== 'No file part') {
          this.attachmentUrl = res.activity_url;
          this.uploadingAttachment = false;
        }

      }, err => {
        console.log(err);
        this.uploadingAttachment = false;
      });
    if (this.fileData.length - 1 === index) {
      this.removeAttachedFile();
      this.isScrollToBottom = true;
      this.activityFiles = [];
      this.filesStartNo = 0;
      setTimeout(() => {
        if (this.environmentFilter === 'external_activities') {
          this.getActivityFiles(CommonConstants.ACTIVITY_TYPE_EXTERNAL);
        } else {
          this.getActivityFiles(CommonConstants.ACTIVITY_TYPE_INTERNAL);
        }
        clearInterval(this.refreshIntervalId);
      }, 1000);
    }
  });

}




    // remove attachment
    public removeAttachedFile() {
      this.attachmentUrl = '';
      this.attachedFileDisplayUrl = '';
      this.attachedFileDisplayName = '';
      this.attachedFileType = '';
     // this.fileuploaderrormessage = '';
      this.fileDataDisplay = [];
      this.fileData = [];
    }


  // Remove Uploaded Files
  removeAttachedFileUpload(item: any, ind: number): void {
    if (this.fileDataDisplay.length > ind) {
      this.fileDataDisplay.splice(ind, 1);
      this.fileData.forEach((element, index) => {
        if (element.file.name === item.name) {
          this.fileData.splice(index, 1);
        }
      });
    }
  }

    // update select file list
    public selectFileCheckBoxChanged(isChecked: boolean, file: any) {

      // if check add to selected file
      if (isChecked) {
        this.selectedFiles.push(file);
      } else {
        // tslint:disable-next-line:prefer-const
        let indexOfItemToRemove = this.selectedFiles.indexOf(file);
        if (indexOfItemToRemove > -1) {
          this.selectedFiles.splice(indexOfItemToRemove, 1);
        }
      }
    }


  // set attachment file type and validate file size according to file type
  private setFileTypeAndValidate(fileType: string, sizeInBytes: number, name: string, activitytype: string) {
    this.fileuploaderrormessage = '';
    let mainFileType = fileType.split('/')[0];

    // to determine if this is a document file or not
    if (mainFileType === 'application') {
      // tslint:disable-next-line:prefer-const
      let extension = name.split('.').splice(-1)[0].toLocaleLowerCase();
      mainFileType = this.buyerService.getMainFileType(extension);
    } else if (mainFileType === 'text') {
      // tslint:disable-next-line:prefer-const
      let extension = name.split('.').splice(-1)[0].toLocaleLowerCase();
      mainFileType = this.buyerService.getMainFileType(extension);
    } else if (mainFileType === '') {
      // tslint:disable-next-line:prefer-const
      let extension = name.split('.').splice(-1)[0].toLocaleLowerCase();
      mainFileType = this.buyerService.getMainFileType(extension);
      if (mainFileType == null) {
        if (extension === 'mp3' || extension === 'ogg' || extension === 'wav'
          || extension === 'webm' || extension === 'mmf' || extension === 'm4p' || extension === 'msv') {
          mainFileType = 'audio';
        } else if (extension === 'flv'
          || extension === 'mov' || extension === 'mp4' || extension === 'avi' || extension === 'mpeg' || extension === 'wmv') {
          mainFileType = 'video';
        } else {
          // this.fileuploaderrormessage = 'File type isnot supported';
          this.translate.get('Common.Filetypesupported').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        }
      }
      // if (extension === 'docx' || extension === 'pdf'
      //   || extension === 'doc' || extension === 'txt' || extension === 'odt' || extension === 'csv' ||
      //   extension === 'xlsx' || extension === 'tex') {
      //   mainFileType = 'doc';
      // } else
    }



    switch (mainFileType) {

      case 'image':
        if (sizeInBytes > 3000000) { // image validation cannot exceed 2mb
         // this.fileuploaderrormessage = 'Maximum image size cannot exceed 2mb';
          this.translate.get('Common.Maximumimageexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_IMAGE;
          return true;
        }

      case 'doc':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
          // this.fileuploaderrormessage = 'Maximum document size cannot exceed 50mb';
          this.translate.get('Common.Maximumdocumentexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_DOC;
          this.attachedFileDisplayUrl = '../assets/images/doc.png';
          return true;
        }

      case 'pdf':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
          // this.fileuploaderrormessage = 'Maximum pdf size cannot exceed 50mb';
          this.translate.get('Common.Maximumpdfexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_PDF;
          this.attachedFileDisplayUrl = '../assets/images/pdf-icon.png';
          return true;
        }

      case 'key':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
          // this.fileuploaderrormessage = 'Maximum key size cannot exceed 50mb';
          this.translate.get('Common.Maximumkeyexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_KEY;
          this.attachedFileDisplayUrl = '../assets/images/key-icon.png';
          return true;
        }

      case 'numbers':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
         // this.fileuploaderrormessage = 'Maximum numbers size cannot exceed 50mb';
          this.translate.get('Common.Maximumnumbersexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_NUMBERS;
          this.attachedFileDisplayUrl = '../assets/images/numbers-icon.png';
          return true;
        }

      case 'pages':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
         // this.fileuploaderrormessage = 'Maximum pages size cannot exceed 50mb';
          this.translate.get('Common.Maximumpagesexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_PAGES;
          this.attachedFileDisplayUrl = '../assets/images/pages-icon.png';
          return true;
        }

      case 'ppt':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
          // this.fileuploaderrormessage = 'Maximum ppt size cannot exceed 50mb';
          this.translate.get('Common.Maximumpptexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_PPT;
          this.attachedFileDisplayUrl = '../assets/images/ppt-icon.png';
          return true;
        }

      case 'txt':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
         // this.fileuploaderrormessage = 'Maximum text size cannot exceed 50mb';
          this.translate.get('Common.Maximumtextexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_TXT;
           this.attachedFileDisplayUrl = '../assets/images/text-icon.png';
          return true;
        }

      case 'excel':
        if (sizeInBytes > 50000000) {// doc validation cannot exceed 50mb
          // this.fileuploaderrormessage = 'Maximum excel size cannot exceed 50mb';
          this.translate.get('Common.Maximumexcelexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_EXCEL;
          this.attachedFileDisplayUrl = '../assets/images/excel.png';
          return true;
        }

      case 'audio':
        if (sizeInBytes > 50000000) {  // audio validation cannot exceed 50mb
         // this.fileuploaderrormessage = 'Maximum audio size cannot exceed 50mb';
          this.translate.get('Common.Maximumaudioexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileDisplayUrl = '../assets/images/audio.png';
          this.attachedFileType = CommonConstants.FILE_TYPE_AUDIO;
          return true;
        }

      case 'video':
        if (sizeInBytes > 50000000) { // video validation cannot exceed 50mb
          // this.fileuploaderrormessage = 'Maximum video size cannot exceed 50mb';
          this.translate.get('Common.Maximumvideoexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileDisplayUrl = '../assets/images/video.png';
          this.attachedFileType = CommonConstants.FILE_TYPE_VIDEO;
          return true;
        }


      default:
        if (sizeInBytes > 50000000) { // other file types validation cannot exceed 50mb
         // this.fileuploaderrormessage = 'Maximum file size cannot exceed 50mb';
          this.translate.get('Common.Maximumfileexceed').subscribe(res => {
            this.fileuploaderrormessage = res;
          });
          return false;
        } else {
          this.attachedFileType = CommonConstants.FILE_TYPE_OTHER;
           this.attachedFileDisplayUrl = '../assets/images/doc.png';
          return true;
        }

    }

  }

  public filesContainerOnScroll(event: ScrollEvent) {
    // if scroll reached bottom and if window is not scrolled && another file set is not loading
    if (event.isReachingBottom && !this.loadingMoreFiles) {
      if (this.environmentFilter === 'external_activities') {
        this.getActivityFiles(CommonConstants.ACTIVITY_TYPE_EXTERNAL);
      } else {
        this.getActivityFiles(CommonConstants.ACTIVITY_TYPE_INTERNAL);
      }
    }
  }

   // select or deselect all files
  public selectAllFilesClicked(isChecked: boolean) {

    // check all check boxes
    this.selectFileCheckBoxs.forEach(element => {
      element.nativeElement.checked = isChecked;
    });

    // remove all selected file
    this.selectedFiles = [];

    // if checked true, re-add all activity files into selected files
    if (isChecked) {
      this.selectedFiles = [];
      this.activityFiles.forEach(element => {
        this.selectedFiles.push(element);
      });
    }

  }

   // download selected files
   public downloadSelectedFiles() {

    // if no files selected
    if (!this.selectedFiles || this.selectedFiles.length === 0) {
     // alert('Please select files to download');
      return;
    }

    // tslint:disable-next-line:prefer-const
    let zip = new JSZip();

    this.selectedFiles.forEach((element, index) => {

      // for each selected file generate blog from file url
      this.buyerService.getFileAsBlob(element.activity_file_path).subscribe(res => {

        // add blobed file into zipe
        zip.file(element.activity_file_path.split('/').slice(-1), res);

        // if all selected files are added into zip, download zip
        if (index === this.selectedFiles.length - 1) {

          zip.generateAsync({ type: 'blob' }, (metadata) => {

            // display while zip file generating
            this.loaderActivate = true;

          }).then((blob) => {

            // download zip
            // tslint:disable-next-line:prefer-const
            let downloadUrl = window.URL.createObjectURL(blob);
            window.open(downloadUrl);
            this.loaderActivate = false;

          }, function (err) {

          });
        }
      }, err => {

      });
    });

  }

   // remove selected files from files section
   public deleteFiles(modalCloseBtn) {

    // tslint:disable-next-line:prefer-const
    let fileIdsToDelete: number[] = [];
    this.selectedFiles.forEach(element => {
      fileIdsToDelete.push(element.safety_box_file_id);
    });

    this.buyerService.deleteFiles(fileIdsToDelete).subscribe(res => {

      // remove from list
      this.selectedFiles.forEach(element => {
        // tslint:disable-next-line:prefer-const
        let indexOfItemToRemove = this.activityFiles.findIndex(m => m.safety_box_file_id === element.safety_box_file_id);

        if (indexOfItemToRemove > -1) {
          // tslint:disable-next-line:prefer-const
          let removedItem = this.activityFiles.splice(indexOfItemToRemove, 1);

          // reduce counts
          switch (removedItem[0].file_type) {

            case CommonConstants.FILE_TYPE_IMAGE:
              this.imageCount = this.imageCount - 1;
              break;

            case CommonConstants.FILE_TYPE_DOC:
              this.docCount = this.docCount - 1;
              break;

            case CommonConstants.FILE_TYPE_AUDIO:
              this.audioCount = this.audioCount - 1;
              break;

            case CommonConstants.FILE_TYPE_VIDEO:
              this.videoCount = this.videoCount - 1;
              break;

            case CommonConstants.FILE_TYPE_OTHER:
              this.otherCount = this.otherCount - 1;
              break;

          }
          this.totalCount = this.totalCount - 1;
        }
      });

      //   close modal by triggering close modal btn click
      $(this.deleteFilesConfirmationModal.nativeElement).modal('hide');
      this.selectedFiles = [];

    }, err => {
      $(this.deleteFilesConfirmationModal.nativeElement).modal('hide');
      console.log(err);
    });
  }
  // return thumbnail image to display - depends on attached file type
  public getFileThumbnail(fileUrl: string, fileType: string) {
    return this.buyerService.getThumbnailImages(fileType, fileUrl);
  }

  // Close the Delete Modal
  declineDeleteFilesModal(): void {
    $(this.deleteFilesConfirmationModal.nativeElement).modal('hide');
  }

  // Moment JS Date format for IE
  momentDateFormat(date, dateformat): Date {
    return moment(date, dateformat).toDate();
  }



  //#region Decline Preffered Seller
  callDeclinePrefferedSeller(item) {
    this.declineSeller = 'preffered';
    this.isConfirmDecline = false;
    this.buyerSellerModal = item;
    this.getBussinessInformationByBussinessId(this.buyerSellerModal.company_registration_no);
    this.getSellerProfileInformation(this.buyerSellerModal.email);
    this.getPreviousWorkersGalleries(this.buyerSellerModal.email);
    this.getSellerReviewInfomrationList(this.buyerSellerModal.email);
  }

  // Call Decline Waiting Sellers
  callDeclineWaitingSeller(item, value) {
    this.declineSeller = 'waiting';
    this.isConfirmDecline = false;
    this.buyerSellerModal = item;
    this.getBussinessInformationByBussinessId(this.buyerSellerModal.company_registration_no);
    this.getSellerProfileInformation(this.buyerSellerModal.email);
    this.getPreviousWorkersGalleries(this.buyerSellerModal.email);
    this.getSellerReviewInfomrationList(this.buyerSellerModal.email);
  }



  // Call Decline Confirmation
  getConfirmationDecline() {
    this.isConfirmDecline = true;
  }

  callDeclineConfirmation() {
     this.loaderModalActivate = true;
    const service_request_link = environment.frontendlink +
    '/seller/browserequest?requestto=browserequest&requesttype=requestme&projectid=';
    this.buyerService.declineSellerByBuyer(this.buyerId, this.buyerSellerModal.seller_id, this.projectId,
      service_request_link).pipe(finalize(() => {
      this.loaderModalActivate = false;
    })).subscribe(res => {
      // console.log(res);
      this.closeDeclineModal.nativeElement.click();
      if (this.declineSeller === 'preffered') {
        this.listofPreferedsellers.map((item, index) => {
          if (this.buyerSellerModal.seller_id === item.seller_id) {
            this.listofPreferedsellers.splice(index, 1);
          }
        });

        if (this.projectDetails.waiting_sellers.length > 0) {
          this.projectDetails.waiting_sellers.map((item, index) => {
            if (index === 0) {
              this.projectDetails.waiting_sellers.splice(index, 1);
              this.projectDetails.preferred_sellers.push(item);
            }
          });
        }

        this.projectDetails.preferred_sellers.map((item, index) => {
          if (this.buyerSellerModal.seller_id === item.seller_id) {
            this.projectDetails.preferred_sellers.splice(index, 1);
          }
        });
      } else {
        this.listofWaitingsellers.map((item, index) => {
          if (this.buyerSellerModal.seller_id === item.seller_id) {
            this.listofWaitingsellers.splice(index, 1);
          }
        });

        this.projectDetails.waiting_sellers.map((item, index) => {
          if (this.buyerSellerModal.seller_id === item.seller_id) {
            this.projectDetails.waiting_sellers.splice(index, 1);
          }
        });
      }

      this.listofDeclinedsellers.push(this.buyerSellerModal);
      this.projectDetails.declined_sellers = this.listofDeclinedsellers;
      this.callProjectDetails(this.projectDetails, this.isActiveRequestIndex);
    }, err => {
      console.log(err);
    });
  }

  // Get the Business Informatiom
  getBussinessInformationByBussinessId(bussinessid: string) {
    this.loaderModalActivate = true;
    this.buyerService.getBussinessDetailInfomation(bussinessid).pipe(finalize(() => {
      this.loaderModalActivate = false;
    })).subscribe(res => {
      if (res != null) {
        this.bussinessinfo = res;
        this.loadChartData(res.turnover_data_list);
      }
    },
      err => {
        console.log(err);
      });
  }


    // Get the Profile Information Passing Username or Email Address.
    getSellerProfileInformation(username: string) {
      this.buyerService.getSellerProfileDetails(username).subscribe(
        res => {
          if (res != null) {
            this.user = res;
          }
        },
        err => {
          console.log(err);
        }
      );
    }

      // Get the Prevois Workers Information
  getPreviousWorkersGalleries(username: string) {
    this.buyerService.getPreviousWorkImages(username).subscribe(
      res => {
        this.uploadedimages = res;
      },
      err => {
        console.log(err);
      }
    );
  }

    // get Sellers Previews Reviews
    getSellerReviewInfomrationList(username: string) {
      this.reviewList = [];
      this.buyerService.getSellerReviewInformationList(username).subscribe(res => {
        if (res != null) {
          this.reviewList = res;
        } else {
          this.reviewList = [];
        }
      }, err => {
          console.log(err);
        });
    }
    // Get the certificates for Buyer
  getCertificatesforBuyer(sellerid) {
    this.certificates = [];
    this.buyerService.getCertificateForSellerBySellerId(sellerid).subscribe(res => {
      if (res != null) {
        this.certificates = res;
      } else {
        this.certificates = [];
      }
    }, err => {
      console.log(err);
    });
  }

   // Load Quick fact Charts
   loadChartData(dataObject: Array<any>): void {
    // tslint:disable-next-line:prefer-const
    let dataTable: Array<any> = [];
    dataObject.map(function(item) {
      dataTable.push([item.year, Number(item.value)]);
    });

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        height: 200,
        borderRadius: 0,
        spacing: [20, 10, 20, 10]
      },
      title: {
        text: ''
      },
      colors: ['#3eb3a9', '#7ac1b8', '#6fbfd6', '#45aad1', '#318db9'],
      yAxis: {
        title: {
          text: ''
        }
      },
      xAxis: {
        type: 'category'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          colorByPoint: true
        }
      },
      series: [
        {
          name: null,
          data: dataTable
        }
      ]
    };

    this.chart = chart(this.chartTarget.nativeElement, options);
  }

   // Calculating FinalRating
   getFinalRatingValue(item) {
    // tslint:disable-next-line:prefer-const
    let value = item.rate_final_result + item.rate_treatment + item.rate_price +
      item.rate_time + item.rate_communication + item.rate_recommendation;
    return ((value / 30) * 5).toFixed(1);
  }


  //#endregion

//#region   Agreement Section start
  // Project offer Agreement Split  by the Seller ID
  // Get Agreement File Path
  getAgreementFilePath(projectid: number, sellerid: number): void {
    this.buyerService.getAgreementFilePath(projectid, sellerid).subscribe(res => {
      if (res != null) {
        this.projectOfferAgreements = res;
      }
    }, err => {
      console.log(err);
    });
  }
  // TODO: changes available in the furture
  // Get Agreement Infomation
  getAgreementInformation(item, is_additional_agreement) {
    this.agreementInformation = item;
    this.loaderActivate = true;
    this.isAdditionalAgreement = false;
    this.buyerService.getAgreementInfomrationByagreementId(this.agreementInformation.project_agreement_id,
      is_additional_agreement).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(res => {
      if (res != null) {
        this.summaryObject.total_price = res.total_price;
        this.summaryObject.project_start_date = res.project_start_date;
        this.summaryObject.project_end_date = res.project_end_date;
        this.summaryObject.offer_expiry_date = res.offer_expiry_date;
        this.summaryObject.sign_url = res.sign_url;
        this.summaryObject.is_ongoing_bill = res.is_ongoing_bill;
        this.summaryObject.work_hourly_cost = res.work_hourly_cost;
        this.summaryObject.work_flow_hourly_cost = res.work_flow_hourly_cost;
        this.summaryObject.sub_contract_cost_rate = res.sub_contract_cost_rate;
        this.summaryObject.other_cost_rate = res.other_cost_rate;
        this.summaryObject.travel_charge_details = res.travel_charge_details;
      }
    }, err => {
      console.log(err);
    });
  }

 // TOMOVE Trash:// // Agree the Agreement with Seller
  callConfirmationAgreement() {
    if (this.agreementInformation == null) {
      return;
    }
      // tslint:disable-next-line:prefer-const
      let number = 0;
      if (this.isAdditionalAgreement) {
        number = 1;
      }
    // tslint:disable-next-line:prefer-const
    let model = {
      project_id: this.projectId,
      seller_id: this.currentSellerId,
      agreement_id: this.agreementInformation.project_agreement_id
    };
    this.loaderActivate = true;
    this.buyerService.buyerProjectSignAgreement(model).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(res => {
      this.closeSummaryModal.nativeElement.click();
      this.getActiveRequestProjects(this.userFirstname);
      if (res != null) {
      }
    }, err => {
      console.log(err);
    });

  }


  // Get the Agreement Signicat Link
  callModalClose() {
    this.closeSummaryModal.nativeElement.click();
  }

  // Call the Decline the Agreement
  callDeclineAgreement() {
    if (this.agreementInformation == null) {
      return;
    }
    this.loaderActivate = true;
    // tslint:disable-next-line:prefer-const
    let number = 0;
    let agreementId = this.agreementInformation.project_agreement_id;
    if (this.isAdditionalAgreement) {
      number = 1;
      agreementId = this.additionalagreementId;
    } else {

    }
    this.buyerService.agreementDecline(agreementId,
      this.declineReason, number, this.active_request_safety_box_link, this.active_project_safety_box_link).pipe(finalize(() => {
        this.loaderActivate = false;
      })).subscribe(res => {
        this.closeSummaryModal.nativeElement.click();
        this.getAgreementFilePath(this.projectId, this.currentSellerId);
        this.declineReason = '';
      }, err => {
        console.log(err);
      });

  }

    // Get Agreement Infomation
    getAdditionalAgreementInformation(item, is_additional_agreement) {
      this.loaderActivate = true;
      this.isAdditionalAgreement = true;
      this.additionalagreementId = item.additional_agreement_id;
      this.additionalAgreement = item;
      this.buyerService.getAgreementInfomrationByagreementId(this.additionalagreementId,
        is_additional_agreement).pipe(finalize(() => {
        this.loaderActivate = false;
      })).subscribe(res => {
        if (res != null) {
          this.summaryObject.total_price = res.total_price;
          this.summaryObject.is_ongoing_bill = res.is_ongoing_bill;
          this.summaryObject.project_start_date = res.project_start_date;
          this.summaryObject.project_end_date = res.project_end_date;
          this.summaryObject.offer_expiry_date = res.offer_expiry_date;
          if (this.isAdditionalAgreement) {
            this.summaryObject.new_project_end_date = res.new_project_end_date;
            this.summaryObject.total_price = this.getTotalPrice(res);
          }
          this.summaryObject.sign_url = res.sign_url;
          this.summaryObject.work_hourly_cost = res.work_hourly_cost;
          this.summaryObject.work_flow_hourly_cost = res.work_flow_hourly_cost;
          this.summaryObject.sub_contract_cost_rate = res.sub_contract_cost_rate;
          this.summaryObject.other_cost_rate = res.other_cost_rate;
          this.summaryObject.travel_charge_details = res.travel_charge_details;
        }
      }, err => {
        console.log(err);
      });
    }

  // Get the Total Price..
  getTotalPrice(item) {
    if (item.is_fixed_price) {
      return this.setPriceValue(item.work_cost) + this.setPriceValue(item.material_cost) +
        this.setPriceValue(item.travel_cost);
    } else if (item.is_approximate_price) {
      return item.total_price;
    } else if (item.is_ongoing_bill) {
      return item.total_price;
    }
  }
  // value convert to number
  setPriceValue(value) {
    if (value == null) {
      return 0;
    } else {
      return value;
    }
  }





//#endregion Agreement End

//#region Review Section Start

  getReviewColorShow(property, value): boolean {
    if (value <= this.reviewObject[property]) {
      return true;
    }
    return false;
  }

  // Call the reviw add or remove
  callReviewAddorRemove(property, value) {
    if (this.reviewObject[property] === 1 && value === 1 ) {
      this.reviewObject[property] = 0;
    } else {
      this.reviewObject[property] = value;
    }
  }

  // Submit the Review
  callReviewSubmit(model: any, isValid: boolean): void {
    if (!this.isReviewfromOverview) {
      this.loaderActivate = true;
      this.reviewObject.token = this.tokenreview;
      this.buyerService.addreviewbyemailLink(this.reviewObject).pipe(finalize(() => {
        this.loaderActivate = false;
      })).subscribe(res => {
        this.closereviewModalCenter.nativeElement.click();
        this.router.navigate(['/buyer/safetybox']);
      }, err => {
        this.closereviewModalCenter.nativeElement.click();
        console.log(err);
      });
    } else {
      this.loaderActivate = true;
      this.reviewObject.project_id = this.projectId;
      // console.log(this.reviewObject);
      this.buyerService.reviewSubmitFromOverview(this.reviewObject).pipe(finalize(() => {
        this.loaderActivate = false;
      })).subscribe(res => {
        this.closereviewModalCenter.nativeElement.click();
        this.getBuyerReviewInformationForSeller(this.userFirstname, this.projectId, this.currentSellerId);
        this.reviewObject = {
          token: '',
          rate_final_result: 0,
          rate_treatment: 0,
          rate_price: 0,
          rate_time: 0,
          rate_communication: 0,
          rate_recommendation: 0,
          comment: ''
        };
      }, err => {
        this.closereviewModalCenter.nativeElement.click();
        console.log(err);
      });
    }
  }

  // Get the Review Informartion
  getBuyerReviewInformationForSeller(buyer_email, project_id, seller_id) {
    this.buyerService.getBuyerReviewInformation(buyer_email, project_id, seller_id).subscribe(res => {
      this.buyerEeviewInformation = res;
    }, err => {
      console.log(err);
    });
  }

   // calculate the Percentage
   getPercentageValue(value) {
    return (value / 5) * 100;
  }

//#endregion


// Logout
  callLogOut() {
    localStorage.removeItem('localEmailAddress');
    this.authService.removeToken();
    this.router.navigate(['buyer/home']);
  }

  // Check the Email Address
   checkemailValidation(event: any) {
    this.isAlreadyExitemail = false;
    if (event.target.value !== undefined && event.target.value !== null && event.target.value !== '') {
      this.buyerService.checkBuyerEmailExitsOrNot(event.target.value).subscribe(res => {
        if (res) {
          this.isAlreadyExitemail = true;
        }
      }, err => {
        console.log(err);
      });
    }
  }

  // photo upload select file
  onSelectFile(event) {
    this.fileDataPro = [];
    if (event.target.files && event.target.files[0]) {
      this.fileDataPro.push(event.target.files[0]);
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: Event) => { // called once readAsDataURL is completed
        this.buyerInformation.image_url = reader.result;
      };
    }
  }

  // Call Update Profile
  callUpdateProfileSubmit(model: any, isValid: boolean): void {
    if (!isValid) {
      return;
    }
    if (this.isAlreadyExitemail) {
      return;
    }
    this.loaderActivate = true;
    this.buyerInformation.contact_no = model.contact_no;
    this.buyerInformation.address = model.address;
    this.buyerInformation.email = model.email;
    this.buyerInformation.is_share_contact = model.is_share_contact;
    this.buyerService.updateBuyerShowProfileInformation(this.fileDataPro, this.buyerInformation).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      this.isBuyerProfileEditable = false;
      this.closeprofileModalCenter.nativeElement.click();
    }, err => {
      console.log(err);
      this.loaderActivate = false;
    });
  }

  onaddressValidateGoogle() {

  }

}
