import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BuyerService } from 'src/app/buyer/service/buyer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/buyer/service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  invalidCredentialMsg: string;
  login: any = {};
  loaderActivate: boolean;
  loginResponse: any = {};
  constructor(private buyerService: BuyerService, private authservice: AuthService,
     private router: Router, private translate: TranslateService) { }

  ngOnInit() {
       /** Mobile redirection Display the Error Message Page */
       if (window.location.href.includes('buyer/join/project') || window.location.href.includes('signin/postproc')) {

      } else {
        if (navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)) {
          this.router.navigate(['/mobileview']);
          return;
        }
      }
       /** Mobile redirection Display the Error Message Page */
  }

  callLoginSignicat(model: any, isValid: boolean) {
    this.invalidCredentialMsg = '';
    if (!isValid) {
      return;
    }
    if (this.login.bankId.includes('-') || (/\s/.test(this.login.bankId))) {
      this.translate.get('Common.BankIdErrorMessage').subscribe(res => {
        this.invalidCredentialMsg = res;
      });
      return;
    }


    this.loaderActivate = true;
    this.buyerService.loginBuyerByBankId(this.login.bankId).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      // if (response === -21) {
      //   this.translate.get('Login.Pleaseregistertheaccount').subscribe(res => {
      //     this.invalidCredentialMsg = res;
      //   });
      // } else if (response === -23) {
      //   this.translate.get('Login.InvalidbankID').subscribe(res => {
      //     this.invalidCredentialMsg = res;
      //   });
      // } else if (response === -24) {
      //   this.translate.get('Login.Pleasesmartphonetablet').subscribe(res => {
      //     this.invalidCredentialMsg = res;
      //   });
      // } 
      // else {
        console.log(response);
        this.loginResponse = response;
        console.log(response);
        this.authservice.setToken(this.loginResponse.auth_token.access_token);
        this.authservice.setRefreshToken(this.loginResponse.auth_token.refresh_token);
        localStorage.removeItem('localEmailAddress');
        localStorage.removeItem('yossobuyerid');
        localStorage.setItem('localEmailAddress', this.loginResponse.email);
        localStorage.setItem('yossobuyerid', this.loginResponse.buyer_id);
        console.log(localStorage);
        this.router.navigate(['/buyer/safetybox']);
      // }
    }, err => {
      console.log(err);
    });
  }
 goToRegistration(): void {
  // this.router.navigate(['/registration']);
}

goToForgetPassword(): void {
  // this.router.navigate(['/login/forgetpassword']);
}

}
