import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KitchenModal } from 'src/app/buyer/buyermodal';


@Component({
  selector: 'app-kitchen-details',
  templateUrl: './kitchen-details.component.html',
  styleUrls: ['./kitchen-details.component.scss']
})
export class KitchenDetailsComponent implements OnInit, OnChanges {

  @Input() data: KitchenModal;
  kitchen: KitchenModal = new KitchenModal();
  kitchen_details_is_there_a_local_shutdown_of_water_plumbing: string;
  kitchen_details_is_floor_heating_available: string;
  version: number;
  is_kitchen_details_is_choosen_work: boolean;
  is_kitchen_details_is_counter_tops: boolean;
  is_kitchen_details_is_splash_back: boolean;
  is_kitchen_details_is_white_goods: boolean;
  is_kitchen_details_is_el: boolean;
  is_kitchen_details_is_plumbing: boolean;
  is_kitchen_details_is_floor: boolean;
  is_kitchen_details_is_wall: boolean;
  is_kitchen_details_is_ceiling: boolean;
  is_kitchen_details_is_other_installation: boolean;
  is_kitchen_details_is_kitchen_treatment: boolean;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data !== null || this.data !== undefined) {
      this.is_kitchen_details_is_choosen_work = false;
      this.is_kitchen_details_is_counter_tops = false;
      this.is_kitchen_details_is_splash_back = false;
      this.is_kitchen_details_is_white_goods = false;
      this.is_kitchen_details_is_el = false;
      this.is_kitchen_details_is_plumbing = false;
      this.is_kitchen_details_is_floor = false;
      this.is_kitchen_details_is_wall = false;
      this.is_kitchen_details_is_ceiling = false;
      this.is_kitchen_details_is_other_installation = false;
      this.is_kitchen_details_is_kitchen_treatment = false;
     this.kitchen = this.data;
     this.kitchen_details_is_there_a_local_shutdown_of_water_plumbing =
     this.get_local_shutdown_of_water_plumbing(this.data.kitchen_details_is_there_a_local_shutdown_of_water_plumbing);
     this.kitchen_details_is_floor_heating_available =
     this.get_local_shutdown_of_water_plumbing(this.data.kitchen_details_is_floor_heating_available);
     this.checkVersion('kitchen_details_cw_is', 'is_kitchen_details_is_choosen_work');
     this.checkVersion('kitchen_details_ct_is', 'is_kitchen_details_is_counter_tops');
     this.checkVersion('kitchen_details_sb_is', 'is_kitchen_details_is_splash_back');
     this.checkVersion('kitchen_details_wg_is', 'is_kitchen_details_is_white_goods');
     this.checkVersion('kitchen_details_el_is', 'is_kitchen_details_is_el');
     this.checkVersion('kitchen_details_pl_is', 'is_kitchen_details_is_plumbing');
     this.checkVersion('kitchen_details_fl_is', 'is_kitchen_details_is_floor');
     this.checkVersion('kitchen_details_wa_is', 'is_kitchen_details_is_wall');
     this.checkVersion('kitchen_details_ce_is', 'is_kitchen_details_is_ceiling');
     this.checkVersion('kitchen_details_oi_is', 'is_kitchen_details_is_other_installation');
     this.checkVersion('kitchen_details_kt_is', 'is_kitchen_details_is_kitchen_treatment');

    }

  }

  get_local_shutdown_of_water_plumbing(index) {
    if (Number(index) === 1) {
      return 'Ja';
    } else if (Number(index) === 2) {
      return 'Nej';
    } else if (Number(index) === 3) {
      return 'Osäker/Vill rådfråga';
    } else {
      return '';
    }
  }

  kitchenchoosenwork_changesub(item, item2, item3) {

  }

  async checkVersion(item, version) {
   await Object.keys(this.kitchen).forEach(e => {
      if (e.indexOf(item) > -1) {
        if (e.indexOf('_version') > -1) {
          if (this.kitchen[e] > 1) {
            this[version] = true;
          }
        }
      }
    });
  }




}
