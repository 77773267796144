import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BuyerService } from '../service/buyer.service';
import { BuyerEventManager } from '../buyer-event.manager';
import { Router, ActivatedRoute } from '@angular/router';
import { BuyerSellerModal, SellerProfileUser } from '../buyermodal';
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
declare var require: any;

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss']
})
export class ServiceRequestComponent implements OnInit {
  loaderActivate: boolean;
  loaderModalActivate: boolean;
  @ViewChild('chartTarget') chartTarget: ElementRef;

  chart: Highcharts.ChartObject;
  user: SellerProfileUser = new SellerProfileUser();
  isLeftArrow: boolean;
  isRightArrow: boolean;
  isLeftShowHide: boolean;
  public indexNum: number;
  public latitude: number;
  public longitude: number;
  public zoom: number;
  styles: Array<any> = [];
  protected map: any;
  labelOptions: any = {};
  serviceRequestSeller: Array<any> = [];
  preferedsellerlists: Array<any> = [];
  waitingsellerlists: Array<any> = [];
  buyerSellerModal: BuyerSellerModal;
  bussinessinfo: any = {};
  searchModal: string;
  filteredServiceAreas: any = [];
  isbtnDisable: boolean;
  commonObject: any = {};
  categoryId: number;
  uploadedimages: Array<any> = [];
  isAuthenticated: boolean;
  isBackToServiceRequest: boolean;
  scope_upload_links: Array<any> = [];
  reviewList: Array<any> = [];
  certificates: Array<any> = [];
  isQueryParams: boolean;
  isDisableOver: boolean;
  buyerId: number;
  constructor(
    private buyerService: BuyerService,
    private buyereventManager: BuyerEventManager,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.buyereventManager.sendPreferredListEmitter.subscribe((mode) => {
      this.preferedsellerlists = mode;
    });

    this.buyereventManager.sendwaitingListEmitter.subscribe((mode) => {
      this.waitingsellerlists = mode;
    });

    this.buyereventManager.isAuthenticatedFromUserEmitter.subscribe((mode) => {
      this.isAuthenticated = mode;
    });

    this.buyereventManager.senduploadedFilesEmitter.subscribe((mode) => {
      this.scope_upload_links = mode;
    });
  }

  ngOnInit() {
    // Query Params
    this.route.queryParams.subscribe(params => {
        if (params['requestfrom'] === 'mapview') {
          this.isQueryParams = true;
        }
        if (params['buyer']) {
          this.buyerId = params['buyer_id'];
        }
      });

    this.route.params.subscribe(params => {
      if (params['categoryid']) {
        this.categoryId = params['categoryid'];
      }
      if (params['buyerid']) {
        this.buyerId = Number(atob(params['buyerid'].toString()));
      }
    });
    this.user.image_url = null;
    this.labelOptions = {
      url: 'assets/images/mapmarker.png',
      scaledSize: {
        height: 50,
        width: 30
      }
    };

    // set google maps defaults
    this.zoom = 9;
    this.latitude = 59.3293;
    this.longitude = 18.0686;
    this.buyerSellerModal = new BuyerSellerModal();
    this.getAllSellersServiceRequest();
  }

  // Get All Sellers Service Request
  getAllSellersServiceRequest() {
    this.loaderActivate = true;
    this.buyerService
      .getAllSellersServiceCategories()
      .pipe(
        finalize(() => {
          this.loaderActivate = false;
        })
      )
      .subscribe(
        response => {
          this.serviceRequestSeller = response;
          this.assignCopy();
        },
        err => {
          console.log(err);
        }
      );
  }

  // Activate || Deactivate
  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (this.isBackToServiceRequest) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return false;
  }

  mapReady(map) {
    this.map = map;
  }

  // assign copy for search the modal.
  assignCopy() {
    this.filteredServiceAreas = this.removeFromWaitingLists();  //  Object.assign([], this.serviceRequestSeller);
    if (this.filteredServiceAreas.length === 1) {
      this.isLeftArrow = true;
      this.isRightArrow = true;
    }
  }

  // filter item
  filterItem(event) {
    this.isLeftShowHide = false;
    // tslint:disable-next-line:prefer-const
    let value = event.query;
    // tslint:disable-next-line:curly
    if (!value) this.assignCopy(); // when nothing has typed
    this.filteredServiceAreas = _.uniqBy( this.removeFromWaitingLists(), 'seller_id').filter(
      item => item.company_name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

  }

  filterItemOnselect(event) {
    this.filteredServiceAreas = [];
    // tslint:disable-next-line:prefer-const
    let value = event.company_name;
    // tslint:disable-next-line:curly
    if (!value) this.assignCopy(); // when nothing has typed
    Object.assign([], this.serviceRequestSeller).filter(item => {
      if (item.company_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        if (item.area_name === event.area_name) {
          this.filteredServiceAreas.push(item);
          this.viewMarkerDetails(item, 0);
        }
      }
    });
  }

  // Remove unwanted service area List By Clicking
  removeFromWaitingLists(): Array<any> {
    // tslint:disable-next-line:prefer-const
    let servicerAreas = Object.assign([], this.serviceRequestSeller);
    servicerAreas.filter((item, index) => {
      // tslint:disable-next-line:prefer-const
      let objpre = this.preferedsellerlists.filter(function (itepr) { return itepr.seller_id === item.seller_id; });
      if (objpre.length > 0) {
        servicerAreas.splice(index, 1);
      }
      // tslint:disable-next-line:prefer-const
      let objwaiting = this.waitingsellerlists.filter(function (itepr) { return itepr.seller_id === item.seller_id; });
      if (objwaiting.length > 0) {
        servicerAreas.splice(index, 1);
      }
    });
    return servicerAreas;
  }




  callOnclearInput(event) {
    this.assignCopy();
  }

  // Convert to Number
  convertToNumber(item): Number {
    return Number(item);
  }

  // View Sellers Details By indivual
  viewMarkerDetails(item, index): void {
    this.isLeftShowHide = true;
    if (item != null) {
      this.indexNum = index;
      if (
        this.filteredServiceAreas.length - 1 === this.indexNum ||
        this.indexNum === 0
      ) {
        if (this.indexNum === 0) {
          this.isRightArrow = false;
          this.isLeftArrow = true;
        } else {
          this.isRightArrow = true;
          this.isLeftArrow = false;
        }
      } else {
        this.isRightArrow = false;
        this.isLeftArrow = false;
      }

      this.buyerSellerModal = item;
      this.getBussinessInformationByBussinessId(
        this.buyerSellerModal.company_registration_no
      );
      this.getSellerProfileInformation(this.buyerSellerModal.email);
      this.getPreviousWorkersGalleries(this.buyerSellerModal.email);
      this.getSellerReviewInfomrationList(this.buyerSellerModal.email);
      this.disableIfAlreadyAdded(this.buyerSellerModal.seller_id);
      this.getCertificatesforBuyer(this.buyerSellerModal.seller_id);
    }
  }

  // view Details By CLick Top of the screen
  viewMarkerDetailsByTopCirecle(item): void {
    this.isLeftShowHide = true;
    if (item != null) {
      this.isRightArrow = true;
      this.isLeftArrow = true;
     this.buyerSellerModal = item;
      this.getBussinessInformationByBussinessId(
        item.company_registration_no
      );
      this.getSellerProfileInformation(item.seller_email);
      this.getPreviousWorkersGalleries(item.seller_email);
      this.getSellerReviewInfomrationList(item.seller_email);
      this.disableIfAlreadyAdded(item.seller_id);
      this.getCertificatesforBuyer(item.seller_id);
    }
  }

  // Change Left Move
  changeLeftMove(): void {
    this.indexNum = this.indexNum - 1;
    if (this.indexNum !== -1) {
      this.buyerSellerModal = this.filteredServiceAreas[this.indexNum];
      this.getBussinessInformationByBussinessId(
        this.buyerSellerModal.company_registration_no
      );
      this.getSellerProfileInformation(this.buyerSellerModal.email);
      this.getPreviousWorkersGalleries(this.buyerSellerModal.email);
      this.getSellerReviewInfomrationList(this.buyerSellerModal.email);
      this.disableIfAlreadyAdded(this.buyerSellerModal.seller_id);
      this.getCertificatesforBuyer(this.buyerSellerModal.seller_id);

      if (this.indexNum === 0) {
        this.isRightArrow = false;
        this.isLeftArrow = true;
      }
    } else {
      this.indexNum = this.indexNum + 1;
    }
  }

  // Change Right Move
  changeRightMove(): void {
    this.indexNum = this.indexNum + 1;
    if (this.filteredServiceAreas.length > this.indexNum) {
      this.buyerSellerModal = this.filteredServiceAreas[this.indexNum];
      this.getBussinessInformationByBussinessId(
        this.buyerSellerModal.company_registration_no
      );
      this.getSellerProfileInformation(this.buyerSellerModal.email);
      this.getPreviousWorkersGalleries(this.buyerSellerModal.email);
      this.getSellerReviewInfomrationList(this.buyerSellerModal.email);
      this.disableIfAlreadyAdded(this.buyerSellerModal.seller_id);
      this.getCertificatesforBuyer(this.buyerSellerModal.seller_id);
      if (this.filteredServiceAreas.length - 1 === this.indexNum) {
        this.isRightArrow = true;
        this.isLeftArrow = false;
      }
    } else {
      this.indexNum = this.indexNum - 1;
    }
  }

  // Get the Business Informatiom
  getBussinessInformationByBussinessId(bussinessid: string) {
    this.loaderModalActivate = true;
    this.buyerService
      .getBussinessDetailInfomation(bussinessid)
      .pipe(
        finalize(() => {
          this.loaderModalActivate = false;
        })
      )
      .subscribe(
        res => {
          if (res != null) {
            this.bussinessinfo = res;
            this.loadChartData(res.turnover_data_list);
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  // Get the Profile Information Passing Username or Email Address.
  getSellerProfileInformation(username: string) {
    this.buyerService.getSellerProfileDetails(username).subscribe(
      res => {
        if (res != null) {
          this.user = res;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  // Get the Prevois Workers Information
  getPreviousWorkersGalleries(username: string) {
    this.buyerService.getPreviousWorkImages(username).subscribe(
      res => {
        this.uploadedimages = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  // get Sellers Previews Reviews
  getSellerReviewInfomrationList(username: string) {
    this.reviewList = [];
    this.buyerService.getSellerReviewInformationList(username).subscribe(res => {
      if (res != null) {
        this.reviewList = res;
      } else {
        this.reviewList = [];
      }
    }, err => {
        console.log(err);
      });
  }

  // Add to Waiting List and Prefered List
  addTowaitingListandPreferedList() {
    if (this.preferedsellerlists.length < 3) {
      this.preferedsellerlists.push(this.user);
      this.filteredServiceAreas.splice(this.indexNum, 1);
      this.disableIfAlreadyAdded(this.user.seller_id);
      this.isDisableOver = false;
      this.removeMultiSellers(this.preferedsellerlists);
      return;
    }

    if (this.preferedsellerlists.length === 3) {
      if (this.waitingsellerlists.length < 3) {
        this.waitingsellerlists.push(this.user);
        this.filteredServiceAreas.splice(this.indexNum, 1);
        this.disableIfAlreadyAdded(this.user.seller_id);
        if (this.waitingsellerlists.length === 3) {
          this.isDisableOver = true;
        }
        this.removeMultiSellers(this.waitingsellerlists);
      } else {
        this.isDisableOver = true;
      }
    }
  }



  // Remove Already Added Prefered List
  removePreferedList(index) {
    this.preferedsellerlists.splice(index, 1);
    if (this.waitingsellerlists.length > 0) {
      this.preferedsellerlists.push(this.waitingsellerlists[0]);
      this.waitingsellerlists.splice(0, 1);
    }
    this.assignCopy();
    this.disableIfAlreadyAdded(this.user.seller_id);
    this.isDisableOver = false;
    this.removeMultiSellers(this.preferedsellerlists);
  }

  // remove from waiting List
  removeWaitingList(index) {
    this.waitingsellerlists.splice(index, 1);
    this.assignCopy();
    this.disableIfAlreadyAdded(this.user.seller_id);
    this.isDisableOver = false;
    this.removeMultiSellers(this.waitingsellerlists);
  }

  // Remove or Add Item
  removeMultiSellers(listof: Array<any>) {
    listof.forEach((item) => {
      this.filteredServiceAreas.filter((data, index) => {
        if (item.seller_id === data.seller_id) {
          this.filteredServiceAreas.splice(index, 1);
        }
      });
    });
  }



  // Start new Process
  calltoStartNewServiceRequest() {
    // this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));
    this.commonObject = {};
    this.isBackToServiceRequest = true;

    if (this.preferedsellerlists.length > 0) {
      this.commonObject['request_preferred_company1_id'] = this.preferedsellerlists[0].seller_id;
    }

    if (this.preferedsellerlists.length > 1) {
      this.commonObject['request_preferred_company2_id'] = this.preferedsellerlists[1].seller_id;
    }

    if (this.preferedsellerlists.length > 2) {
      this.commonObject['request_preferred_company3_id'] = this.preferedsellerlists[2].seller_id;
    }

    if (this.waitingsellerlists.length > 0) {
      this.commonObject['request_waiting_company1_id'] = this.waitingsellerlists[0].seller_id;
    }

    if (this.waitingsellerlists.length > 1) {
      this.commonObject['request_waiting_company2_id'] = this.waitingsellerlists[1].seller_id;
    }

    if (this.waitingsellerlists.length > 2) {
      this.commonObject['request_waiting_company3_id'] = this.waitingsellerlists[2].seller_id;
    }
    if (this.buyerId > 0) {
      this.commonObject['buyer_id'] = this.buyerId;
    }
    localStorage.removeItem('YossocommonObject');
    localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));

    this.buyereventManager.sendKitchenModalOption(this.commonObject);
    this.buyereventManager.sendPreferredList(this.preferedsellerlists);
    this.buyereventManager.sendWaitingList(this.waitingsellerlists);
   // this.buyereventManager.isAuthenticatedFromUser(this.isAuthenticated);
   // this.buyereventManager.sendEnableSelectedNodeMulti(true);
    this.router.navigate(['/buyer/home'],
    { queryParams: { requestfrom: 'mapview', 'callingpage': 'selectedpagehome' } });
  }

  // go back to safety box
  gobacktosafetybox() {
    this.router.navigate(['/buyer/safetybox']);
  }

  // Close the Modal By Click
  gotoHomePageByClose() {
    this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));

    this.isBackToServiceRequest = true;

    if (this.preferedsellerlists.length > 0) {
      this.commonObject['request_preferred_company1_id'] = this.preferedsellerlists[0].seller_id;
    }

    if (this.preferedsellerlists.length > 1) {
      this.commonObject['request_preferred_company2_id'] = this.preferedsellerlists[1].seller_id;
    }

    if (this.preferedsellerlists.length > 2) {
      this.commonObject['request_preferred_company3_id'] = this.preferedsellerlists[2].seller_id;
    }

    if (this.waitingsellerlists.length > 0) {
      this.commonObject['request_waiting_company1_id'] = this.waitingsellerlists[0].seller_id;
    }

    if (this.waitingsellerlists.length > 1) {
      this.commonObject['request_waiting_company2_id'] = this.waitingsellerlists[1].seller_id;
    }

    if (this.waitingsellerlists.length > 2) {
      this.commonObject['request_waiting_company3_id'] = this.waitingsellerlists[2].seller_id;
    }

    localStorage.removeItem('YossocommonObject');
    localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));
    this.buyereventManager.sendKitchenModalOption(this.commonObject);
    this.buyereventManager.sendPreferredList(this.preferedsellerlists);
    this.buyereventManager.sendWaitingList(this.waitingsellerlists);
    this.buyereventManager.isAuthenticatedFromUser(this.isAuthenticated);
    this.buyereventManager.sendEnableSelectedNodeMulti(true);
    this.router.navigate(['/buyer/home/', 'servicerequest', this.categoryId]);
  }


   // Call the Submit Button for Last View Option
   callSubmitButtonFromFullMapView() {
    this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));

    this.isBackToServiceRequest = true;

    if (this.preferedsellerlists.length > 0) {
      this.commonObject['request_preferred_company1_id'] = this.preferedsellerlists[0].seller_id;
    }

    if (this.preferedsellerlists.length > 1) {
      this.commonObject['request_preferred_company2_id'] = this.preferedsellerlists[1].seller_id;
    }

    if (this.preferedsellerlists.length > 2) {
      this.commonObject['request_preferred_company3_id'] = this.preferedsellerlists[2].seller_id;
    }

    if (this.waitingsellerlists.length > 0) {
      this.commonObject['request_waiting_company1_id'] = this.waitingsellerlists[0].seller_id;
    }

    if (this.waitingsellerlists.length > 1) {
      this.commonObject['request_waiting_company2_id'] = this.waitingsellerlists[1].seller_id;
    }

    if (this.waitingsellerlists.length > 2) {
      this.commonObject['request_waiting_company3_id'] = this.waitingsellerlists[2].seller_id;
    }

    if (this.commonObject == null || this.commonObject === undefined) {
      this.router.navigate(['/buyer/home']);
      return;
    }

    if (!this.commonObject['buyer_id']) {
      this.commonObject['buyer_id'] = Number(localStorage.getItem('yossobuyerid'));
    }

    this.commonObject.service_category_id = this.categoryId;
    this.loaderActivate = true;
    this.commonObject.service_request_link = environment.frontendlink +
    '/seller/browserequest?requestto=browserequest&requesttype=requestme&projectid=';
    this.buyerService.createServiceRequest(this.scope_upload_links, this.commonObject).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      this.sendPrefferedSellersNotification(response);
      localStorage.removeItem('YossocommonObject');
      this.router.navigate(['/buyer/safetybox']);
      this.buyereventManager.sendActiveRequest(true);
      this.buyereventManager.sendActiveRequestProjectName(this.commonObject.project_name);

    }, err => {
      console.log(err);
    });
  }

  // Call the Preferred Sellers Notification
  sendPrefferedSellersNotification(projectid) {
    // tslint:disable-next-line:prefer-const
    let pid1 = 0;
    // tslint:disable-next-line:prefer-const
    let pid2 = 0;
    // tslint:disable-next-line:prefer-const
    let pid3 = 0;
    if (this.commonObject.request_preferred_company1_id != null) {
      pid1 = this.commonObject.request_preferred_company1_id;
    }

    if (this.commonObject.request_preferred_company2_id != null) {
      pid2 = this.commonObject.request_preferred_company2_id;
    }

    if (this.commonObject.request_preferred_company3_id != null) {
      pid3 = this.commonObject.request_preferred_company3_id;
    }
     const obj = {
      project_id: projectid,
      service_request_link: environment.frontendlink + '/seller/browserequest?requestto=browserequest&requesttype=requestme&projectid=',
      request_preferred_company1_id: pid1,
      request_preferred_company2_id: pid1,
      request_preferred_company3_id: pid2
    };
    this.buyerService.sendNotificationToPreferredSellers(obj).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
    });
  }

  // Find If its Available Or not in the List
  disableIfAlreadyAdded(sellerId) {
    this.isbtnDisable = false;
    // tslint:disable-next-line:prefer-const
    let listOfitem = this.preferedsellerlists.concat(this.waitingsellerlists);
    listOfitem.filter(item => {
      if (item.seller_id === sellerId) {
        this.isbtnDisable = true;
      }
    });
  }

  getIdentifyDisable(): boolean {
    if (this.preferedsellerlists.length === 0) {
      return true;
    }
    return false;
  }

  // Load Quick fact Charts
  loadChartData(dataObject: Array<any>): void {
    // tslint:disable-next-line:prefer-const
    let dataTable: Array<any> = [];
    dataObject.map(function(item) {
      dataTable.push([item.year, Number(item.value)]);
    });

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        height: 200,
        borderRadius: 0,
        spacing: [20, 10, 20, 10]
      },
      title: {
        text: ''
      },
      colors: ['#3eb3a9', '#7ac1b8', '#6fbfd6', '#45aad1', '#318db9'],
      yAxis: {
        title: {
          text: ''
        }
      },
      xAxis: {
        type: 'category'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          colorByPoint: true
        }
      },
      series: [
        {
          name: null,
          data: dataTable
        }
      ]
    };

    this.chart = chart(this.chartTarget.nativeElement, options);
  }

  // Review Sub Functions
   // Calculating FinalRating
   getFinalRatingValue(item) {
    // tslint:disable-next-line:prefer-const
    let value = item.rate_final_result + item.rate_treatment + item.rate_price +
      item.rate_time + item.rate_communication + item.rate_recommendation;
    return ((value / 30) * 5).toFixed(1);
  }
  // calculate the Percentage
  getPercentageValue(value) {
    return (value / 5) * 100;
  }

    // Get the certificates for Buyer
    getCertificatesforBuyer(sellerid) {
      this.certificates = [];
      this.buyerService.getCertificateForSellerBySellerId(sellerid).subscribe(res => {
        if (res != null) {
          this.certificates = res;
        } else {
          this.certificates = [];
        }
      }, err => {
        console.log(err);
      });
    }

}
