import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Google Map set up
// https://angular-maps.com/guides/getting-started/
import { AgmCoreModule } from '@agm/core';
import { BuyerRoutingModule } from './buyer-routing.module';
import { HomeComponent } from './home/home.component';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { BuyerService } from './service/buyer.service';
import { KitchenComponent } from './sub-component/kitchen/kitchen.component';
import { SafetyboxComponent } from './safetybox/safetybox.component';
import { environment } from '../../environments/environment';
import { SubCommonComponent } from './sub-component/sub-common/sub-common.component';
import { AuthenticationComponent } from './sub-component/authentication/authentication.component';
import { OrderByPipe, ReversePipe, NewLinePipe, TruncatePipe, SortGridPipe } from './buyer.pipe';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MultiSelectModule} from 'primeng/multiselect';
import { NumbersOnlyInputDirective } from './number.directive';
import { FirebaseHelperService } from './core/firebase-helper.service';
import { FileDropModule } from 'ngx-file-drop';
import { ScrollEventModule } from 'ngx-scroll-event';
import { CanDeactivateGuard } from './service/can-deactivate-guard.service';
import { SubAttachmentsComponent } from './safetybox/sub-components/sub-attachments/sub-attachments.component';
import { SignicatStatusComponent } from './signicat-status/signicat-status.component';
import { AuthGuardService } from './service/auth-guard.service';
import { KitchenMainComponent } from './safetybox/sub-components/kitchen/kitchen-main/kitchen-main.component';
import { KitchenScopeComponent } from './safetybox/sub-components/kitchen/kitchen-scope/kitchen-scope.component';
import { KitchenDetailsComponent } from './safetybox/sub-components/kitchen/kitchen-details/kitchen-details.component';
import { CommonComponent } from './sub-component/common/common.component';
import { CommonScopeComponent } from './safetybox/sub-components/kitchen/common-scope/common-scope.component';
import { CommonDetailsComponent } from './safetybox/sub-components/kitchen/common-details/common-details.component';
import { JoinProjectComponent } from './join-project/join-project.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthService } from './service/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './service/token.interceptor';

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApikey,
      libraries: ['places']
    }),
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    HttpClientModule,
    BuyerRoutingModule,
    AutoCompleteModule,
    ScrollEventModule,
    FileDropModule,
    MultiSelectModule

  ],
  declarations: [HomeComponent, ServiceRequestComponent, KitchenComponent, SafetyboxComponent,
    SubCommonComponent, AuthenticationComponent, OrderByPipe, ReversePipe, NewLinePipe, TruncatePipe,
    SortGridPipe, NumbersOnlyInputDirective, KitchenMainComponent, KitchenScopeComponent, KitchenDetailsComponent,
    SubAttachmentsComponent, SignicatStatusComponent, KitchenMainComponent, CommonComponent,
    CommonScopeComponent, CommonDetailsComponent, JoinProjectComponent],
  providers: [AuthGuardService, AuthService,
    BuyerService, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    FirebaseHelperService, CanDeactivateGuard, TranslateService]
})
export class BuyerModule { }
