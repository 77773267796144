import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KitchenModal } from 'src/app/buyer/buyermodal';
import { BuyerService } from 'src/app/buyer/service/buyer.service';


@Component({
  selector: 'app-kitchen-scope',
  templateUrl: './kitchen-scope.component.html',
  styleUrls: ['./kitchen-scope.component.scss']
})
export class KitchenScopeComponent implements OnInit, OnChanges {

  @Input() data: KitchenModal;
  @Input() iseditedmessage: boolean;
  kitchen: KitchenModal = new KitchenModal();
  scope_price_offerValue: string;
  scope_project_budget: string;
  kitchen_scope_is_module: string;
  kitchen_scope_module_type: string;
  service_category_name: string;
  serviceCategories: Array<any> = [];
  categoryId: number;
  isshowMessage: boolean;
  constructor(private buyerService: BuyerService) { }

  ngOnInit() {
    this.loadServiceCategories();
  }

  // Load All the Service categories
  loadServiceCategories() {
    this.buyerService.getServiceCategories().subscribe(response => {
      this.serviceCategories = response;
      if (this.categoryId != null || this.categoryId !== undefined) {
        this.getServiceCategoryName(this.categoryId);
      }
    }, err => {
      console.log(err);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data != null || this.data !== undefined) {
      this.kitchen = this.data;
      this.isshowMessage = this.iseditedmessage;
      this.kitchen.kitchen_scope_kitchen_project_type = this.getkitchenProjectType(this.data.kitchen_scope_kitchen_project_type);
      this.kitchen_scope_is_module = this.get_kitchen_scope_is_module(this.data.kitchen_scope_is_module);
      this.kitchen_scope_module_type = this.get_kitchen_scope_module_type(this.data.kitchen_scope_module_type);
      this.scope_price_offerValue = this.get_scope_price_offer(this.data.scope_price_offer);
      this.scope_project_budget = this.get_scope_project_budget(this.data.scope_project_budget);
      this.service_category_name = this.getServiceCategoryName(this.data.service_category_id);
      this.categoryId = this.data.service_category_id;
    }
  }

  getkitchenProjectType(index) {
    // tslint:disable-next-line:prefer-const
    let kitchenProjects: Array<any> = [];
    kitchenProjects = ['', 'Bygga nytt kök från grunden',
      'Delvis renovering av befintligt kök', 'Totalrenovering av befintligt kök'];
    return kitchenProjects[Number(index)];
  }

  get_kitchen_scope_module_type(index) {
    // tslint:disable-next-line:prefer-const
    let array = ['', 'Tibrokök', 'Vedum', 'Kungsäter kök', 'Arredo3', 'Arrital Cucine',
      'ARXI - Tecnocucina', 'Binova', 'Canadaköket', 'Claessonkök', 'Creoform Kök',
      'Electrolux Home', 'Epoq', 'Himle Kök', 'Härjedalskök', 'IKEA', 'Interhem',
      'Invita', 'Kitchn', 'Kvik', 'Köksfabriken', 'Marbodal', 'Mälarkök', 'Noblessa Kök',
      'Novaflex', 'Perfect Inredning', 'Siematic', 'Smedstorp', 'Strömsdal Kök & Interiör',
      'Unique Wood', 'Apparat Kök', 'Ballingslöv',
      'Bomanskök', 'Bulthaup', 'Geco', 'Hagaköket', 'HTH', 'Infra Kök', 'Kvänum Kök',
      'Köksskräddarn', 'Lidhults Kök', 'LIJO Kök', 'Lundins Kök', 'Länghems kök', 'Mobalpa',
      'Modexa', 'NordDesign', 'Poggenpohl', 'Puustelli', 'Scandinavian Kitchen', 'Storsjökök'];

    return array[Number(index)];
  }

  // get Category Name
  getServiceCategoryName(index) {
    // tslint:disable-next-line:prefer-const
    let cate = this.serviceCategories.filter(item => item.service_category_id === Number(index));
    if (cate.length > 0) {
      return cate[0].service_category_name;
    } else {
      return '';
    }
  }

  get_scope_price_offer(index) {
    // tslint:disable-next-line:prefer-const
    let array = ['', 'Enbart arbete', 'Arbetskostnad och köksinredning', 'Arbetskostnad och byggmaterial',
      'Arbetskostnad, köksinredning samt byggmaterial'];
    return array[Number(index)];
  }

  get_scope_project_budget(index) {
    // tslint:disable-next-line:prefer-const
    let array = ['', '0 – 10 000 kr', '10 001 – 50 000 kr',
      '50 001 – 100 000 kr', '100 001 – 250 000 kr', '250 001 – 500 000 kr', '> 500 000 kr'];
    return array[Number(index)];
  }

  get_kitchen_scope_is_module(value) {
    if (value) {
      return 'Modul';
    } else {
      return 'Platsbyggd';
    }
  }

}
