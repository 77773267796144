import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BuyerService } from '../../service/buyer.service';
import { BuyerEventManager } from '../../buyer-event.manager';
import { finalize } from 'rxjs/operators';
import { BuyerLoginResponse, BuyerRegisterUpdate, KitchenModal } from '../../buyermodal';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  @Input() activeNumber: number;
  isActiveTab: number;
  loaderAuthenticationActivate: boolean;
  loaderLoginActivate: boolean;
  loadingtextmessage: string;
  buyerResponse: BuyerLoginResponse;
  updateRegister: BuyerRegisterUpdate;
  @ViewChild('authenticationVerifyButton') authenticationVerifyButton: ElementRef;
  login: any = { bankId: '' };
  register: any = { regsiterBankId: '', uploadimageUrl: '' };
  fileData: Array<any> = [];
  registerErrorMessage: string;
  loginErrorMessage: string;
  isBankIdNotRegistered: boolean;
  isSuccessFullyLogin: boolean;
  registeredBankId: string;
  uploadimageUrl: string;
  buyerId: number;
  // kitchen: KitchenModal;
  commonObject: any = {};
  isAgreedAgreement: boolean;
  agreementfilter: boolean;
  isShowAgreement: boolean;
  isAlreadyExitemail: boolean;
  @ViewChild('authenticationsearch') authsearchElementRef: ElementRef;
  isAddreesInvalid: boolean;

  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private translate: TranslateService, private authservice: AuthService) {
    this.buyerResponse = new BuyerLoginResponse();
    this.buyereventManager.sendActiveStageNumberEmitter.subscribe((mode) => {
      this.isActiveTab = mode;
    });
   }
  ngOnInit() {
    this.loaderLoginActivate = true;
    // this.loadingtextmessage = 'Authenticating';
    this.translate.get('Login.Authenticating').subscribe(res => {
      this.loadingtextmessage = res;
    });
    // this.kitchen = new KitchenModal();
    this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));
    this.registerErrorMessage = '';
    this.loginErrorMessage = '';
    this.updateRegister  = new BuyerRegisterUpdate();
    if (this.commonObject['buyer_id'] == null && localStorage.getItem('yossobuyerid') != null) {
      this.buyerId = Number(localStorage.getItem('yossobuyerid'));
    } else {
      this.buyerId = this.commonObject['buyer_id'];
    }
    this.getBuyerInfomation();
    if (this.authsearchElementRef != null && this.authsearchElementRef !== undefined) {
      this.loadMapLoaderInScreen();
    }
  }

  loadMapLoaderInScreen() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.authsearchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.updateRegister.address = place.formatted_address;
          this.isAddreesInvalid = false;
        });
      });
    });
  }

  // Get Buyer Information
  getBuyerInfomation() {
    // this.isSuccessFullyLogin = true;
    if (this.buyerId != null) {
     // this.loadingtextmessage = 'Authenticating';
      this.translate.get('Login.Authenticating').subscribe(res => {
        this.loadingtextmessage = res;
      });
      this.loaderLoginActivate = true;
      this.buyerService.getBuyerInformation(this.buyerId).pipe(finalize(() => {
        this.loaderLoginActivate = false;
      })).subscribe(response => {
        this.buyerResponse = response;
        this.isSuccessFullyLogin = true;
        this.buyereventManager.isActivateLoginNextButton(false);
      }, err => {
        console.log(err);
      });
    } else {
      this.loaderLoginActivate = false;
    }
  }

  // Validate the Screen
  verifyFromHomePage() {
    if (this.isActiveTab === 4) {
      if (this.isSuccessFullyLogin) {
        this.buyereventManager.sendActiveStageNumber(4);
      } else {
        this.buyereventManager.sendActiveStageNumber(3);
      }
    } else {
      this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
    }
  }

  callLoginSignicat(model: any, isValid: boolean) {
    this.loginErrorMessage = '';
    if (!isValid) {
      return;
    }

    if (this.login.bankId.includes('-') || (/\s/.test(this.login.bankId))) {
      this.translate.get('Common.BankIdErrorMessage').subscribe(res => {
        this.loginErrorMessage = res;
      });
      return;
    }

    this.loaderAuthenticationActivate = true;
    this.buyerService.loginBuyerByBankId(this.login.bankId).pipe(finalize(() => {
      this.loaderAuthenticationActivate = false;
    })).subscribe(response => {
      if (response === -21) {
       // this.loginErrorMessage = 'Please register the account';
        this.translate.get('Login.Pleaseregistertheaccount').subscribe(res => {
          this.loginErrorMessage = res;
        });
      } else if (response === -23) {
        // this.loginErrorMessage = 'Invalid bank ID';
        this.translate.get('Login.InvalidbankID').subscribe(res => {
          this.loginErrorMessage = res;
        });
      } else if (response === -24) {
        // this.loginErrorMessage = 'Please make sure enter the pin code in your smart phone/tablet';
        this.translate.get('Login.Pleasesmartphonetablet').subscribe(res => {
          this.loginErrorMessage = res;
        });
      } else {
        // console.log(response);
        this.buyerResponse = response;
        this.isSuccessFullyLogin = true;
        this.commonObject['buyer_id'] = this.buyerResponse.buyer_id;
        this.buyerId = this.buyerResponse.buyer_id;
        this.authservice.setToken(this.buyerResponse.auth_token.access_token);
        localStorage.removeItem('YossocommonObject');
        localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));
        localStorage.removeItem('localEmailAddress');
        localStorage.removeItem('yossobuyerid');
        localStorage.setItem('localEmailAddress', this.buyerResponse.email);
        localStorage.setItem('yossobuyerid', this.buyerId.toString());
        this.buyereventManager.sendKitchenModalOption(this.commonObject);
        this.buyereventManager.isActivateLoginNextButton(false);
      }
    }, err => {
      console.log(err);
    });
  }

  callRegisterSignicat(model: any, isValid: boolean) {
    this.registerErrorMessage = '';
    this.isBankIdNotRegistered = false;
    if (!isValid) {
      return;
    }
    if (this.register.regsiterBankId.includes('-') || (/\s/.test(this.register.regsiterBankId))) {
      this.translate.get('Common.BankIdErrorMessage').subscribe(res => {
        this.registerErrorMessage = res;
      });
      return;
    }

    this.registeredBankId = this.register.regsiterBankId;
    this.loaderAuthenticationActivate = true;
    this.buyerService.registerBuyerByBankId(this.register.regsiterBankId).pipe(finalize(() => {
      this.loaderAuthenticationActivate = false;
    })).subscribe(response => {
      if (response === -22) {
        // this.registerErrorMessage = 'BankId already exists in the system.';
        this.translate.get('Login.BankIdexiststhesystem').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else if (response === -23) {
        // this.registerErrorMessage = 'Invalid bank ID';
        this.translate.get('Login.InvalidbankID').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else if (response === -24) {
        // this.registerErrorMessage = 'Please make sure enter the pin code in your smart phone/tablet';
        this.translate.get('Login.Pleasesmartphonetablet').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else {
        this.buyerResponse = response;
        this.isBankIdNotRegistered = true;
        if (this.authsearchElementRef != null && this.authsearchElementRef !== undefined) {
          this.loadMapLoaderInScreen();
        }

      }
    }, err => {
      console.log(err);
    });
  }

  updateRegisterPage(model: any, isValid: boolean) {
    this.registerErrorMessage = '';
    if (!isValid) {
      return;
    }
    if (this.isAlreadyExitemail) {
      return;
    }
    // this.loadingtextmessage = 'Registering...';
    this.translate.get('Login.Registering').subscribe(res => {
      this.loadingtextmessage = res;
    });
    this.loaderLoginActivate = true;
    this.updateRegister.bank_id = this.registeredBankId;
    this.updateRegister.given_name = this.buyerResponse.given_name;
    this.updateRegister.family_name = this.buyerResponse.family_name;
    if (this.updateRegister.contact_no === null || this.updateRegister.contact_no === undefined) {
      this.updateRegister.contact_no = 0;
    }
    this.buyerService.registerBuyerInformationWithBankId(this.fileData, this.updateRegister).pipe(finalize(() => {
      this.loaderLoginActivate = false;
    })).subscribe(response => {
      this.buyerResponse = response;
      this.commonObject['buyer_id'] = this.buyerResponse.buyer_id;
      this.buyerId = this.buyerResponse.buyer_id;
      this.authservice.setToken(this.buyerResponse.auth_token.access_token);
      localStorage.removeItem('YossocommonObject');
      localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));
      this.buyereventManager.sendKitchenModalOption(this.commonObject);
      this.isBankIdNotRegistered = false;
      this.isSuccessFullyLogin = true;
      this.buyereventManager.isActivateLoginNextButton(false);
      localStorage.removeItem('localEmailAddress');
      localStorage.setItem('localEmailAddress', this.buyerResponse.email);
      localStorage.removeItem('yossobuyerid');
      localStorage.setItem('yossobuyerid', this.buyerId.toString());
    }, err => {
      console.log(err);
    });


  }

  // photo upload select file
  onSelectFile(event) {
    this.fileData = [];
    if (event.target.files && event.target.files[0]) {
      this.fileData.push(event.target.files[0]);
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: Event) => { // called once readAsDataURL is completed
        this.register.uploadimageUrl = reader.result;
      };
    }
  }

  // Accept the Terms and Conditions
  acceptTermsCondition() {
    this.isAgreedAgreement = true;
    this.isShowAgreement = false;
  }

   // Accept the Terms and Conditions
   declineTermsCondition() {
    this.isAgreedAgreement = false;
    this.isShowAgreement = false;
    this.agreementfilter = false;
  }


  onFilterChange(eve: any) {
    if (eve) {
      this.isShowAgreement = true;
      this.agreementfilter = true;
    } else {
      this.agreementfilter = false;
      this.isAgreedAgreement = false;
    }
  }


   // Checking Email Exists or Not
   checkemailValidation(event: any) {
    this.isAlreadyExitemail = false;
    // without type info
    if (event.target.value !== undefined && event.target.value !== null && event.target.value !== '') {
      this.buyerService.checkBuyerEmailExitsOrNot(event.target.value).subscribe(res => {
        if (res) {
          this.isAlreadyExitemail = true;
        }
      }, err => {
        console.log(err);
        // this.invalidValidationMsg = 'Already Exits the email';
      });
    }
  }

   // Address validare
   onaddressValidateGoogle() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.authsearchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.updateRegister.address = place.formatted_address;
          // set latitude, longitude and zoom
          this.isAddreesInvalid = false;
          return;
        });
      });
    });
  }



}
