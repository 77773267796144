import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { BuyerService } from '../service/buyer.service';
import { BuyerEventManager } from '../buyer-event.manager';
import { MapsAPILoader } from '@agm/core';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BuyerLoginResponse, BuyerRegisterUpdate } from '../buyermodal';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-join-project',
  templateUrl: './join-project.component.html',
  styleUrls: ['./join-project.component.scss']
})
export class JoinProjectComponent implements OnInit {

  registerErrorMessage: string;
  loginErrorMessage: string;
  loaderAuthenticationActivate: boolean;
  loaderLoginActivate: boolean;
  loadingtextmessage: string;
  buyerResponse: BuyerLoginResponse;
  updateRegister: BuyerRegisterUpdate;
  buyerId: number;
  token: string;
  projectInformation: any = {};
  login: any = { bankId: '' };
  register: any = { regsiterBankId: '', uploadimageUrl: '' };
  isBankIdNotRegistered: boolean;
  registeredBankId: string;
  isAlreadyExitemail: boolean;
  @ViewChild('authenticationsearch') authsearchElementRef: ElementRef;
  fileData: Array<any> = [];
  isAgreedAgreement: boolean;
  agreementfilter: boolean;
  isShowAgreement: boolean;
  isAddreesInvalid: boolean;
  isTokenValid: boolean;
  constructor(private router: Router, private buyerService: BuyerService, private buyereventManager: BuyerEventManager,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private translate: TranslateService, private route: ActivatedRoute, private authservice: AuthService) { }

  ngOnInit() {
    this.registerErrorMessage = '';
    this.loginErrorMessage = '';
    this.translate.get('Login.Authenticating').subscribe(res => {
      this.loadingtextmessage = res;
    });
    this.updateRegister  = new BuyerRegisterUpdate();
    this.buyerResponse = new BuyerLoginResponse();
    this.route.params.subscribe(params => {
        if (params['token']) {
          this.token = params['token'];
          if (this.token != null) {
            this.isTokenValid = true;
            this.buyerService.getInternalProjectInformation(this.token).pipe(finalize(() => {
              // this.loaderAuthenticationActivate = false;
            })).subscribe(response => {
              if (response != null) {
                this.projectInformation = response;
                this.isTokenValid = true;
              } else {
                this.isTokenValid = false;
              }
            }, err => {
              console.log(err);
            });
          }
        }
    });
  }

  callLoginSignicat(model: any, isValid: boolean) {
    this.loginErrorMessage = '';
    if (!isValid) {
      return;
    }
    this.loaderAuthenticationActivate = true;
    this.buyerService.loginBuyerByBankId(this.login.bankId).pipe(finalize(() => {
      this.loaderAuthenticationActivate = false;
    })).subscribe(response => {
      if (response === -21) {
        this.translate.get('Login.Pleaseregistertheaccount').subscribe(res => {
          this.loginErrorMessage = res;
        });
      } else if (response === -23) {
        this.translate.get('Login.InvalidbankID').subscribe(res => {
          this.loginErrorMessage = res;
        });
      } else if (response === -24) {
        this.translate.get('Login.Pleasesmartphonetablet').subscribe(res => {
          this.loginErrorMessage = res;
        });
      } else {
        // console.log(response);
        this.buyerResponse = response;
        this.buyerId = this.buyerResponse.buyer_id;
        this.authservice.setToken(this.buyerResponse.auth_token.access_token);
        localStorage.removeItem('localEmailAddress');
        localStorage.removeItem('yossobuyerid');
        localStorage.setItem('localEmailAddress', this.buyerResponse.email);
        localStorage.setItem('yossobuyerid', this.buyerId.toString());
        this.updateInformationForProject(this.token, this.buyerId);
      }
    }, err => {
      console.log(err);
    });
  }

  updateInformationForProject(token, buyerId) {
    this.buyerService.updateExtenalProjectInformation(token, buyerId).subscribe(res => {
      console.log(res);
      setInterval(() => {
        this.router.navigate(['/buyer/safetybox']);
      }, 3000);
    }, err => {
      console.log(err);
    });
  }

  callRegisterSignicat(model: any, isValid: boolean) {
    this.registerErrorMessage = '';
    this.isBankIdNotRegistered = false;
    if (!isValid) {
      return;
    }
    this.registeredBankId = this.register.regsiterBankId;
    this.loaderAuthenticationActivate = true;
    this.buyerService.registerBuyerByBankId(this.register.regsiterBankId).pipe(finalize(() => {
      this.loaderAuthenticationActivate = false;
    })).subscribe(response => {
      if (response === -22) {
        this.translate.get('Login.BankIdexiststhesystem').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else if (response === -23) {
        this.translate.get('Login.InvalidbankID').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else if (response === -24) {
        this.translate.get('Login.Pleasesmartphonetablet').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else {
        this.buyerResponse = response;
        this.isBankIdNotRegistered = true;
        if (this.authsearchElementRef != null && this.authsearchElementRef !== undefined) {
          this.loadMapLoaderInScreen();
        }

      }
    }, err => {
      console.log(err);
    });
  }

  loadMapLoaderInScreen() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.authsearchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.updateRegister.address = place.formatted_address;
          this.isAddreesInvalid = false;
        });
      });
    });
  }

   // photo upload select file
   onSelectFile(event) {
    this.fileData = [];
    if (event.target.files && event.target.files[0]) {
      this.fileData.push(event.target.files[0]);
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: Event) => { // called once readAsDataURL is completed
        this.register.uploadimageUrl = reader.result;
      };
    }
  }

  // Accept the Terms and Conditions
  acceptTermsCondition() {
    this.isAgreedAgreement = true;
    this.isShowAgreement = false;
  }

   // Accept the Terms and Conditions
   declineTermsCondition() {
    this.isAgreedAgreement = false;
    this.isShowAgreement = false;
    this.agreementfilter = false;
  }


  onFilterChange(eve: any) {
    if (eve) {
      this.isShowAgreement = true;
      this.agreementfilter = true;
    } else {
      this.agreementfilter = false;
      this.isAgreedAgreement = false;
    }
  }


   // Checking Email Exists or Not
   checkemailValidation(event: any) {
    this.isAlreadyExitemail = false;
    // without type info
    if (event.target.value !== undefined && event.target.value !== null && event.target.value !== '') {
      this.buyerService.checkBuyerEmailExitsOrNot(event.target.value).subscribe(res => {
        if (res) {
          this.isAlreadyExitemail = true;
        }
      }, err => {
        console.log(err);
        // this.invalidValidationMsg = 'Already Exits the email';
      });
    }
  }

   // Address validare
   onaddressValidateGoogle() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.authsearchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.updateRegister.address = place.formatted_address;
          // set latitude, longitude and zoom
          this.isAddreesInvalid = false;
          return;
        });
      });
    });
  }

  updateRegisterPage(model: any, isValid: boolean) {
    this.registerErrorMessage = '';
    if (!isValid) {
      return;
    }
    if (this.isAlreadyExitemail) {
      return;
    }
    this.translate.get('Login.Registering').subscribe(res => {
      this.loadingtextmessage = res;
    });
    this.loaderLoginActivate = true;
    this.updateRegister.bank_id = this.registeredBankId;
    this.updateRegister.given_name = this.buyerResponse.given_name;
    this.updateRegister.family_name = this.buyerResponse.family_name;
    if (this.updateRegister.contact_no === null || this.updateRegister.contact_no === undefined) {
      this.updateRegister.contact_no = 0;
    }
    this.buyerService.registerBuyerInformationWithBankId(this.fileData, this.updateRegister).pipe(finalize(() => {
      this.loaderLoginActivate = false;
    })).subscribe(response => {
      this.buyerResponse = response;
      this.buyerId = this.buyerResponse.buyer_id;
      this.isBankIdNotRegistered = false;
      this.authservice.setToken(this.buyerResponse.auth_token.access_token);
      localStorage.removeItem('localEmailAddress');
      localStorage.setItem('localEmailAddress', this.buyerResponse.email);
      localStorage.removeItem('yossobuyerid');
      localStorage.setItem('yossobuyerid', this.buyerId.toString());
      this.updateInformationForProject(this.token, this.buyerId);
    }, err => {
      console.log(err);
    });
  }


}
