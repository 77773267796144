import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(private translate: TranslateService, private router: Router, private route: ActivatedRoute) {
    localStorage.removeItem('YossocommonObject');
    localStorage.removeItem('firebase:previous_websocket_failure');
    translate.setDefaultLang('se');

    if (window.location.href.includes('buyer/join/project') || window.location.href.includes('signin/postproc')) {

    } else {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        this.router.navigate(['/mobileview']);
      }
    }



  }
}
