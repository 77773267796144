export class CommonConstants {
  // activity types
  public static ACTIVITY_TYPE_INTERNAL: string = '0';
  public static ACTIVITY_TYPE_EXTERNAL: string = '1';

  // file types
  public static FILE_TYPE_ALL: string = 'all';
  public static FILE_TYPE_IMAGE: string = 'image';
  public static FILE_TYPE_DOC: string = 'doc';
  public static FILE_TYPE_PDF: string = 'pdf';
  public static FILE_TYPE_KEY: string = 'key';
  public static FILE_TYPE_NUMBERS: string = 'numbers';
  public static FILE_TYPE_PAGES: string = 'pages';
  public static FILE_TYPE_PPT: string = 'ppt';
  public static FILE_TYPE_TXT: string = 'txt';
  public static FILE_TYPE_EXCEL: string = 'excel';
  public static FILE_TYPE_AUDIO: string = 'audio';
  public static FILE_TYPE_VIDEO: string = 'video';
  public static FILE_TYPE_OTHER: string = 'other';
}
