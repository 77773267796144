import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Pipe({
  name: 'sortgrid',
  pure: false
})

@Injectable()
export class SortGridPipe implements PipeTransform {
  transform(array: Array<any>, args: string): Array<any> {
    if (typeof args[0] === 'undefined') {
      return array;
    }
    // tslint:disable-next-line:prefer-const
    let direction = args[0][0];
    // tslint:disable-next-line:prefer-const
    let column = args.replace('-', '');
    array.sort((a: any, b: any) => {
      // tslint:disable-next-line:prefer-const
      let left = Number(new Date(a[column]));
      // tslint:disable-next-line:prefer-const
      let right = Number(new Date(b[column]));
      return (direction === '-') ? right - left : left - right;
    });
    return array;
  }
}

@Pipe({
 name: 'orderByPipe'
})
export class OrderByPipe implements PipeTransform {

 transform(array: Array<string>, args: string): Array<string> {

  // tslint:disable-next-line:curly
  if (!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (a.date < b.date) {
        return -1;
      } else if (a.date > b.date) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform(value) {
    // tslint:disable-next-line:curly
    if (!value) return;

    return value.reverse();
  }
}


@Pipe({
  name: 'newline'
})
export class NewLinePipe implements PipeTransform {

  transform(value: string, args: string[]): any {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    } else {
      return value;
    }
  }
}


@Pipe({
  name: 'limitTo'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: string): string {
    // let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
    // let trail = args.length > 1 ? args[1] : '...';
    // tslint:disable-next-line:prefer-const
    let limit = args ? parseInt(args, 10) : 10;
    // tslint:disable-next-line:prefer-const
    let trail = '...';

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
