import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { BuyerService } from 'src/app/buyer/service/buyer.service';
import { KitchenModal } from 'src/app/buyer/buyermodal';
import { finalize } from 'rxjs/operators';
import { BuyerEventManager } from 'src/app/buyer/buyer-event.manager';

@Component({
  selector: 'app-kitchen-main',
  templateUrl: './kitchen-main.component.html',
  styleUrls: ['./kitchen-main.component.scss']
})
export class KitchenMainComponent implements OnInit, OnChanges {

  scope_upload_links: Array<any> = [];
  @Input() projectId: number;
  @Input() currentUserId: number;
  isshowMessage: boolean;
  questionObject: any;

  commonObject: any = {
    project_name: null,
    sub_category_position: null,
    scope_upload_links: []
  };

  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.projectId != null && this.currentUserId != null) {
      this.commonObject = {
        project_name: null,
        sub_category_position: null,
        scope_upload_links: []
      };
      this.getServiceRequestByprojectId(this.projectId, this.currentUserId);
      this.getQuestionSetByProjectId(this.projectId);
    }

  }

  // get the Question Set
  getQuestionSetByProjectId(project_id) {
    this.buyerService.getQuestionSetByProjectId(project_id).pipe(finalize(() => {
      // this.loaderMiddleActivate = false;
    })).subscribe(res => {
      if (res != null) {
        this.questionObject = res;
      }
    }, err => {
      console.log(err);
    });
  }


  // Get the Project Information By Project Id
  getServiceRequestByprojectId(project_id, user_id) {
    this.scope_upload_links = [];
    this.isshowMessage = false;
    this.buyerService.getServiceRequestDetailsByProjectId(project_id, user_id).pipe(finalize(() => {
      // this.loaderMiddleActivate = false;
    })).subscribe(res => {
      if (res != null) {
        this.commonObject = this.toObject(res);
        this.buyereventManager.sendParentToChild(this.commonObject);
        if (this.commonObject.scope_upload_links.length > 0) {
          this.scope_upload_links = this.commonObject.scope_upload_links;
        }
      }
    }, err => {
      console.log(err);
    });
  }

  toObject(arr) {

     arr.forEach((item, index) => {
       if (item.answer === 'False') {
        this.commonObject[item.question_name] = false;
        this.commonObject[item.question_name + '_version'] = item.version;
         if (item.version > 1) {
           this.isshowMessage = true;
         }
       } else if (item.answer === 'True') {
        this.commonObject[item.question_name] = true;
        this.commonObject[item.question_name + '_version'] = item.version;
         if (item.version > 1) {
          this.isshowMessage = true;
        }
       } else {
        this.commonObject[item.question_name] = item.answer;
        this.commonObject[item.question_name + '_version'] = item.version;
         if (item.version > 1) {
          this.isshowMessage = true;
        }
       }
     });

     return this.commonObject;
  }
}
