import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';

// tslint:disable-next-line:no-inferrable-types
export const TOKEN_NAME: string = 'buyer_jwt_token';
// tslint:disable-next-line:no-inferrable-types
export const REFRESH_TOKEN_NAME: string = 'buyer_jwt_refresh_token';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

   // API Link
   public static _BACKEND_API_LINK = environment.backendapi;
  headersAuth: HttpHeaders;
  optionsAuth: any;
  constructor(private http: HttpClient, private router: Router) {
   }

  getAuthHttpOptions() {
    this.headersAuth = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.headersAuth.set('Authorization', 'Bearer ' + this.getRefreshToken());
    this.optionsAuth = { headers: this.headersAuth };
    return this.optionsAuth;
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  getLogOutSystem() {
    localStorage.removeItem('localEmailAddress');
    this.removeToken();
    this.router.navigate(['buyer/home']);
  }

  removeToken(): void {
    return localStorage.removeItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  setRefreshToken(token: string): void {
    console.log(token);
    localStorage.setItem(REFRESH_TOKEN_NAME, token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken();}
    if (!token) { return true; }

    // const date = this.getTokenExpirationDate(token);
    // if (date === undefined) { return false; }
    // return !(date.valueOf() > new Date().valueOf());
    return false;
  }

  getrefreshtokenfromserver(): Observable<any> {
    return this.http.get(AuthService._BACKEND_API_LINK + '/buyer_refresh_web',
    this.getAuthHttpOptions());
  }

  /** Response and Errors Receive ************************ Start  */

  // Response split
  private extractRequests(res: Response) {
    if (res['_body']) {
      const data = JSON.parse(res['_body']);
      if (data != null && data.value) { return data.value; }
      // tslint:disable-next-line:one-line
      else { return data; }
    }
  }

  // Error Handler
  private handleError(error: Response | any) {
    // check JWT expired error - if expired refresh page, this'll redirect to login automatically by auth guard
    if (error.message === 'No JWT present or has expired') {
      location.reload();
    }

    let errMsg: string;
    if (error instanceof Response) {
      // const body = error.json() || '';
      const err = JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    // console.error(errMsg);
    return Observable.throw(errMsg);
  }
  /** Response and Errors Receive ************************ END  */
}
