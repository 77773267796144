import { Component, OnInit, NgZone, Input, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { BuyerService } from '../../service/buyer.service';
import { BuyerEventManager } from '../../buyer-event.manager';
import { ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import {} from 'googlemaps';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit, OnChanges {
  @Input() activeNumber: number;
  @Input() CategoryId: number;
  @ViewChild('firststepHiddenButton') firstStepSubmit: ElementRef;
  @ViewChild('firstsecondHiddenButton') firstsecondHiddenButton: ElementRef;
  @ViewChild('firstsecondTabHiddenButton') firstsecondTabHiddenButton: ElementRef;
  @ViewChild('subcomponent') subcomponent: ElementRef;
  @ViewChild('authentication') authentication: ElementRef;
  serviceCategories: Array<any> = [];
  Category_Id: number;
  buyerId: number;
  isActiveTab: number;
  isAddreesInvalid: boolean;
  imageurls: Array<any> = [];
  scope_upload_links: Array<any> = [];
  kitchen_details_do_you_want_to_move: Array<any> = [{ name: 'Golvbrunn' }, { name: 'Avlopp' }, { name: 'Ventilation' }];
  selectedkitchenrow: Array<any> = [];
  @ViewChild('search')
  public searchElementRef: ElementRef;

  multiselect = [
    {name: 'New York', code: 'NY'},
    {name: 'Rome', code: 'RM'},
    {name: 'London', code: 'LDN'},
    {name: 'Istanbul', code: 'IST'},
    {name: 'Paris', code: 'PRS'}
];

  commonListObject: any = {
    formone: [],
    formtwo: [],
    questionset: []

  };
  commonObject: any = {
    project_name: null,
    sub_category_position: null,
    service_category_id: null
  };
  commonDisableObject: any = {};
  commonmultiSelect: any = {};
  commonmultiSelectOptions: any = {};
  subCategoryList: Array<any> = [];
  isServiceRequestFromMap: boolean;
  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager,  private router: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
      this.buyereventManager.sendActiveStageNumberEmitter.subscribe((mode) => {
        this.isActiveTab = mode;
      });
      this.buyereventManager.senduploadedFilesEmitter.subscribe((mode) => {
        this.scope_upload_links = mode;
      });
     }

  ngOnInit() {
    // Query Params details
    this.router.queryParams.subscribe(params => {
      if (params['requestfrom'] === 'mapview' && params['callingpage'] === 'selectedpagehome' ) {
        this.isServiceRequestFromMap = true;
        this.commonObject = Object.assign(this.commonObject, JSON.parse(localStorage.getItem('YossocommonObject')));
        this.loadServiceCategories();
      }
    });

    this.router.params.subscribe(params => {
      if (params['servicerequest']) {
        this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));
        this.commonListObject.formtwo.map((item) => {
          if (item.type === 'multiselect') {
            this.commonmultiSelect[item.modal] = this.getMultiselectRow(this.commonObject[item.modal]);
          }
        });
      }

      if (params['buyer_id']) {
        this.commonObject.buyer_id = atob(params['buyer_id'].toString()) ;
        localStorage.removeItem('yossobuyerid');
        localStorage.setItem('yossobuyerid', atob(params['buyer_id'].toString()));
      }

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.CategoryId != null) {
      this.Category_Id = this.CategoryId;
      this.subCategoryList = this.buyerService.getSubCategoryType(this.CategoryId.toString());
      // this.loaderActivate = true;
      this.buyerService.getGeneralizedQuestionSet(this.CategoryId, 1).pipe(finalize(() => {
        // this.loaderActivate = false;
      })).subscribe(response => {
        this.commonListObject = JSON.parse(response.question_set);
        this.commonListObject.formtwo.map((item) => {
          if (item.type === 'multiselect') {
            this.commonmultiSelectOptions[item.modal] = this.getCommonMultiSelectArray(item.value);
          }
        });
        // this.loadAddressComponent();
        setInterval(() => {
          this.loadAddressComponent();
        }, 2500);
      }, err => {
        console.log(err);
      });
    }
  }
  // Load the Category Details
  loadServiceCategories() {
    this.buyerService.getServiceCategories().pipe(finalize(() => {
    })).subscribe(response => {
      this.serviceCategories = response;
      if (this.serviceCategories.length > 0) {
        this.subCategoryList = this.buyerService.getSubCategoryType(this.serviceCategories[0].service_category_id.toString());
        this.loadDummyDetailsForMapServiceRequest(this.serviceCategories[0].service_category_id);
      }
    }, err => {
      console.log(err);
    });
  }

  // Load the Dummy Details
  loadDummyDetailsForMapServiceRequest(id) {
    this.scope_upload_links = [];
    this.buyerService.getGeneralizedQuestionSet(id, 1).pipe(finalize(() => {
    })).subscribe(response => {
      this.commonListObject = JSON.parse(response.question_set);
      this.commonListObject.formtwo.map((item_sub) => {
        if (item_sub.type === 'multiselect') {
          this.commonmultiSelectOptions[item_sub.modal] = this.getCommonMultiSelectArray(item_sub.value);
        }
      });

      setInterval(() => {
        this.loadAddressComponent();
      }, 2000);
    }, err => {
      console.log(err);
    });
  }

  // On change category
  onChangecategory(item) {
    this.Category_Id = this.commonObject.service_category_id;
    this.subCategoryList = this.buyerService.getSubCategoryType(this.Category_Id.toString());
  }


  // Sub Category Change
  onChangeSubcategory(item) {
      this.buyerService.getGeneralizedQuestionSet(this.Category_Id, this.commonObject.sub_category_position).pipe(finalize(() => {
        // this.loaderActivate = false;
      })).subscribe(response => {
        this.commonListObject = JSON.parse(response.question_set);
        this.commonListObject.formtwo.map((item_sub) => {
          if (item_sub.type === 'multiselect') {
            this.commonmultiSelectOptions[item_sub.modal] = this.getCommonMultiSelectArray(item_sub.value);
          }
        });
        setInterval(() => {
          this.loadAddressComponent();
        }, 2500);
      }, err => {
        console.log(err);
      });
  }

  loadAddressComponent() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.commonObject.scope_street_name = place.formatted_address;
          // set latitude, longitude and zoom
          this.commonObject.lat = place.geometry.location.lat();
          this.commonObject.lon = place.geometry.location.lng();
          this.isAddreesInvalid = false;
          // this.zoom = 12;
        });
      });
    });
  }


   // Address validare
   onaddressValidateGoogle() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });
      this.commonObject.lat = null;
      this.commonObject.lon = null;
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.commonObject.scope_street_name = place.formatted_address;
          // set latitude, longitude and zoom
          this.commonObject.lat = place.geometry.location.lat();
          this.commonObject.lon = place.geometry.location.lng();
          this.isAddreesInvalid = false;
          // this.zoom = 12;
          return;
        });
      });
    });
  }


  // Call First Step Next Button
  callCommonFirstStep(model: any, isValid: boolean): void {
    this.isAddreesInvalid = false;
    if (!isValid) {
      return;
    }

    if (this.commonObject.lon == null || this.commonObject.lat == null) {
      this.isAddreesInvalid = true;
      return;
    }

    this.buyereventManager.sendActiveStageNumber(2);
    this.buyereventManager.sendUploadedFiles(this.scope_upload_links);
  }

// call Second Step
callCommonSecondStep(model: any, isValid: boolean): void {
  if (!isValid) {
    return;
  }
  if (this.commonListObject.formtwo.length > 0) {
    this.buyereventManager.sendActiveStageNumber(3);
    this.commonListObject.formtwo.map((item) => {
      if (item.type === 'multiselect') {
        if (Object.getOwnPropertyNames(this.commonmultiSelect).length > 0) {
          if (this.commonmultiSelect.hasOwnProperty(item.modal)) {
            this.commonObject[item.modal] = this.commonmultiSelect[item.modal].map(function (val) {
              return val['name'];
            }).toString();
          }
        }
      }
    });
    this.commonObject.service_category_id = this.Category_Id;
    localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));
    this.buyereventManager.sendKitchenModalOption(this.commonObject);
  }
}

// Call Move Tab Function Initialize
  callTabFunctionCall() {
    if (this.commonListObject.formtwo.length > 0) {
      this.commonListObject.formtwo.forEach((item) => {
        if (item.type === 'multiselect') {
          if (Object.getOwnPropertyNames(this.commonmultiSelect).length > 0) {
            if (this.commonmultiSelect.hasOwnProperty(item.modal)) {
              this.commonObject[item.modal] = this.commonmultiSelect[item.modal].map(function (val) {
                return val['name'];
              }).toString();
            }
          }
        }
      });
      this.commonObject.service_category_id = this.Category_Id;
      localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));
      this.buyereventManager.sendKitchenModalOption(this.commonObject);
    }
  }


  // This should work in node.js and other ES5 compliant implementations.
  isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  getCommonMultiSelectArray(arrayValue) {
    const returnitem: Array<any> = [];
    arrayValue.map((item) => {
      returnitem.push({ name: item });
    });
    return returnitem;
  }

  getMultiselectRow(item: string) {
    const returnitem: Array<any> = [];
    if (typeof item === 'string') {
      const itemarray = item.split(',');
      itemarray.forEach(val => {
        returnitem.push({ name: val });
      });
    }
    return returnitem;
  }



// Upload Files
onFileChange(event) {
  if (event.target.files && event.target.files.length > 0) {
    for (let index = 0; index < event.target.files.length; index++) {
      // tslint:disable-next-line:prefer-const
      let file = event.target.files[index];
      this.scope_upload_links.push(file);
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: Event) => { // called once readAsDataURL is completed
        this.imageurls.push(reader.result);
      };
    }
  }
}

  // Common Object
  onchangeModal(value, propertyvalue, mainvariablevalue) {
    this.commonDisableObject[propertyvalue] = false;
    this.commonObject[mainvariablevalue] = false;
    Object.keys(this.commonObject).forEach(e => {
      if (e.indexOf(value) > -1) {
        if (this.commonObject[e]) {
          this.commonObject[mainvariablevalue] = true;
          this.commonDisableObject[propertyvalue] = true;
          return;
        }
      }
    });
  }


// Call the Cancel uploaded images in Step 2 section
removebrowseAttachFiles(i): void {
  this.scope_upload_links.splice(i, 1);
}


  // Remove First Value From Dropdown
  getDropdownList(items: Array<any>, defaultvalue): Array<any> {
    items.map((item, index) => {
      if (item === defaultvalue) {
          items.splice(index, 1);
      }
    });
    return items;
  }

  generateYears() {
    const currentYear = new Date().getFullYear(), years = [];
    let startYear = 1970;

    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }



}
