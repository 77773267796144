import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, private authService: AuthService) {
    }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.authService.isTokenExpired()) {
    //   this.authService.getrefreshtokenfromserver().subscribe(res => {
    //     if (res != null) {
    //       // set the token again here
    //       this.authService.setToken(res.access_token);
    //       this.authService.setRefreshToken(res.refresh_token);
    //       return true;
    //     } else {
    //       this.router.navigate(['/login']);
    //       return false;
    //     }
    //   }, err => {
    //     this.router.navigate(['/login']);
    //     return false;
    //   });
    // }
    // return true;

    if (!this.authService.isTokenExpired()) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
