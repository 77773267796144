
// firebase chat message model
export class MessageModel {
  public id: string;
  public senderId: number;
  public recipientId: number;
  public messageText: string;
  public attachmentUrl: string;
  public attachmentFileType: string;
  public sentTime: any;
  public status: number;
  public fileName: string;
  constructor(
      _senderId: number | null,
      _recipientId: number | null,
      _messageText: string | null,
      _attachmentUrl: string | null,
      _attachmentFileType: string | null,
      _sentTime: any | null,
      _status: number | null,
      _fileName: string | null
  ) {
      this.senderId = _senderId;
      this.recipientId = _recipientId;
      this.messageText = _messageText;
      this.attachmentUrl = _attachmentUrl;
      this.attachmentFileType = _attachmentFileType;
      this.sentTime = _sentTime;
      this.status = _status;
      this.fileName = _fileName;
  }
}
