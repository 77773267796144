import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { SafetyboxComponent } from './safetybox/safetybox.component';
import { CanDeactivateGuard } from './service/can-deactivate-guard.service';
import { SignicatStatusComponent } from './signicat-status/signicat-status.component';
import { JoinProjectComponent } from './join-project/join-project.component';
import { AuthGuardService } from './service/auth-guard.service';

const routes: Routes = [
  {
    path: 'buyer/home',
    component: HomeComponent
  },
  {
    path: 'buyer/home/:buyer_id',
    component: HomeComponent,
   // canActivate: [AuthGuardService]
  },
  {
    path: 'buyer/home/dashboard/:authcheck',
    component: HomeComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'buyer/home/:servicerequest/:categoryid',
    component: HomeComponent,
   // canActivate: [AuthGuardService]
  },
  {
    path: 'buyer/servicerequest',
    component: ServiceRequestComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'buyer/servicerequest/:maprequest/:buyerid',
    component: ServiceRequestComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'buyer/servicerequest/:categoryid',
    component: ServiceRequestComponent,
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'buyer/safetybox',
    component: SafetyboxComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'buyer/safetybox/:review/:token',
    component: SafetyboxComponent
  },
  {
    path: 'signin/postproc/:request_id/:task_id/:status',
    component: SignicatStatusComponent
  },
  {
    path: 'buyer/join/project/:token',
    component: JoinProjectComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyerRoutingModule { }
