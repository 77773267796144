import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { BuyerService } from 'src/app/buyer/service/buyer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { BuyerLoginResponse, BuyerRegisterUpdate } from 'src/app/buyer/buyermodal';
import { MapsAPILoader } from '@agm/core';
import { AuthService } from 'src/app/buyer/service/auth.service';
// jQuery Sign $
declare let $: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  invalidCredentialMsg: string;
  loaderActivate: boolean;
  register: any = { regsiterBankId: '', uploadimageUrl: '' };
  fileData: Array<any> = [];
  registerErrorMessage: string;
  registeredBankId: string;
  uploadimageUrl: string;
  buyerId: number;
  buyerResponse: BuyerLoginResponse;
  updateRegister: BuyerRegisterUpdate;
  isBankIdNotRegistered: boolean;
  isAgreedAgreement: boolean;
  agreementfilter: boolean;
  isShowAgreement: boolean;
  isAlreadyExitemail: boolean;
  @ViewChild('authenticationsearch') authsearchElementRef: ElementRef;
  isAddreesInvalid: boolean;
  @ViewChild('termsandconditions') openTermsAndConditionModal: ElementRef;

  constructor(private buyerService: BuyerService,
    private router: Router, private translate: TranslateService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private authservice: AuthService) { }

  ngOnInit() {
       /** Mobile redirection Display the Error Message Page */
       if (window.location.href.includes('buyer/join/project') || window.location.href.includes('signin/postproc')) {

      } else {
        if (navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)) {
          this.router.navigate(['/mobileview']);
          return;
        }
      }
       /** Mobile redirection Display the Error Message Page */
    this.updateRegister = new BuyerRegisterUpdate();
    this.buyerResponse = new BuyerLoginResponse();
  }


  callRegisterSignicat(model: any, isValid: boolean) {
    this.registerErrorMessage = '';
    this.isBankIdNotRegistered = false;
    if (!isValid) {
      return;
    }

    if (this.register.regsiterBankId.includes('-') || (/\s/.test(this.register.regsiterBankId)) ) {
      this.translate.get('Common.BankIdErrorMessage').subscribe(res => {
        this.registerErrorMessage = res;
      });
      return;
    }

    this.registeredBankId = this.register.regsiterBankId;
    this.loaderActivate = true;
    this.buyerService.registerBuyerByBankId(this.register.regsiterBankId).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      if (response === -22) {
        this.translate.get('Login.BankIdexiststhesystem').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else if (response === -23) {
        this.translate.get('Login.InvalidbankID').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else if (response === -24) {
        this.translate.get('Login.Pleasesmartphonetablet').subscribe(res => {
          this.registerErrorMessage = res;
        });
      } else {
        this.buyerResponse = response;
        this.isBankIdNotRegistered = true;
        if (this.authsearchElementRef != null && this.authsearchElementRef !== undefined) {
        // this.loadMapLoaderInScreen();
         setInterval(() => {
          this.loadMapLoaderInScreen();
        }, 2000);
       }

      }
    }, err => {
      console.log(err);
    });
  }

   // photo upload select file
   onSelectFile(event) {
    this.fileData = [];
    if (event.target.files && event.target.files[0]) {
      this.fileData.push(event.target.files[0]);
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: Event) => { // called once readAsDataURL is completed
        this.register.uploadimageUrl = reader.result;
      };
    }
  }


  updateRegisterPage(model: any, isValid: boolean) {
    this.registerErrorMessage = '';
    if (!isValid) {
      return;
    }
    if (this.isAlreadyExitemail) {
      return;
    }
    this.loaderActivate = true;
    this.updateRegister.bank_id = this.registeredBankId;
    this.updateRegister.given_name = this.buyerResponse.given_name;
    this.updateRegister.family_name = this.buyerResponse.family_name;
    if (this.updateRegister.contact_no === null || this.updateRegister.contact_no === undefined) {
      this.updateRegister.contact_no = 0;
    }
    this.buyerService.registerBuyerInformationWithBankId(this.fileData, this.updateRegister).pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      this.buyerResponse = response;
      this.buyerId = this.buyerResponse.buyer_id;
      this.authservice.setToken(this.buyerResponse.auth_token.access_token);
      localStorage.removeItem('localEmailAddress');
      localStorage.setItem('localEmailAddress', this.buyerResponse.email);
      localStorage.removeItem('yossobuyerid');
      localStorage.setItem('yossobuyerid', this.buyerId.toString());
      // this.isBankIdNotRegistered = false;
      this.router.navigate(['/buyer/safetybox']);

    }, err => {
      console.log(err);
    });

  }

   // Checking Email Exists or Not
   checkemailValidation(event: any) {
    this.isAlreadyExitemail = false;
    if (event.target.value !== undefined && event.target.value !== null && event.target.value !== '') {
      this.buyerService.checkBuyerEmailExitsOrNot(event.target.value).subscribe(res => {
        if (res) {
          this.isAlreadyExitemail = true;
        }
      }, err => {
        console.log(err);
      });
    }
  }

  // Accept the Terms and Conditions
  acceptTermsCondition() {
    this.isAgreedAgreement = true;
    this.isShowAgreement = false;
    $(this.openTermsAndConditionModal.nativeElement).modal('hide');
  }

   // Accept the Terms and Conditions
   declineTermsCondition() {
    this.isAgreedAgreement = false;
    this.isShowAgreement = false;
    this.agreementfilter = false;
    $(this.openTermsAndConditionModal.nativeElement).modal('hide');
  }


  onFilterChange(eve: any) {
    if (eve) {
      this.isShowAgreement = true;
      this.agreementfilter = true;
      $(this.openTermsAndConditionModal.nativeElement).modal('show');
    } else {
      this.agreementfilter = false;
      this.isAgreedAgreement = false;
    }
  }


  // Load the Map Screen
  loadMapLoaderInScreen() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.authsearchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.updateRegister.address = place.formatted_address;
          this.isAddreesInvalid = false;
        });
      });
    });
  }

   // Address validare
   onaddressValidateGoogle() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.authsearchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.updateRegister.address = place.formatted_address;
          // set latitude, longitude and zoom
          this.isAddreesInvalid = false;
          return;
        });
      });
    });
  }


}
