export class KitchenModal {
  kitchen_scope_kitchen_project_type: number;
  kitchen_scope_is_module: boolean;
  kitchen_scope_is_site_built: boolean;
  kitchen_scope_module_type: number; // : int; -- if scope_is_module == True
  scope_work_description: string;
  scope_street_name: string;
  scope_zip_code: string;
  scope_city: string;
  scope_project_budget: number;
  scope_price_offer: number;
  scope_upload_links: Array<any> = [];

  kitchen_details_floor_area: number;
  kitchen_details_ceiling_height: number;
  kitchen_details_is_there_a_local_shutdown_of_water_plumbing: number;
  kitchen_details_is_floor_heating_available: number;
  kitchen_details_do_you_want_to_move: string;
  kitchen_details_is_choosen_work: boolean;
  kitchen_details_cw_is_base_cabinets: boolean;
  kitchen_details_cw_cabinets_qty: number;
  kitchen_details_cw_is_corner_cabinet: boolean;
  kitchen_details_cw_corner_cabinet_qty: number;
  kitchen_details_cw_is_handle: boolean;
  kitchen_details_cw_handle_qty: number;
  kitchen_details_cw_is_shelves: boolean;
  kitchen_details_cw_shelves_qty: number;
  kitchen_details_cw_is_high_cabinets: boolean;
  kitchen_details_cw_high_cabinets_qty: number;
  kitchen_details_cw_is_commode: boolean;
  kitchen_details_cw_commode_qty: number;
  kitchen_details_cw_is_kitchen_island: boolean;
  kitchen_details_cw_kitchen_island_qty: number;
  kitchen_details_cw_is_gaps: boolean;
  kitchen_details_cw_gaps_qty: number;
  kitchen_details_cw_is_boxes: boolean;
  kitchen_details_cw_boxes_qty: boolean;
  kitchen_details_cw_is_mirror_cabinet: boolean;
  kitchen_details_cw_mirror_cabinet_qty: number;
  kitchen_details_cw_is_taka_connection: boolean;
  kitchen_details_cw_taka_connection_qty: number;
  kitchen_details_cw_is_cover_pages: boolean;
  kitchen_details_cw_cover_pages_qty: number;
  kitchen_details_cw_is_wall_cabinet: boolean;
  kitchen_details_cw_wall_cabinet_qty: number;
  kitchen_details_cw_is_uncertain: boolean;
  kitchen_details_cw_is_free_text: boolean;
  kitchen_details_cw_free_text: string;
  kitchen_details_is_counter_tops: boolean;
  kitchen_details_ct_is_concrete: boolean;
  kitchen_details_ct_is_glass: boolean;
  kitchen_details_ct_is_composite: boolean;
  kitchen_details_ct_is_tile: boolean;
  kitchen_details_ct_is_laminate: boolean;
  kitchen_details_ct_is_massive_wood: boolean;
  kitchen_details_ct_is_mosaic: boolean;
  kitchen_details_ct_is_rock_free: boolean;
  kitchen_details_ct_is_granite: boolean;
  kitchen_details_ct_is_uncertain: boolean;
  kitchen_details_ct_is_free_text: boolean;
  kitchen_details_ct_free_text: string;
  kitchen_details_is_splash_back: boolean;
  kitchen_details_sb_is_concrete: boolean;
  kitchen_details_sb_is_glass: boolean;
  kitchen_details_sb_is_composite: boolean;
  kitchen_details_sb_is_tile: boolean;
  kitchen_details_sb_is_laminate: boolean;
  kitchen_details_sb_is_mosaic: boolean;
  kitchen_details_sb_is_painting: boolean;
  kitchen_details_sb_is_rock_free: boolean;
  kitchen_details_sb_is_granite: boolean;
  kitchen_details_sb_is_wallpaper: boolean;
  kitchen_details_sb_is_uncertain: boolean;
  kitchen_details_sb_is_free_text: boolean;
  kitchen_details_sb_free_text: string;
  kitchen_details_is_white_goods: boolean;
  kitchen_details_wg_is_refrigerator: boolean;
  kitchen_details_wg_is_refrigerator_free_standing: boolean;
  kitchen_details_wg_refrigerator_free_standing_qty: number;
  kitchen_details_wg_is_refrigerator_integrated: boolean;
  kitchen_details_wg_refrigerator_integrated_qty: number;
  kitchen_details_wg_is_freezer: boolean;
  kitchen_details_wg_is_freezer_free_standing: boolean;
  kitchen_details_wg_freezer_free_standing_qty: number;
  kitchen_details_wg_is_freezer_integrated: boolean;
  kitchen_details_wg_freezer_integrated_qty: number;
  kitchen_details_wg_is_micro: boolean;
  kitchen_details_wg_is_micro_free_standing: boolean;
  kitchen_details_wg_micro_free_standing_qty: number;
  kitchen_details_wg_is_micro_integrated: boolean;
  kitchen_details_wg_micro_integrated_qty: number;
  kitchen_details_wg_is_built_in_oven: boolean;
  kitchen_details_wg_is_built_in_oven_qty: number;
  kitchen_details_wg_is_stove_top: boolean;
  kitchen_details_wg_is_stove_top_qty: number;
  kitchen_details_wg_is_gas_cooker: boolean;
  kitchen_details_wg_is_gas_cooker_qty: number;
  kitchen_details_wg_is_free_standing_stove: boolean;
  kitchen_details_wg_is_free_standing_stove_qty: number;
  kitchen_details_wg_is_dish_washer: boolean;
  kitchen_details_wg_is_dish_washer_qty: number;
  kitchen_details_wg_is_fan: boolean;
  kitchen_details_wg_is_fan_qty: number;
  kitchen_details_wg_is_free_text: boolean;
  kitchen_details_wg_free_text: string;
  kitchen_details_is_el: boolean;
  kitchen_details_el_is_troubleshooting: boolean;
  kitchen_details_el_is_retraction_of_electricity: boolean;
  kitchen_details_el_is_recessed_elongation: boolean;
  kitchen_details_el_is_lighting: boolean;
  kitchen_details_el_is_electric_floor_heating: boolean;
  kitchen_details_el_is_appliance: boolean;
  kitchen_details_el_is_fire_alarm: boolean;
  kitchen_details_el_is_telecommunications_network: boolean;
  // kitchen_details_el_is_electric_floor_heating: boolean;
  kitchen_details_el_is_RCD: boolean;
  kitchen_details_el_is_electrical_socket: boolean;
  kitchen_details_el_is_radiators: boolean;
  kitchen_details_el_is_spotlights: boolean;
  kitchen_details_el_is_fan: boolean;
  kitchen_details_el_is_elimination_of_electricity: boolean;
  kitchen_details_el_is_surface_electricity: boolean;
  // kitchen_details_el_is_surface_electricity: boolean;
  kitchen_details_el_is_uncertain: boolean;
  kitchen_details_el_is_free_text: boolean;
  kitchen_details_el_free_text: string;
  kitchen_details_is_plumbing: boolean;
  kitchen_details_pl_is_installation_of_mixer: boolean;
  kitchen_details_pl_is_floor_drain: boolean;
  kitchen_details_pl_is_installation_of_water: boolean;
  kitchen_details_pl_is_piping: boolean;
  kitchen_details_pl_is_water_pipe: boolean;
  kitchen_details_pl_is_appliance: boolean;
  kitchen_details_pl_is_water_heater: boolean;
  kitchen_details_pl_is_drainage_pipes: boolean;
  kitchen_details_pl_is_installation_of_water_trap: boolean;
  kitchen_details_pl_is_uncertain: boolean;
  kitchen_details_pl_is_free_text: boolean;
  kitchen_details_pl_free_text: string;

  kitchen_details_fl_anf_wttnf_icf_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_icf_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_icf_free_text: string;
  kitchen_details_fl_anf_wttnf_itf_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_itf_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_itf_free_text: string;

  kitchen_details_fl_anf_wttnf_il_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_il_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_il_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_il_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_il_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_il_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_il_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_il_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_il_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_il_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_il_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_il_free_text: string;

  kitchen_details_fl_anf_wttnf_iv_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_iv_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_iv_free_text: string;

  kitchen_details_fl_anf_wttnf_ipf_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_ipf_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_ipf_free_text: string;

  kitchen_details_fl_anf_wttnf_ic_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_ic_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_ic_free_text: string;

  kitchen_details_fl_anf_wttnf_it_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_it_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_it_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_it_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_it_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_it_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_it_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_it_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_it_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_it_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_it_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_it_free_text: string;

  kitchen_details_fl_anf_wttnf_iwf_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_iwf_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_iwf_free_text: string;

  kitchen_details_fl_anf_wttnf_igf_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_igf_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_igf_free_text: string;

  kitchen_details_fl_anf_wttnf_iun_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_iun_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_iun_free_text: string;

  kitchen_details_fl_anf_wttnf_ift_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_painting: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_oil: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_ift_is_free_text: boolean;
  kitchen_details_fl_anf_wttnf_ift_free_text: string;



  kitchen_details_is_floor: boolean;
  kitchen_details_fl_is_add_new_floor: boolean;
  kitchen_details_fl_anf_is_concrete_floors: boolean;
  kitchen_details_fl_anf_is_tiled_floor: boolean;
  kitchen_details_fl_anf_is_laminate: boolean;
  kitchen_details_fl_anf_is_vinyl: boolean;
  kitchen_details_fl_anf_is_parquet_floor: boolean;
  kitchen_details_fl_anf_is_carpet: boolean;
  kitchen_details_fl_anf_is_tessellation: boolean;
  kitchen_details_fl_anf_is_wood_floors: boolean;
  kitchen_details_fl_anf_is_granite_floor: boolean;
  kitchen_details_fl_anf_is_uncertain: boolean;
  kitchen_details_fl_anf_is_free_text: boolean;
  kitchen_details_fl_anf_free_text: string;




  kitchen_details_fl_anf_want_to_treat_new_floor: boolean;
  kitchen_details_fl_anf_wttnf_is_joining: boolean;
  kitchen_details_fl_anf_wttnf_is_hardwax: boolean;
  kitchen_details_fl_anf_wttnf_is_lacquer: boolean;
  kitchen_details_fl_anf_wttnf_is_lasering: boolean;
  kitchen_details_fl_anf_wttnf_is_lye: boolean;
  kitchen_details_fl_anf_wttnf_is_oil: boolean;
  // pianting misssing
  kitchen_details_fl_anf_wttnf_is_grinding: boolean;
  kitchen_details_fl_anf_wttnf_is_soap: boolean;
  kitchen_details_fl_anf_wttnf_is_uncertain: boolean;
  kitchen_details_fl_anf_wttnf_is_free_text: boolean;
  // diddnt use freetxt
  kitchen_details_fl_anf_wttnf_free_text: string;



  kitchen_details_fl_is_renovate: boolean;
  kitchen_details_fl_ren_is_joining: boolean;
  kitchen_details_fl_ren_is_hardwax: boolean;
  kitchen_details_fl_ren_is_lacquer: boolean;
  kitchen_details_fl_ren_is_repair_of_damage: boolean;
  kitchen_details_fl_ren_is_lasering: boolean;
  kitchen_details_fl_ren_is_caustic_treatment: boolean;
  kitchen_details_fl_ren_is_painting: boolean;
  kitchen_details_fl_ren_is_oiling: boolean;
  kitchen_details_fl_ren_is_grinding: boolean;
  kitchen_details_fl_ren_is_soap_treatment: boolean;
  kitchen_details_fl_ren_is_uncertain: boolean;
  kitchen_details_fl_ren_is_free_text: boolean;
  kitchen_details_fl_ren_free_text: string;
  kitchen_details_is_wall: boolean;
  kitchen_details_wa_is_new_wall: boolean;
  kitchen_details_wa_nw_is_concrete_wall: boolean;

  kitchen_details_wa_nw_wttnw_icw_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_icw_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_icw_free_text: string;

  kitchen_details_wa_nw_is_concrete_glass_wall: boolean;

  kitchen_details_wa_nw_wttnw_icgw_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_icgw_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_icgw_free_text: string;

  kitchen_details_wa_nw_is_gypsum_wall: boolean;

  kitchen_details_wa_nw_wttnw_igw_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_igw_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_igw_free_text: string;

  kitchen_details_wa_nw_is_glass_wall: boolean;

  kitchen_details_wa_nw_wttnw_iglw_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_iglw_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_iglw_free_text: string;

  kitchen_details_wa_nw_is_uncertain: boolean;

  kitchen_details_wa_nw_wttnw_iun_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_iun_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_iun_free_text: string;

  kitchen_details_wa_nw_is_free_text: boolean;
  kitchen_details_wa_nw_free_text: string;

  kitchen_details_wa_nw_wttnw_ift_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_ift_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_ift_free_text: string;

  // not include
  kitchen_details_wa_nw_want_to_treat_new_wall: boolean;
  kitchen_details_wa_nw_wttnw_is_priming: boolean;
  kitchen_details_wa_nw_wttnw_is_tiling: boolean;
  kitchen_details_wa_nw_wttnw_is_painting: boolean;
  kitchen_details_wa_nw_wttnw_is_grinding: boolean;
  kitchen_details_wa_nw_wttnw_is_filling: boolean;
  kitchen_details_wa_nw_wttnw_is_wallpapering: boolean;
  kitchen_details_wa_nw_wttnw_is_trimming: boolean;
  kitchen_details_wa_nw_wttnw_is_uncertain: boolean;
  kitchen_details_wa_nw_wttnw_is_free_text: boolean;
  kitchen_details_wa_nw_wttnw_free_text: string;
  kitchen_details_wa_is_renovate: boolean;
  kitchen_details_wa_ren_is_priming: boolean;
  kitchen_details_wa_ren_is_tiling: boolean;
  kitchen_details_wa_ren_is_repair_of_damage: boolean;
  kitchen_details_wa_ren_is_painting: boolean;
  kitchen_details_wa_ren_is_grinding: boolean;
  kitchen_details_wa_ren_is_filling: boolean;
  kitchen_details_wa_ren_is_wallpapering: boolean;
  kitchen_details_wa_ren_is_trimming: boolean;
  kitchen_details_wa_ren_is_uncertain: boolean;
  kitchen_details_wa_ren_is_free_text: boolean;
  kitchen_details_wa_ren_free_text: string;


  kitchen_details_is_ceiling: boolean;
  kitchen_details_ce_is_new_ceiling: boolean;
  kitchen_details_ce_nc_is_acoustic_plates: boolean;

  kitchen_details_ce_nc_wttnc_iap_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_iap_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_iap_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_iap_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_iap_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_iap_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_iap_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_iap_free_text: string;

  kitchen_details_ce_nc_is_ceiling_panels: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_icp_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_icp_free_text: string;

  kitchen_details_ce_nc_is_sunken_ceilings: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_isc_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_isc_free_text: string;

  kitchen_details_ce_nc_is_panel_ceilings: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_ipc_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_ipc_free_text: string;

  kitchen_details_ce_nc_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_iun_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_iun_free_text: string;

  kitchen_details_ce_nc_is_free_text: boolean;
  kitchen_details_ce_nc_free_text: string;

  kitchen_details_ce_nc_wttnc_ift_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_ift_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_ift_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_ift_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_ift_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_ift_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_ift_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_ift_free_text: string;

  kitchen_details_ce_nc_want_to_treat_new_cave: boolean;
  kitchen_details_ce_nc_wttnc_is_priming: boolean;
  kitchen_details_ce_nc_wttnc_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_nc_wttnc_is_painting: boolean;
  kitchen_details_ce_nc_wttnc_is_grinding: boolean;
  kitchen_details_ce_nc_wttnc_is_filling: boolean;
  kitchen_details_ce_nc_wttnc_is_uncertain: boolean;
  kitchen_details_ce_nc_wttnc_is_free_text: boolean;
  kitchen_details_ce_nc_wttnc_free_text: string;
  kitchen_details_ce_is_renovate: boolean;
  kitchen_details_ce_ren_is_priming: boolean;
  kitchen_details_ce_ren_is_installation_of_weave_loss: boolean;
  kitchen_details_ce_ren_is_repair_of_damage: boolean;
  kitchen_details_ce_ren_is_painting: boolean;
  kitchen_details_ce_ren_is_grinding: boolean;
  kitchen_details_ce_ren_is_filling: boolean;
  kitchen_details_ce_ren_is_uncertain: boolean;
  kitchen_details_ce_ren_is_free_text: boolean;
  kitchen_details_ce_ren_free_text: string;
  kitchen_details_is_other_installation: boolean;

  kitchen_details_oi_is_door: boolean;
  kitchen_details_oi_door_qty: number;

  kitchen_details_oi_poi_id_is_putty: boolean;
  kitchen_details_oi_poi_id_is_grind: boolean;
  kitchen_details_oi_poi_id_is_prime: boolean;
  kitchen_details_oi_poi_id_is_paint: boolean;
  kitchen_details_oi_poi_id_is_uncertain: boolean;
  kitchen_details_oi_poi_id_is_free_text: boolean;
  kitchen_details_oi_poi_id_free_text: string;

  kitchen_details_oi_is_door_lining: boolean;
  kitchen_details_oi_door_lining_qty: number;

  kitchen_details_oi_poi_idl_is_putty: boolean;
  kitchen_details_oi_poi_idl_is_grind: boolean;
  kitchen_details_oi_poi_idl_is_prime: boolean;
  kitchen_details_oi_poi_idl_is_paint: boolean;
  kitchen_details_oi_poi_idl_is_uncertain: boolean;
  kitchen_details_oi_poi_idl_is_free_text: boolean;
  kitchen_details_oi_poi_idl_free_text: string;

  kitchen_details_oi_is_door_frame: boolean;
  kitchen_details_oi_door_frame_qty: number;

  kitchen_details_oi_poi_idf_is_putty: boolean;
  kitchen_details_oi_poi_idf_is_grind: boolean;
  kitchen_details_oi_poi_idf_is_prime: boolean;
  kitchen_details_oi_poi_idf_is_paint: boolean;
  kitchen_details_oi_poi_idf_is_uncertain: boolean;
  kitchen_details_oi_poi_idf_is_free_text: boolean;
  kitchen_details_oi_poi_idf_free_text: string;

  kitchen_details_oi_is_window: boolean;
  kitchen_details_oi_window_qty: number;

  kitchen_details_oi_poi_iw_is_putty: boolean;
  kitchen_details_oi_poi_iw_is_grind: boolean;
  kitchen_details_oi_poi_iw_is_prime: boolean;
  kitchen_details_oi_poi_iw_is_paint: boolean;
  kitchen_details_oi_poi_iw_is_uncertain: boolean;
  kitchen_details_oi_poi_iw_is_free_text: boolean;
  kitchen_details_oi_poi_iw_free_text: string;

  kitchen_details_oi_is_window_feed: boolean;
  kitchen_details_oi_window_feed_qty: number;

  kitchen_details_oi_poi_iwf_is_putty: boolean;
  kitchen_details_oi_poi_iwf_is_grind: boolean;
  kitchen_details_oi_poi_iwf_is_prime: boolean;
  kitchen_details_oi_poi_iwf_is_paint: boolean;
  kitchen_details_oi_poi_iwf_is_uncertain: boolean;
  kitchen_details_oi_poi_iwf_is_free_text: boolean;
  kitchen_details_oi_poi_iwf_free_text: string;

  kitchen_details_oi_is_window_frame: boolean;
  kitchen_details_oi_window_frame_qty: number;

  kitchen_details_oi_poi_iwfr_is_putty: boolean;
  kitchen_details_oi_poi_iwfr_is_grind: boolean;
  kitchen_details_oi_poi_iwfr_is_prime: boolean;
  kitchen_details_oi_poi_iwfr_is_paint: boolean;
  kitchen_details_oi_poi_iwfr_is_uncertain: boolean;
  kitchen_details_oi_poi_iwfr_is_free_text: boolean;
  kitchen_details_oi_poi_iwfr_free_text: string;

  kitchen_details_oi_is_base: boolean;
  kitchen_details_oi_base_qty: number;

  kitchen_details_oi_poi_ib_is_putty: boolean;
  kitchen_details_oi_poi_ib_is_grind: boolean;
  kitchen_details_oi_poi_ib_is_prime: boolean;
  kitchen_details_oi_poi_ib_is_paint: boolean;
  kitchen_details_oi_poi_ib_is_uncertain: boolean;
  kitchen_details_oi_poi_ib_is_free_text: boolean;
  kitchen_details_oi_poi_ib_free_text: string;

  kitchen_details_oi_is_threshold: boolean;
  kitchen_details_oi_threshold_qty: number;

  kitchen_details_oi_poi_it_is_putty: boolean;
  kitchen_details_oi_poi_it_is_grind: boolean;
  kitchen_details_oi_poi_it_is_prime: boolean;
  kitchen_details_oi_poi_it_is_paint: boolean;
  kitchen_details_oi_poi_it_is_uncertain: boolean;
  kitchen_details_oi_poi_it_is_free_text: boolean;
  kitchen_details_oi_poi_it_free_text: string;

  kitchen_details_oi_is_uncertain: boolean;
  kitchen_details_oi_uncertain_qty: number;

  kitchen_details_oi_poi_iun_is_putty: boolean;
  kitchen_details_oi_poi_iun_is_grind: boolean;
  kitchen_details_oi_poi_iun_is_prime: boolean;
  kitchen_details_oi_poi_iun_is_paint: boolean;
  kitchen_details_oi_poi_iun_is_uncertain: boolean;
  kitchen_details_oi_poi_iun_is_free_text: boolean;
  kitchen_details_oi_poi_iun_free_text: string;

  kitchen_details_oi_is_free_text: boolean;
  kitchen_details_oi_free_text: string;

  kitchen_details_oi_poi_ift_is_putty: boolean;
  kitchen_details_oi_poi_ift_is_grind: boolean;
  kitchen_details_oi_poi_ift_is_prime: boolean;
  kitchen_details_oi_poi_ift_is_paint: boolean;
  kitchen_details_oi_poi_ift_is_uncertain: boolean;
  kitchen_details_oi_poi_ift_is_free_text: boolean;
  kitchen_details_oi_poi_ift_free_text: string;


  kitchen_details_oi_is_processing_of_installation: boolean;
  kitchen_details_oi_poi_is_putty: boolean;
  kitchen_details_oi_poi_is_grind: boolean;
  kitchen_details_oi_poi_is_prime: boolean;
  kitchen_details_oi_poi_is_paint: boolean;
  kitchen_details_oi_poi_is_uncertain: boolean;
  kitchen_details_oi_poi_is_free_text: boolean;
  kitchen_details_oi_poi_free_text: string;

  kitchen_details_is_kitchen_treatment: boolean;

  kitchen_details_kt_is_door: boolean;
  kitchen_details_kt_door_qty: number;

  kitchen_details_kt_tr_id_is_putty: boolean;
  kitchen_details_kt_tr_id_is_grind: boolean;
  kitchen_details_kt_tr_id_is_prime: boolean;
  kitchen_details_kt_tr_id_is_paint: boolean;
  kitchen_details_kt_tr_id_uncertain: boolean;
  kitchen_details_kt_tr_id_is_free_text: boolean;
  kitchen_details_kt_tr_id_free_text: string;

  kitchen_details_kt_is_door_lining: boolean;
  kitchen_details_kt_door_lining_qty: number;

  kitchen_details_kt_tr_idl_is_putty: boolean;
  kitchen_details_kt_tr_idl_is_grind: boolean;
  kitchen_details_kt_tr_idl_is_prime: boolean;
  kitchen_details_kt_tr_idl_is_paint: boolean;
  kitchen_details_kt_tr_idl_uncertain: boolean;
  kitchen_details_kt_tr_idl_is_free_text: boolean;
  kitchen_details_kt_tr_idl_free_text: string;

  kitchen_details_kt_is_door_frame: boolean;
  kitchen_details_kt_door_frame_qty: number;

  kitchen_details_kt_tr_idf_is_putty: boolean;
  kitchen_details_kt_tr_idf_is_grind: boolean;
  kitchen_details_kt_tr_idf_is_prime: boolean;
  kitchen_details_kt_tr_idf_is_paint: boolean;
  kitchen_details_kt_tr_idf_uncertain: boolean;
  kitchen_details_kt_tr_idf_is_free_text: boolean;
  kitchen_details_kt_tr_idf_free_text: string;

  kitchen_details_kt_is_window: boolean;
  kitchen_details_kt_window_qty: number;
  kitchen_details_kt_tr_iw_is_putty: boolean;
  kitchen_details_kt_tr_iw_is_grind: boolean;
  kitchen_details_kt_tr_iw_is_prime: boolean;
  kitchen_details_kt_tr_iw_is_paint: boolean;
  kitchen_details_kt_tr_iw_uncertain: boolean;
  kitchen_details_kt_tr_iw_is_free_text: boolean;
  kitchen_details_kt_tr_iw_free_text: string;

  kitchen_details_kt_is_window_feed: boolean;
  kitchen_details_kt_window_feed_qty: number;
  kitchen_details_kt_tr_iwf_is_putty: boolean;
  kitchen_details_kt_tr_iwf_is_grind: boolean;
  kitchen_details_kt_tr_iwf_is_prime: boolean;
  kitchen_details_kt_tr_iwf_is_paint: boolean;
  kitchen_details_kt_tr_iwf_uncertain: boolean;
  kitchen_details_kt_tr_iwf_is_free_text: boolean;
  kitchen_details_kt_tr_iwf_free_text: string;

  kitchen_details_kt_is_window_frame: boolean;
  kitchen_details_kt_window_frame_qty: number;
  kitchen_details_kt_tr_iwfr_is_putty: boolean;
  kitchen_details_kt_tr_iwfr_is_grind: boolean;
  kitchen_details_kt_tr_iwfr_is_prime: boolean;
  kitchen_details_kt_tr_iwfr_is_paint: boolean;
  kitchen_details_kt_tr_iwfr_uncertain: boolean;
  kitchen_details_kt_tr_iwfr_is_free_text: boolean;
  kitchen_details_kt_tr_iwfr_free_text: string;

  kitchen_details_kt_is_base: boolean;
  kitchen_details_kt_base_qty: number;
  kitchen_details_kt_tr_ib_is_putty: boolean;
  kitchen_details_kt_tr_ib_is_grind: boolean;
  kitchen_details_kt_tr_ib_is_prime: boolean;
  kitchen_details_kt_tr_ib_is_paint: boolean;
  kitchen_details_kt_tr_ib_uncertain: boolean;
  kitchen_details_kt_tr_ib_is_free_text: boolean;
  kitchen_details_kt_tr_ib_free_text: string;

  kitchen_details_kt_is_threshold: boolean;
  kitchen_details_kt_threshold_qty: number;
  kitchen_details_kt_tr_it_is_putty: boolean;
  kitchen_details_kt_tr_it_is_grind: boolean;
  kitchen_details_kt_tr_it_is_prime: boolean;
  kitchen_details_kt_tr_it_is_paint: boolean;
  kitchen_details_kt_tr_it_uncertain: boolean;
  kitchen_details_kt_tr_it_is_free_text: boolean;
  kitchen_details_kt_tr_it_free_text: string;

  kitchen_details_kt_is_uncertain: boolean;
  kitchen_details_kt_uncertain_qty: number;
  kitchen_details_kt_tr_iun_is_putty: boolean;
  kitchen_details_kt_tr_iun_is_grind: boolean;
  kitchen_details_kt_tr_iun_is_prime: boolean;
  kitchen_details_kt_tr_iun_is_paint: boolean;
  kitchen_details_kt_tr_iun_uncertain: boolean;
  kitchen_details_kt_tr_iun_is_free_text: boolean;
  kitchen_details_kt_tr_iun_free_text: string;

  kitchen_details_kt_is_free_text: boolean;
  kitchen_details_kt_free_text: string;

  kitchen_details_kt_tr_ift_is_putty: boolean;
  kitchen_details_kt_tr_ift_is_grind: boolean;
  kitchen_details_kt_tr_ift_is_prime: boolean;
  kitchen_details_kt_tr_ift_is_paint: boolean;
  kitchen_details_kt_tr_ift_uncertain: boolean;
  kitchen_details_kt_tr_ift_is_free_text: boolean;
  kitchen_details_kt_tr_ift_free_text: string;

  // kitchen_details_kt_free_text: string;
  kitchen_details_kt_is_treatment: boolean;
  kitchen_details_kt_tr_is_putty: boolean;
  kitchen_details_kt_tr_is_grind: boolean;
  kitchen_details_kt_tr_is_prime: boolean;
  kitchen_details_kt_tr_is_paint: boolean;
  kitchen_details_kt_tr_uncertain: boolean;
  kitchen_details_kt_tr_is_free_text: boolean;
  kitchen_details_kt_tr_free_text: string;

  request_preferred_company1_id: number;
  request_preferred_company2_id: number;
  request_preferred_company3_id: number;
  request_waiting_company1_id: number;
  request_waiting_company2_id: number;
  request_waiting_company3_id: number;

  project_name: string;
  service_category_id: number;
  lat: number;
  lon: number;
  buyer_id: number;

  kitchen_scope_module_type_version: number;
  scope_work_description_version: number;
  scope_price_offer_version: number;
  scope_project_budget_version: number;
  kitchen_details_is_there_a_local_shutdown_of_water_plumbing_version: number;
  kitchen_details_floor_area_version: number;
  kitchen_details_is_floor_heating_available_version: number;
  kitchen_details_ceiling_height_version: number;
  kitchen_details_do_you_want_to_move_version: number;
  sub_category_position: number;

}

export class BuyerSellerModal {
  address: string;
  area_name: string;
  company_name: string;
  company_registration_no: string;
  email: string;
  pin_point_lat: number;
  pin_point_lon: number;
  seller_first_name: string;
  seller_id: number;
  seller_last_name: string;
  summary: string;
}

export class SellerProfileUser {
  address: string;
  company_name: string;
  company_registration_no: string;
  contact_no: string;
  seller_email: string;
  first_name: string;
  image_url: string;
  is_activate: string;
  last_name: string;
  seller_id: string;
  tax_no: string;
  user_id: string;
  user_type: string;
  username: string;
  website: string;
  summary: string;
  company_lon: number;
  company_lat: number;
}


export class BuyerLoginResponse {
  auth_token: any;
  address: string;
  buyer_id: number;
  contact_no: string;
  email: string;
  family_name: string;
  given_name: string;
  image_url: string;
}

export class BuyerRegisterUpdate {
  bank_id: string;
  email: string;
  contact_no: number;
  address: string;
  family_name: string;
  given_name: string;
}
