import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { BuyerService } from '../../service/buyer.service';
import { BuyerEventManager } from '../../buyer-event.manager';
import { KitchenModal } from '../../buyermodal';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import {} from 'googlemaps';

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.scss']
})
export class KitchenComponent implements OnInit, OnChanges {

  @Input() activeNumber: number;
  @Input() CategoryId: number;
  @ViewChild('firststepHiddenButton') firstStepSubmit: ElementRef;
  @ViewChild('firstsecondHiddenButton') firstsecondHiddenButton: ElementRef;
  @ViewChild('subcomponent') subcomponent: ElementRef;
  @ViewChild('authentication') authentication: ElementRef;

  buyerId: number;
  isActiveTab: number;
  kitchen: KitchenModal;
  kitchenSub: any = {};
  isAddreesInvalid: boolean;
  is_detailsChoosenwork: boolean;
  is_detailscounterTops: boolean;
  is_details_is_splash_back: boolean;
  is_details_is_white_goods: boolean;
  is_details_is_el: boolean;
  is_details_is_plumbing: boolean;
  is_details_is_floor: boolean;
  is_details_is_wall: boolean;
  is_details_is_ceiling: boolean;
  is_details_is_other_installation: boolean;
  is_details_is_kitchen_treatment: boolean;
  imageurls: Array<any> = [];
  scope_upload_links: Array<any> = [];
  kitchen_details_do_you_want_to_move: Array<any> = [{ name: 'Golvbrunn' }, { name: 'Avlopp' }, { name: 'Ventilation' }];
  selectedkitchenrow: Array<any> = [];
  @ViewChild('search')
  public searchElementRef: ElementRef;

  commonListObject: any = {
    formone: [],
    formtwo: [],
    questionset: []

  };
  commonObject: any = {};
  commonDisableObject: any = {};

  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager,  private router: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
    this.buyereventManager.sendActiveStageNumberEmitter.subscribe((mode) => {
      this.isActiveTab = mode;
    });

    this.buyereventManager.senduploadedFilesEmitter.subscribe((mode) => {
      this.scope_upload_links = mode;
    });

    this.loadAddressComponent();
   }

  ngOnInit() {
   // this.commonObjectArray = this.buyerService.getKitchenObjectByJson();
    this.kitchen = new KitchenModal ();
    this.kitchenSub.projectType = [];
    this.kitchenSub.typeOfModule = [];
    this.getkitchenProjectType();
    this.getTypeOfKitchenModule();
    this.router.params.subscribe(params => {
      if (params['servicerequest']) {
        this.kitchen = JSON.parse(localStorage.getItem('YossocommonObject'));
        if (this.kitchen.kitchen_details_do_you_want_to_move != null || this.kitchen.kitchen_details_do_you_want_to_move !== undefined) {
          this.kitchen.kitchen_details_do_you_want_to_move.split(',').map((item) => {
            if (item === 'Golvbrunn') {
              this.selectedkitchenrow.push({ name: 'Golvbrunn' });
            } else if (item === 'Avlopp') {
              this.selectedkitchenrow.push({ name: 'Avlopp' });
            } else if (item === 'Ventilation') {
              this.selectedkitchenrow.push({ name: 'Ventilation' });
            }
          });
        }
      }

      if (params['buyer_id']) {
        this.kitchen.buyer_id = params['buyer_id'];
        localStorage.removeItem('yossobuyerid');
        localStorage.setItem('yossobuyerid', params['buyer_id']);
      }

    });


  }

  loadAddressComponent() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.kitchen.scope_street_name = place.formatted_address;
          // set latitude, longitude and zoom
          this.kitchen.lat = place.geometry.location.lat();
          this.kitchen.lon = place.geometry.location.lng();
          this.isAddreesInvalid = false;
          // this.zoom = 12;
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.CategoryId != null) {
      // this.loaderActivate = true;
      this.buyerService.getGeneralizedQuestionSet(this.CategoryId, 1).pipe(finalize(() => {
        // this.loaderActivate = false;
      })).subscribe(response => {
        this.commonListObject = JSON.parse(response.question_set);
        this.loadAddressComponent();
      }, err => {
        console.log(err);
      });
    }
  }

 // get the Kitchecn Type for project
 getkitchenProjectType() {
  this.kitchenSub.projectType = this.buyerService.getkitchenProjectType();
}

// get Kitchecn Modules
getTypeOfKitchenModule() {
  this.kitchenSub.typeOfModule = this.buyerService.getTypeOfKitchenModule();
}

  // Address validare
  onaddressValidateGoogle() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // tslint:disable-next-line:prefer-const
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'], componentRestrictions: { country: 'SE' }
      });
      this.kitchen.lat = null;
      this.kitchen.lon = null;
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          // tslint:disable-next-line:prefer-const
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            this.isAddreesInvalid = true;
            return;
          }

          this.kitchen.scope_street_name = place.formatted_address;
          // set latitude, longitude and zoom
          this.kitchen.lat = place.geometry.location.lat();
          this.kitchen.lon = place.geometry.location.lng();
          this.isAddreesInvalid = false;
          // this.zoom = 12;
          return;
        });
      });
    });

    // this.isAddreesInvalid = true;
  }

  callKitchenFirstStep(model: any, isValid: boolean): void {
    this.isAddreesInvalid = false;
    if (!isValid) {
      return;
    }

    if (this.kitchen.lon == null || this.kitchen.lat == null) {
      this.isAddreesInvalid = true;
      return;
    }

    this.buyereventManager.sendActiveStageNumber(2);
    this.buyereventManager.sendUploadedFiles(this.scope_upload_links);

    // tslint:disable-next-line:prefer-const
    // let body = {
    //   scope_street_name: this.kitchen.scope_street_name,
    //   scope_zip_code: this.kitchen.scope_zip_code,
    //   scope_city: this.kitchen.scope_city
    // };
    // this.buyereventManager.sendLoaderHomeModal(true);
    // this.buyerService.validateAddrees(body).pipe(finalize(() => {
    //   this.buyereventManager.sendLoaderHomeModal(false);
    // })).subscribe(res => {
    //     if (res === 200) {
      // this.buyereventManager.sendActiveStageNumber(2);
      // this.buyereventManager.sendUploadedFiles(this.scope_upload_links);
    //     } else {
    //       this.isAddreesInvalid = true;
    //     }
    //   }, err => {
    //     console.log(err);
    //   });
  }

// call Second Step
callKitchenSecondStep(model: any, isValid: boolean): void {
  if (!isValid) {
    return;
  }
  this.buyereventManager.sendActiveStageNumber(3);
  this.kitchen.kitchen_details_do_you_want_to_move = this.selectedkitchenrow.map(function(item) {
    return item['name'];
  }).toString();
  this.kitchen = this.commonObject;
  this.buyereventManager.sendKitchenModalOption(this.kitchen);
}

// Upload Files
onFileChange(event) {
  if (event.target.files && event.target.files.length > 0) {
    for (let index = 0; index < event.target.files.length; index++) {
      // tslint:disable-next-line:prefer-const
      let file = event.target.files[index];
      this.scope_upload_links.push(file);
      // tslint:disable-next-line:prefer-const
      let reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: Event) => { // called once readAsDataURL is completed
        this.imageurls.push(reader.result);
      };
    }
  }

    // this.buyerService.uploadagreementbrowsefile(0, file).subscribe(res => {
      //   if (res != null) {
      //     this.kitchen.scope_upload_links.push(res);
      //   }
      // }, err => {
      //   console.log(err);
      // });
}


// Call the Cancel uploaded images in Step 2 section
removebrowseAttachFiles(i): void {
  this.scope_upload_links.splice(i, 1);
}

  // Question set change method accoring to
  kitchenchoosenwork_change($event) {
    // if (!this.kitchen.kitchen_details_is_choosen_work) {
    //   this.kitchen.kitchen_details_is_choosen_work = true;
    // }
  }

  kitchenchoosenwork_changesub(value, propertyvalue, mainvariablevalue) {
    this[propertyvalue] = false;
    this.kitchen[mainvariablevalue] = false;
    Object.keys(this.kitchen).forEach(e => {
      if (e.indexOf(value) > -1) {
        if (this.kitchen[e]) {
          this.kitchen[mainvariablevalue] = true;
            this[propertyvalue] = true;
            return;
        }
      }
    });
  }


  // Common Object
  onchangeModal(value, propertyvalue, mainvariablevalue) {
    this.commonDisableObject[propertyvalue] = false;
    this.commonObject[mainvariablevalue] = false;
    Object.keys(this.commonObject).forEach(e => {
      if (e.indexOf(value) > -1) {
        if (this.commonObject[e]) {
          this.commonObject[mainvariablevalue] = true;
          this.commonDisableObject[propertyvalue] = true;
          return;
        }
      }
    });
  }

  // Un release the all check boxes by Un check
  kitchenchoosenwork_uncheck(value, mainvariablevalue) {
    if (!this.kitchen[mainvariablevalue]) {
      Object.keys(this.kitchen).forEach(e => {
        if (e.indexOf(value) > -1) {
          if (this.kitchen[e]) {
            this.kitchen[e] = false;
          }
          if (e.indexOf('qty') > -1) {
            this.kitchen[e] = null;
          }

          if (e.indexOf('free_text') > -1) {
            this.kitchen[e] = null;
          }
        }
      });
    }
  }

  // Remove First Value From Dropdown
  getDropdownList(items: Array<any>, defaultvalue): Array<any> {
    items.map((item, index) => {
      if (item === defaultvalue) {
          items.splice(index, 1);
      }
    });
    return items;
  }



}
