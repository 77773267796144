import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuyerService } from '../service/buyer.service';
import { BuyerEventManager } from '../buyer-event.manager';
import { environment } from '../../../environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-signicat-status',
  templateUrl: './signicat-status.component.html',
  styleUrls: ['./signicat-status.component.scss']
})
export class SignicatStatusComponent implements OnInit {

  requestId: string;
  taskId: string;
  statusId: string;
  isnotfound: boolean;
  companyname: string;
  generatedlink: string;
  ismobileios: boolean;
  additional: number;
  active_request_safety_box_link: string;
  active_project_safety_box_link: string;
  loaderActivate: boolean;
  constructor(private router: Router, private route: ActivatedRoute,
    private buyerService: BuyerService, private buyereventManager: BuyerEventManager) {
     }

  ngOnInit() {
   this.generatedlink = environment.iossignicat +  this.router.url;
   const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
     this.ismobileios = true;
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      this.ismobileios = true;
    }
    this.additional = 0;
    this.active_request_safety_box_link = environment.frontendlink +
    '/seller/safetybox?requestto=safetybox&requesttype=main&selectedtab=agreement&projectid=';
    this.active_project_safety_box_link = environment.frontendlink +
    '/seller/safetybox?requestto=safetybox&requesttype=additional&selectedtab=agreement&projectid=';
    this.route.params.subscribe(params => {
      if (params['request_id']) {
        this.requestId = params['request_id'];
      }
      if (params['task_id']) {
        this.taskId = params['task_id'];
      }

      if (params['status'] === 'taskcomplete' || params['status'] === 'taskpostponed'
      || params['status'] === 'taskcancelled') {

        this.statusId = params['status'];
        if (this.taskId === 'task00001') {
          this.additional = 0;
        } else {
          this.additional = 1;
        }
        this.loaderActivate = true;
        this.buyerService.updateSignicatSignInStatus(this.requestId,
          this.taskId, this.statusId, this.additional, this.active_request_safety_box_link,
          this.active_project_safety_box_link).pipe(finalize(() => {
            this.loaderActivate = false;
          })).subscribe(response => {
          console.log('Signicat Verified  updated');
          this.companyname = response;
          console.log(this.companyname);
        }, err => {
          console.log(err);
        });

      } else {
        this.isnotfound = true;
      }

    });
  }

  callSafetyBox() {
    this.buyereventManager.sendAgreedProject(true);
    this.buyereventManager.sendAgreedProjectName(this.companyname);
    this.router.navigate(['/buyer/safetybox']);
  }

  openJavascriptlink() {
    console.log(this.generatedlink);
    window.open(this.generatedlink, '_self');
  }

}
