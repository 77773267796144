import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { KitchenModal } from './buyermodal';


@Injectable()
export class BuyerEventManager {

  private _sendActiveStageNumber: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public sendActiveStageNumberEmitter: Observable<number> = this._sendActiveStageNumber.asObservable();

  private _sendCategoryId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public sendCategoryIdEmitter: Observable<number> = this._sendCategoryId.asObservable();

  private _sendKitchenModalOption: BehaviorSubject<KitchenModal> = new BehaviorSubject<KitchenModal>(new KitchenModal());
  public sendKitchenModalOptionEmitter: Observable<KitchenModal> = this._sendKitchenModalOption.asObservable();

  private _closeModalByCallingEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public closeModalByCallingEventEmitter: Observable<boolean> = this._closeModalByCallingEvent.asObservable();


  private _sendActiveRequestSubmitEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendActiveRequestSubmitEmitter: Observable<boolean> = this._sendActiveRequestSubmitEvent.asObservable();

  private _sendActiveRequestProjectNameSubmitEvent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public sendActiveRequestProjectNameSubmitEmitter: Observable<string> = this._sendActiveRequestProjectNameSubmitEvent.asObservable();

  private _sendAgreedProjectEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendAgreedProjectEmitter: Observable<boolean> = this._sendAgreedProjectEvent.asObservable();

  private _sendAgreedProjectNameEvent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public sendAgreedProjectNameEmitter: Observable<string> = this._sendAgreedProjectNameEvent.asObservable();


  private _isAuthenticatedFromUserEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthenticatedFromUserEmitter: Observable<boolean> = this._isAuthenticatedFromUserEvent.asObservable();

  private _sendLoaderHomeModalEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendLoaderHomeModalEmitter: Observable<boolean> = this._sendLoaderHomeModalEvent.asObservable();

  private _sendPreferredList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public sendPreferredListEmitter: Observable<Array<any>> = this._sendPreferredList.asObservable();


  private _sendWaitingList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public sendwaitingListEmitter: Observable<Array<any>> = this._sendWaitingList.asObservable();

  private _senduploadedFiles: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public senduploadedFilesEmitter: Observable<Array<any>> = this._senduploadedFiles.asObservable();

  private _sendActivateNextButtonEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendActivateNextButtonEmitter: Observable<boolean> = this._sendActivateNextButtonEvent.asObservable();

  private _sendActivateSubmitButtonEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendActivateSubmitButtonEmitter: Observable<boolean> = this._sendActivateSubmitButtonEvent.asObservable();

  private _sendEnableSelectedNodeEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendEnableSelectedNodeEmitter: Observable<boolean> = this._sendEnableSelectedNodeEvent.asObservable();

  private _sendActivateLoginNextButtonEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sendActivateLoginNextButtonEmitter: Observable<boolean> = this._sendActivateLoginNextButtonEvent.asObservable();

  private _sendParentToChild: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sendParentToChildEmitter: Observable<any> = this._sendParentToChild.asObservable();
  constructor() { }

  sendActiveStageNumber(activeindex: number) {
    this._sendActiveStageNumber.next(activeindex);
  }

  sendCategoryId(activeindex: number) {
    this._sendCategoryId.next(activeindex);
  }

  closeModalByCallingEvent(activeindex: boolean) {
    this._closeModalByCallingEvent.next(activeindex);
  }

  sendLoaderHomeModal(isactive: boolean) {
    this._sendLoaderHomeModalEvent.next(isactive);
  }

  isAuthenticatedFromUser(isactive: boolean) {
    this._isAuthenticatedFromUserEvent.next(isactive);
  }

  isActivateNextButton(isactive: boolean) {
    this._sendActivateNextButtonEvent.next(isactive);
  }

  isActivateSubmitButton(isactive: boolean) {
    this._sendActivateSubmitButtonEvent.next(isactive);
  }

  isActivateLoginNextButton(isactive: boolean) {
    this._sendActivateLoginNextButtonEvent.next(isactive);
  }

  sendActiveRequest(activeindex: boolean) {
    this._sendActiveRequestSubmitEvent.next(activeindex);
  }

  sendAgreedProject(activeindex: boolean) {
    this._sendAgreedProjectEvent.next(activeindex);
  }

  sendEnableSelectedNodeMulti(activeindex: boolean) {
    this._sendEnableSelectedNodeEvent.next(activeindex);
  }

  sendActiveRequestProjectName(projectname: string) {
    this._sendActiveRequestProjectNameSubmitEvent.next(projectname);
  }

  sendAgreedProjectName(projectname: string) {
    this._sendAgreedProjectNameEvent.next(projectname);
  }

  sendKitchenModalOption(activeObj: KitchenModal) {
    this._sendKitchenModalOption.next(activeObj);
  }

  sendPreferredList(activeObj: Array<any>) {
    this._sendPreferredList.next(activeObj);
  }


  sendParentToChild(activeObj: Array<any>) {
    this._sendParentToChild.next(activeObj);
  }

  sendWaitingList(activeObj: Array<any>) {
    this._sendWaitingList.next(activeObj);
  }

  sendUploadedFiles(activeObj: any) {
    this._senduploadedFiles.next(activeObj);
  }

}
