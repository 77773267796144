import { Directive, ElementRef, HostListener } from '@angular/core';
import { DefaultValueAccessor, NgModel } from '@angular/forms';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[ngModel][numbersOnlyInput]' })
export class NumbersOnlyInputDirective {
  constructor(private valueAccessor: DefaultValueAccessor, private model: NgModel) {
    valueAccessor.registerOnChange = (fn: (val: any) => void) => {
      valueAccessor.onChange = (val) => {
        // tslint:disable-next-line:prefer-const
        let regexp = new RegExp('\\d+(?:[\.]\\d{0,})?$');
        // tslint:disable-next-line:prefer-const
        let isNumber: boolean = regexp.test(val);
        // tslint:disable-next-line:prefer-const
        let isEmpty: boolean = val === '';
        if (!isNumber && !isEmpty) {
          model.control.setValue(model.value);
          return;
        }
        return fn(val);
      };
    };
  }
}
