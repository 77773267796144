// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: 'eu-west-1',
  identityPoolId: 'eu-west-1:cd5f1011-3122-4598-94a1-0071b3e71a9c',
  userPoolId: 'eu-west-1_CpuSA9Ajj',
  clientId: '2qg73bt6binqftkm9hpd8557j0',
  //backendapi: 'http://localhost:5000',
  backendapi: 'http://34.242.130.245:5000/',
  //backendapi: 'https://pl73xmcmr4.execute-api.eu-west-1.amazonaws.com/dev',
  firebase: {
    apiKey: 'AIzaSyCT1jDBwjNk-WVcF48RudyRFVZys7fpJtw',
    authDomain: 'yosso-4f9e1.firebaseapp.com',
    databaseURL: 'https://yosso-4f9e1.firebaseio.com',
    projectId: 'yosso-4f9e1',
    storageBucket: 'yosso-4f9e1.appspot.com',
    messagingSenderId: '967010760047'
  },
  frontendlink: 'https://yossoweb.ascentic.se',
  buyerweblink: 'https://yossobuyer.ascentic.se',
  googleMapApikey: 'AIzaSyBKvg9U5lTyVuQRmbhoWfwK_tK0KjxtiUU',
  username: 'chandula.ucsc@gmail.com',
  // tslint:disable-next-line:max-line-length
  signicaturl: 'https://preprod.signicat.com/oidc/authorize?response_type=code&scope=openid+profile&client_id=demo-inapp&redirect_uri=https://example.com/redirect&acr_values=urn:signicat:oidc:method:sbid-inapp',
  iossignicat : 'yossobuyerdevapp:/',
  VERSION: require('../../package.json').version,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
