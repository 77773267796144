import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { BuyerService } from '../../service/buyer.service';
import { BuyerEventManager } from '../../buyer-event.manager';
import { Router, ActivatedRoute } from '@angular/router';
declare var require: any;

@Component({
  selector: 'app-sub-common',
  templateUrl: './sub-common.component.html',
  styleUrls: ['./sub-common.component.scss']
})
export class SubCommonComponent implements OnInit, OnChanges {

  @Input() CategoryId: number;
  public latitude: number;
  public longitude: number;
  public zoom: number;
  styles: Array<any> = [];
  protected map: any;
  labelOptions: any = {};
  serviceRequestSeller: Array<any> = [];
  preferedsellerlists: Array<any> = [];
  waitingsellerlists: Array<any> = [];
  category_Id: number;
  isAuthenticated: boolean;
  SelectedItemNone: string;
  @ViewChild('callfullviewmap') fullviewmapbutton: ElementRef;
  isServiceRequestFromMap: boolean;
  commonObject: any = {};
  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager,
    private router: ActivatedRoute, private route: Router) {
    this.buyereventManager.sendCategoryIdEmitter.subscribe((mode) => {
      this.category_Id = mode;
    });

    this.buyereventManager.sendPreferredListEmitter.subscribe((mode) => {
      this.preferedsellerlists = mode;
    });

    this.buyereventManager.sendwaitingListEmitter.subscribe((mode) => {
      this.waitingsellerlists = mode;
    });

    this.buyereventManager.isAuthenticatedFromUserEmitter.subscribe((mode) => {
      this.isAuthenticated = mode;
    });

    this.buyereventManager.sendEnableSelectedNodeEmitter.subscribe((mode) => {
      if (mode) {
        this.SelectedItemNone = 'selected';
        this.buyereventManager.isActivateNextButton(false);
        this.buyereventManager.isActivateSubmitButton(false);
      }
    });

   }

   ngOnChanges(changes: SimpleChanges) {
    if (this.CategoryId != null) {
      this.category_Id = this.CategoryId;
    }
  }

  ngOnInit() {

    this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));

    // Query Params details
    this.router.queryParams.subscribe(params => {
      if (params['requestfrom'] === 'mapview' && params['callingpage'] === 'selectedpagehome') {
        this.isServiceRequestFromMap = true;
        this.SelectedItemNone = 'selected';
        if (this.preferedsellerlists.length > 0) {
          this.buyereventManager.isActivateNextButton(false);
          this.buyereventManager.isActivateSubmitButton(false);
        }
      }
    });

    this.router.params.subscribe(params => {
      if (params['buyer_id']) {
       this.preferedsellerlists = [];
      }
    });
    // this.labelOptions = 'assets/images/mapmarker.png';
    this.labelOptions = {
      url:  'assets/images/mapmarker.png',
      scaledSize: {
        height: 50,
        width: 30
      }
    };
    this.styles = [
      {
        'featureType': 'all',
        'elementType': 'all',
        'stylers': [
          {
            'hue': '#e7ecf0'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [
          {
            'saturation': -70
          }
        ]
      },
      {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'simplified'
          },
          {
            'saturation': -60
          }
        ]
      }
    ];
    // set google maps defaults
    this.zoom = 5;
    this.latitude = 59.3293;
    this.longitude = 18.0686;

   // this.isActiveTab = 1;
    this.getAllSellersServiceRequest();
  }

  // Get All Sellers Service Request
  getAllSellersServiceRequest() {
    this.buyerService.getAllSellersServiceCategories().subscribe(response => {
      this.serviceRequestSeller = response;
      // console.log(response);

    }, err => {
      console.log(err);
    });
  }
  mapReady(map) {
    this.map = map;
  }


  // Convert to Number
  convertToNumber(item): Number {
    return Number(item);
  }

  // Mouse Over on the agm Map Marker
  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;

    infoWindow.open();
  }
  // Mouse Out Remove the Info Window
  OnMouseOutLeave(infoWindow, gm) {
    // if (gm.lastOpen != null) {
    //   gm.lastOpen.close();
    // }
  }
  // call the Full view Service Request View
  calltheFullViewServiceRequest() {
    this.buyereventManager.closeModalByCallingEvent(true);
    this.buyereventManager.sendPreferredList(this.preferedsellerlists);
    this.buyereventManager.sendWaitingList(this.waitingsellerlists);
    this.buyereventManager.isAuthenticatedFromUser(this.isAuthenticated);
    this.route.navigate(['buyer/servicerequest', this.category_Id]);
  }

  // Selected the Item
  getSelectedItem(item) {
    this.SelectedItemNone = item;
    if (item === 'selected') {
      this.buyereventManager.isActivateNextButton(false);
      this.buyereventManager.isActivateSubmitButton(false);

    } else {
      this.buyereventManager.isActivateSubmitButton(true);
      this.buyereventManager.isActivateNextButton(false);
    }
  }

}
