import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BuyerService } from '../service/buyer.service';
import { BuyerEventManager } from '../buyer-event.manager';
import { KitchenModal } from '../buyermodal';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isActiveTab: number;
  @ViewChild('closeModalBtn') closeModalBtn: ElementRef;
  @ViewChild('kitchenSubFun') childKitchencomponent;
  @ViewChild('firstStepModelOpen') firstStepModelOpen: ElementRef;
  @ViewChild('hiddenbuttonOpenModal') hiddenbuttonOpenModal: ElementRef;
  @ViewChild('panel', { read: ElementRef }) public panel: ElementRef<any>;
  loaderActivate: boolean;
  loaderMainModalActivate: boolean;
  commonObject: any = {
    project_name: null,
    sub_category_position: null,
    service_category_id: null
  };
  categoryId: number;
  serviceCategories: Array<any> = [];
  scope_upload_links: Array<any> = [];
  isAuthenticated: boolean;
  isActivateNextButton: boolean;
  isActivateSubmitButton: boolean;
  isActivateLoginButton: boolean;
  buyerId: number;
  isServiceRequestFromMap: boolean;
  preferedsellerlists: Array<any> = [];
  waitingsellerlists: Array<any> = [];
  userFirstname: string;
  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager,
    private router: Router, private route: ActivatedRoute, private authService: AuthService) {

    this.buyereventManager.closeModalByCallingEventEmitter.subscribe((mode) => {
      if (mode) {
        if (this.closeModalBtn !== undefined) {
          this.closeModalBtn.nativeElement.click();
        }
      }
    });
    this.buyereventManager.sendActiveStageNumberEmitter.subscribe((mode) => {
      this.isActiveTab = mode;
    });

    this.buyereventManager.isAuthenticatedFromUserEmitter.subscribe((mode) => {
      this.isAuthenticated = mode;
      if (mode) {
        this.userFirstname = localStorage.getItem('localEmailAddress');
      }
    });

    this.buyereventManager.senduploadedFilesEmitter.subscribe((mode) => {
      this.scope_upload_links = mode;
    });

    this.buyereventManager.sendKitchenModalOptionEmitter.subscribe((mode) => {
      localStorage.removeItem('YossocommonObject');
      this.commonObject = mode;
      localStorage.setItem('YossocommonObject', JSON.stringify(this.commonObject));
    });

    this.buyereventManager.sendPreferredListEmitter.subscribe((mode) => {
      this.preferedsellerlists = mode;
    });

    this.buyereventManager.sendwaitingListEmitter.subscribe((mode) => {
      this.waitingsellerlists = mode;
    });

    this.buyereventManager.sendLoaderHomeModalEmitter.subscribe((mode) => {
        this.loaderMainModalActivate = mode;
    });

    this.buyereventManager.sendActivateNextButtonEmitter.subscribe((mode) => {
      this.isActivateNextButton = mode;
    });

    this.buyereventManager.sendActivateSubmitButtonEmitter.subscribe((mode) => {
      this.isActivateSubmitButton = mode;
    });

    this.buyereventManager.sendActivateLoginNextButtonEmitter.subscribe((mode) => {
      this.isActivateLoginButton = mode;
    });


  }

  ngOnInit() {
    /** Mobile redirection Display the Error Message Page */
    if (window.location.href.includes('buyer/join/project') || window.location.href.includes('signin/postproc')) {

    } else {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        this.router.navigate(['/mobileview']);
        return;
      }
    }
     /** Mobile redirection Display the Error Message Page */

    this.isActiveTab = 1;
    this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
        // Query Params
    this.route.queryParams.subscribe(params => {
      if (params['requestfrom'] === 'mapview' && params['callingpage'] === 'selectedpagehome' ) {
        this.isServiceRequestFromMap = true;
        this.hiddenbuttonOpenModal.nativeElement.click();
      }
    });

    this.route.params.subscribe(params => {
      if (params['servicerequest']) {
        this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));
        this.isActiveTab = 4;
        this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
        this.categoryId = params['categoryid'];
        this.hiddenbuttonOpenModal.nativeElement.click();
      }

      if (params['buyer_id']) {
        this.isAuthenticated = true;
       // this.buyerId = params['buyer_id'];
        this.buyerId = Number(atob(params['buyer_id'].toString()));
        this.userFirstname = localStorage.getItem('localEmailAddress');

      }
    });

    if (!this.authService.isTokenExpired()) {
      this.isAuthenticated = true;
      this.buyerId = Number(localStorage.getItem('yossobuyerid'));
      this.userFirstname = localStorage.getItem('localEmailAddress');
    }

    this.loadServiceCategories();
    // this.callProtected();
  }

  // Call Protected method Test
  callProtected() {
    this.buyerService.protectedmethodtest().pipe(finalize(() => {
      // this.loaderActivate = false;
    })).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
    });
  }

  getDisable(): boolean {
    return this.isActivateNextButton;
  }
  // Load All the Service categories
  loadServiceCategories() {
    this.loaderActivate = true;
    this.buyerService.getServiceCategories().pipe(finalize(() => {
      this.loaderActivate = false;
    })).subscribe(response => {
      this.serviceCategories = response;
    }, err => {
      console.log(err);
    });
  }

  // Next tab Button
  moveNextTab(): void {
    if (this.isActiveTab === 1) {
      this.childKitchencomponent.firstStepSubmit.nativeElement.click();
    } else if (this.isActiveTab === 2) {
      this.childKitchencomponent.firstsecondHiddenButton.nativeElement.click();
      if (this.isAuthenticated) {
        this.isActiveTab = 4;
      }
      this.buyereventManager.isActivateLoginNextButton(true);
    } else if (this.isActiveTab === 3) {
      this.buyereventManager.sendActiveStageNumber(4);
      this.childKitchencomponent.authentication.authenticationVerifyButton.nativeElement.click();
      this.buyereventManager.isActivateNextButton(true);
    }
    this.panel.nativeElement.scrollTop = 0;
    this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
  }
  moveBackTab(): void {
    if (this.isActiveTab === 4) {
      if (this.isAuthenticated) {
        this.isActiveTab = 2;
      } else {
        this.isActiveTab = 3;
      }
      this.buyereventManager.isActivateLoginNextButton(false);
    } else if (this.isActiveTab === 2) {
      this.isActiveTab = 1;
    } else if (this.isActiveTab === 3) {
      this.isActiveTab = 2;
    }
    this.panel.nativeElement.scrollTop = 0;
    this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
  }

  callFunctionByTab(value): void {
    if (this.isActiveTab === 1) {
      this.childKitchencomponent.firstStepSubmit.nativeElement.click();
      // this.isActiveTab = value;
      // this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
      this.buyereventManager.isActivateLoginNextButton(true);
    } else if (this.isActiveTab === 3) {
      this.isActiveTab = value;
      this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
      this.childKitchencomponent.authentication.authenticationVerifyButton.nativeElement.click();
      this.buyereventManager.isActivateNextButton(true);
      // this.buyereventManager.isActivateLoginNextButton(true);
    } else if (this.isActiveTab === 2 && value === 3) {
      this.isActiveTab = value;
      this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
      this.buyereventManager.isActivateNextButton(true);
      this.childKitchencomponent.firstsecondTabHiddenButton.nativeElement.click();
    } else {
      this.isActiveTab = value;
      this.buyereventManager.sendActiveStageNumber(this.isActiveTab);
      this.buyereventManager.isActivateNextButton(true);
    }
    this.panel.nativeElement.scrollTop = 0;
  }

  // Selected Service Category Id
  selectedServiceCategories(item) {
    this.categoryId = item.service_category_id;
    this.buyereventManager.sendCategoryId(this.categoryId);
  }

  // Call the Submit Button for Last View Option
  callSubmitButtonFromLastMapView() {
    if (!this.isServiceRequestFromMap) {
      this.commonObject.service_category_id = this.categoryId;
    }
    this.loaderMainModalActivate = true;
    this.commonObject.request_preferred_company1_id = null;
    this.commonObject.request_preferred_company2_id = null;
    this.commonObject.request_preferred_company3_id = null;
    this.commonObject.request_waiting_company1_id = null;
    this.commonObject.request_waiting_company2_id = null;
    this.commonObject.request_waiting_company3_id = null;

    if (!this.commonObject['buyer_id']) {
      this.commonObject['buyer_id'] = Number(localStorage.getItem('yossobuyerid'));
    }

    this.commonObject.service_request_link = environment.frontendlink +
    '/seller/browserequest?requestto=browserequest&requesttype=openrequest&projectid=';
    this.buyerService.createServiceRequest(this.scope_upload_links, this.commonObject).pipe(finalize(() => {
      this.loaderMainModalActivate = false;
    })).subscribe(response => {
      console.log(response);
      localStorage.removeItem('YossocommonObject');
      this.router.navigate(['/buyer/safetybox']);
      this.buyereventManager.sendActiveRequest(true);
      this.buyereventManager.sendActiveRequestProjectName(this.commonObject.project_name);
      this.closeModalBtn.nativeElement.click();

    }, err => {
      console.log(err);
    });
  }

  // Call for Service Request from Map
  callSubmitButtonFromMapView() {
    this.commonObject = JSON.parse(localStorage.getItem('YossocommonObject'));

    if (this.preferedsellerlists.length > 0) {
      this.commonObject['request_preferred_company1_id'] = this.preferedsellerlists[0].seller_id;
    }

    if (this.preferedsellerlists.length > 1) {
      this.commonObject['request_preferred_company2_id'] = this.preferedsellerlists[1].seller_id;
    }

    if (this.preferedsellerlists.length > 2) {
      this.commonObject['request_preferred_company3_id'] = this.preferedsellerlists[2].seller_id;
    }

    if (this.waitingsellerlists.length > 0) {
      this.commonObject['request_waiting_company1_id'] = this.waitingsellerlists[0].seller_id;
    }

    if (this.waitingsellerlists.length > 1) {
      this.commonObject['request_waiting_company2_id'] = this.waitingsellerlists[1].seller_id;
    }

    if (this.waitingsellerlists.length > 2) {
      this.commonObject['request_waiting_company3_id'] = this.waitingsellerlists[2].seller_id;
    }

    if (this.commonObject == null || this.commonObject === undefined) {
      this.router.navigate(['/buyer/home']);
      return;
    }
    this.commonObject['buyer_id'] = Number(localStorage.getItem('yossobuyerid'));
    this.loaderMainModalActivate = true;
    this.commonObject.service_request_link = environment.frontendlink +
    '/seller/browserequest?requestto=browserequest&requesttype=requestme&projectid=';
    this.buyerService.createServiceRequest(this.scope_upload_links, this.commonObject).pipe(finalize(() => {
      this.loaderMainModalActivate = false;
    })).subscribe(response => {
      this.sendPrefferedSellersNotification(response);
      localStorage.removeItem('YossocommonObject');
      this.router.navigate(['/buyer/safetybox']);
      this.buyereventManager.sendActiveRequest(true);
      this.buyereventManager.sendActiveRequestProjectName(this.commonObject.project_name);
      this.closeModalBtn.nativeElement.click();

    }, err => {
      console.log(err);
    });
  }


  // Call the Preferred Sellers Notification
  sendPrefferedSellersNotification(projectid) {
    // tslint:disable-next-line:prefer-const
    let pid1 = 0;
    // tslint:disable-next-line:prefer-const
    let pid2 = 0;
    // tslint:disable-next-line:prefer-const
    let pid3 = 0;
    if (this.commonObject.request_preferred_company1_id != null) {
      pid1 = this.commonObject.request_preferred_company1_id;
    }

    if (this.commonObject.request_preferred_company2_id != null) {
      pid2 = this.commonObject.request_preferred_company2_id;
    }

    if (this.commonObject.request_preferred_company3_id != null) {
      pid3 = this.commonObject.request_preferred_company3_id;
    }
    const obj = {
      project_id: projectid,
      service_request_link: environment.frontendlink + '/seller/browserequest?requestto=browserequest&requesttype=requestme&projectid=',
      request_preferred_company1_id: pid1,
      request_preferred_company2_id: pid2,
      request_preferred_company3_id: pid3
    };
    this.buyerService.sendNotificationToPreferredSellers(obj).subscribe(response => {
      console.log(response);
    }, err => {
      console.log(err);
    });
  }

  // Call the Full view Map Function
  callFullViewMap() {
    this.childKitchencomponent.subcomponent.fullviewmapbutton.nativeElement.click();
  }

}
