import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { MobileRedirectionComponent } from './mobile-redirection/mobile-redirection.component';
import { RegistrationComponent } from './authentication/registration/registration.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full',
    redirectTo: 'buyer/home',
  },
   {
    path: 'mobileview',
    component: MobileRedirectionComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegistrationComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
