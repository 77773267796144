import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonConstants } from '../core/common.constants';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  // API Link
  public static _BACKEND_API_LINK = environment.backendapi;
  public static _FRONTEND_LINK = environment.frontendlink;
  headers: HttpHeaders;
  headersSignicat: HttpHeaders;
  headersAuth: HttpHeaders;
  options: any;
  optionsSignicat: any;
  optionsAuth: any;

  // Constructor Define --- header's Option in constructor
  constructor(public router: Router, private http: HttpClient, private authService: AuthService) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.options = { headers: this.headers };
  }

  // getSignicatRequestoptions() {
  //   this.headersSignicat = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  //   // this.headersSignicat.append('Access-Control-Allow-Origin', '*');
  //   this.optionsSignicat = { headers: this.headersSignicat };
  //   return this.optionsSignicat;
  // }

  // getAuthHttpOptions() {
  //   this.headersAuth = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   this.headersAuth.append('Authorization', 'Bearer ' + this.authService.getToken());
  //   this.optionsAuth = { headers: this.headersAuth };
  //   return this.optionsAuth;
  // }

  // Get Buyer Profile Information
  getBuyerProfileInformation(buyerid): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_buyer_profile/' + buyerid, this.options);
  }

    // Update Buyer Show Profile Details
    updateBuyerShowProfileInformation(file, body: any): Observable<any> {
      // tslint:disable-next-line:prefer-const
      let formData: FormData = new FormData();
      if (file.length > 0) {
        formData.append('file', file[0], file[0].name);
      }
      formData.append('modal', JSON.stringify(body));
      // tslint:disable-next-line:prefer-const
      let headers = new HttpHeaders();
      headers.append('Accept', 'application/json');
      // tslint:disable-next-line:prefer-const
      let options = { headers: headers };
      return this.http.post(BuyerService._BACKEND_API_LINK + '/update_buyer_profile', formData, options);
    }

  // Get Active Projects list
  getActiveProjects(username: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_agreed_projects_by_buyer/' + username, this.options);
  }

  // get the Archeive Project list and Completed Projects
  getArchiveProjectList(username: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_buyer_archive_list/' + username, this.options);
  }

  // get Project Information qa  Service Request details
  getServiceRequestDetailsByProjectId(project_id, user_id): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_service_request_details/' + project_id + '/' + user_id,
      this.options);
  }

  // Get Buyer Information By Buyer Id
  getBuyerInformationByBuyerId(buyer_id: number): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_buyer_information/' + buyer_id, this.options);
  }

  // Get Buyer Information By Email Id
  getBuyerInformationByEmailId(email: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_buyer_information_by_email/' + email, this.options);
  }

  // get Business Information
  getBussinessDetailInfomation(bussinessid: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_get_quick_facts/' + bussinessid, this.options)
      ;
  }
  // get the Profile Details.
  getSellerProfileDetails(username: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_seller_profile/' + username, this.options)
      ;
  }

  // get Previous workers images
  getPreviousWorkImages(username: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_seller_previous_work_images/' + username, this.options)
      ;
  }

  // Get service categories
  getServiceCategories(): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_service_categories/1', this.options);
  }

  // Get service categories
  getAllSellersServiceCategories(): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_all_sellers_in_service_request', this.options);
  }

  validateAddrees(body: any) {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_validate_address',
      body, this.options);
  }

  // Post the Service Request
  // createServiceRequest(body: any) {
  //   return this.http.post(BuyerService._BACKEND_API_LINK + '/create_service_request',
  //     body, this.options);
  // }

  // Request the create service
  createServiceRequest(file, body: any) {
    // tslint:disable-next-line:prefer-const
    let formData: FormData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append('file', file[i], file[i].name);
    }
    // formData.append('file', file);
    formData.append('modal', JSON.stringify(body));
    // tslint:disable-next-line:prefer-const
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    // tslint:disable-next-line:prefer-const
    let options = { headers: headers };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_create_service_request', formData, options);
  }

  // sending Notification to Preferred sellers
  sendNotificationToPreferredSellers(projectObj) {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_send_notification_to_preferred_seller',
      projectObj, this.options);
  }

  // Upload files into the project
  uploadagreementbrowsefile(projectid: number, file: any): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('project_id', projectid.toString());
    // tslint:disable-next-line:prefer-const
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    // tslint:disable-next-line:prefer-const
    let options = { headers: headers };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_upload_agreement_work_files', formData, options);
  }

  // Get the Active Request projects
  getActiveRequestProjects(username: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_service_requests_by_buyer/' + username, this.options);
  }


  // get Project Information by Project Id
  getProjectInformationByProjectId(projectid: number): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_project_information/' + projectid, this.options);
  }

  // files - get activity files (files attached during chat)
  // getActivityFiles(projectId: number, seller_id: number,
  //   activity_type: string, fileType: string, startNo: number, qty: number): Observable<any> {
  //   return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_activity_files/1/' + projectId + '/' + seller_id + '/'
  //     + activity_type + '/' + fileType + '/' + startNo + '/' + qty, this.options);
  // }

  getActivityFiles(projectId: number, seller_id: number,
    activity_type: string, fileType: string, startNo: number, qty: number): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_activity_files_for_buyer/1/' + projectId + '/' + seller_id + '/'
      + activity_type + '/' + fileType + '/' + startNo + '/' + qty, this.options);
  }

  // Get the summary details By Passing <string:buyer_username>/<int:seller_id>/<int:project_id>
  get_service_request_summary(buyer_username: string, seller_id: number, project_id: number): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_service_request_summary/' + buyer_username
      + '/' + seller_id + '/' + project_id, this.options);
  }

  // messaging - uploading attached file into s3 bucket
  uploadAttachedFile(file: any, projectId: Number, seller_id: number, fileType: string, activityType: string,
    is_from_chat: boolean): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('activity_type', activityType);
    formData.append('project_id', projectId.toString());
    formData.append('uploaded_user_id', seller_id.toString());
    formData.append('file_type', this.getFileTypeByAdding(fileType));
    formData.append('is_from_chat', is_from_chat.toString());

    // tslint:disable-next-line:prefer-const
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    // tslint:disable-next-line:prefer-const
    let options = { headers: headers };

    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_upload_activity_files', formData, options);
  }

  // delete activity files
  deleteFiles(listToDelete: any[]): Observable<any> {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_delete_activity_files',
      { 'safety_box_file_ids': listToDelete }, this.options);
  }

  // get activity file as blob by file url
  getFileAsBlob(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl)
      .pipe(map((response) => {
        return response['_body'];
      }), catchError(this.handleError));
  }


  // get agreement
  getAgreementFilePath(projectid: number, sellerid: number): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_get_agreement_file_path/' + projectid + '/' + sellerid,
      this.options);
  }

  // Add New Agreement with Project
  public buyerProjectSignAgreement(agreement: any): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let body = JSON.stringify(agreement);
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_sign_agreement', body, this.options);
  }

  // get the agreement Information by One Agreemnt Id
  getAgreementInfomrationByagreementId(agreementid: number, is_additional_agreement): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_get_agreement_information/' + agreementid + '/' + is_additional_agreement,
      this.options);
  }

  // get the Agreement Id
  // getAgreementlinkByagreementId(agreementid: number, is_additional_agreement): Observable<any> {
  //   return this.http.get(BuyerService._BACKEND_API_LINK + '/show_agreement_sign_url/' + agreementid + '/' + is_additional_agreement,
  //     this.options);
  // }

  // get the notification after archive the project list
  get_project_Archive_Notification(buyerid): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_get_project_archive_notification/' + buyerid,
      this.options);
  }

  // update the read archive list by Buyer
  public updateProjectArchiveNotificationstatus(projectids): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let body = { project_id_list: projectids };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_update_project_archive_notification_status', body,
      this.options);
  }


  //#region  Signicat Implementation Start

  // Get the Buyer Infomation
  getBuyerInformation(buyer_id): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_buyer_profile/' + buyer_id,
      this.options);
  }

  //  STEP 1: Call authorize using method "sbid-inapp"
  // logn buyer by Signicat
  loginBuyerByBankId(bankid: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_login_web/' + bankid,
      this.options);
  }

  // Register the Bank Id -- Infomations..
  registerBuyerByBankId(bankid: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/check_buyer_in_db_web/' + bankid,
      this.options);
  }

  // Final Regsitration - Update User Information
  registerBuyerInformationWithBankId(file, body: any): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let formData: FormData = new FormData();
    if (file.length > 0) {
      formData.append('file', file[0], file[0].name);
    }
    formData.append('modal', JSON.stringify(body));
    // tslint:disable-next-line:prefer-const
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    // tslint:disable-next-line:prefer-const
    let options = { headers: headers };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/add_buyer_in_db_web', formData, options);
  }

  // Check email exist or not
  public checkBuyerEmailExitsOrNot(username: string): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_email_exist/' + username, this.options);

  }

  // Update the Signicat Status According to the verify Result
  updateSignicatSignInStatus(requestid, taskid, status, is_additional_agreement,
    active_request_safety_box_link, active_project_safety_box_link): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let body = {
      request_id: requestid,
      agreement_status: status,
      task_id: taskid,
      is_additional_agreement: is_additional_agreement,
      active_project_safety_box_link: active_project_safety_box_link
    };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/update_buyer_sign_agreement', body, this.options);
  }

  // Validate the Review
  validateReviewByToken(review_token): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/validate_review_token/' + review_token, this.options);
  }

  // Submit the Review By Sending
  addreviewbyemailLink(body) {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/update_reviews_from_buyer', body, this.options);
  }

  // get the Seller Information using Token
  getSellerInformationUsingReviewToken(review_token): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/get_seller_project_details_by_token/' + review_token,
      this.options);
  }

  // get the Seller Information using Token
  getSellerReviewInformationList(sellerusername): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_project_reviews_for_buyer/' + sellerusername, this.options);
  }

  // Decline the Agreement by Buyer
  agreementDecline(agreementid, reason_comment, is_additional_agreement,
    active_request_safety_box_link, active_project_safety_box_link) {
    // tslint:disable-next-line:prefer-const
    let body = {
      agreement_id: agreementid,
      reason_for_rejection: reason_comment,
      is_additional_agreement: is_additional_agreement,
      active_request_safety_box_link: active_request_safety_box_link,
      active_project_safety_box_link: active_project_safety_box_link
    };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/decline_agreement_by_buyer', body, this.options);
  }

  getBuyerReviewInformation(buyer_email, project_id, seller_id): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_single_project_review_for_seller/' + buyer_email + '/'
      + project_id + '/' + seller_id, this.options);
  }

  // Submit the Review By Overview Tab
  reviewSubmitFromOverview(body) {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/update_review_from_buyer_from_safetybox', body, this.options);
  }

  // Decline the Seller By Buyer
  declineSellerByBuyer(buyer_id, seller_id, project_id, service_request_link) {
    const body = {
      buyer_id: buyer_id,
      seller_id: seller_id,
      project_id: project_id,
      service_request_link: service_request_link
    };
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_decline_seller', body,
      this.options);
  }

  //#endregion

  //#region Get Generic Kitchen Details
  getGeneralizedQuestionSet(serviceCategory_id, subCategoryIndex): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_service_request_questions/'
      + serviceCategory_id + '/' + subCategoryIndex,
      this.options);
  }

  // get the Question set by project ID
  getQuestionSetByProjectId(project_id): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/buyer_show_service_request_questions_by_project_id/'
      + project_id, this.options);
  }

  getCertificateForSellerBySellerId(seller_id): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/show_seller_certificates_for_buyer/'
      + seller_id, this.options);
  }

  //#endregion

  // get the Internal Project Information by Token
  getInternalProjectInformation(token): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/get_internal_project_details_using_token/'
      + token, this.options);
  }

  // update the Information
  updateExtenalProjectInformation(token, buyer_id): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/update_buyer_in_internal_project/'
      + token + '/' + buyer_id, this.options);
  }

  // Send Push notification
  sendPushNotification(body): Observable<any> {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_send_firebase_chat_push_notification_android',
      body, this.options);
  }

  sendPushNotification_ios(body): Observable<any> {
    return this.http.post(BuyerService._BACKEND_API_LINK + '/buyer_send_firebase_chat_push_notification_ios',
      body, this.options);
  }

  // test protected
  protectedmethodtest(): Observable<any> {
    return this.http.get(BuyerService._BACKEND_API_LINK + '/protected',
      this.options);
  }




  //#region  File Icons and Type Find
  getFileTypeByAdding(filetype: string): string {
    switch (filetype.toLowerCase()) {
      case 'image':
        return 'image';
      case 'doc':
        return 'doc';
      case 'pdf':
        return 'doc';
      case 'key':
        return 'doc';
      case 'numbers':
        return 'doc';
      case 'pages':
        return 'doc';
      case 'ppt':
        return 'doc';
      case 'txt':
        return 'other';
      case 'excel':
        return 'doc';
      case 'audio':
        return 'audio';
      case 'video':
        return 'video';
      case 'other':
        return 'other';
      default:
        return 'other';

    }
  }

  getThumbnailImages(filetype: string, fileurl: string): string {
    // determine file type
    switch (filetype.toLowerCase()) {
      case CommonConstants.FILE_TYPE_IMAGE:
        return fileurl;

      case CommonConstants.FILE_TYPE_DOC:
        // tslint:disable-next-line:prefer-const
        let filetypefind = fileurl.split('.').splice(-1)[0].toLocaleLowerCase();
        switch (this.getMainFileType(filetypefind.toLocaleLowerCase())) {
          case CommonConstants.FILE_TYPE_EXCEL:
            return '../assets/images/excel.png';

          case CommonConstants.FILE_TYPE_KEY:
            return '../assets/images/key-icon.png';

          case CommonConstants.FILE_TYPE_NUMBERS:
            return '../assets/images/numbers-icon.png';

          case CommonConstants.FILE_TYPE_PAGES:
            return '../assets/images/pages-icon.png';

          case CommonConstants.FILE_TYPE_PDF:
            return '../assets/images/pdf-icon.png';

          case CommonConstants.FILE_TYPE_PPT:
            return '../assets/images/ppt-icon.png';

          case CommonConstants.FILE_TYPE_TXT:
            return '../assets/images/text-icon.png';
          default:
            return '../assets/images/doc.png';
        }

      case CommonConstants.FILE_TYPE_EXCEL:
        return '../assets/images/excel.png';

      case CommonConstants.FILE_TYPE_KEY:
        return '../assets/images/key-icon.png';

      case CommonConstants.FILE_TYPE_NUMBERS:
        return '../assets/images/numbers-icon.png';

      case CommonConstants.FILE_TYPE_PAGES:
        return '../assets/images/pages-icon.png';

      case CommonConstants.FILE_TYPE_PDF:
        return '../assets/images/pdf-icon.png';

      case CommonConstants.FILE_TYPE_PPT:
        return '../assets/images/ppt-icon.png';

      case CommonConstants.FILE_TYPE_TXT:
        return '../assets/images/text-icon.png';

      case CommonConstants.FILE_TYPE_AUDIO:
        return '../assets/images/audio.png';

      case CommonConstants.FILE_TYPE_VIDEO:
        return '../assets/images/video.png';
      case CommonConstants.FILE_TYPE_OTHER:
        // tslint:disable-next-line:prefer-const
        let filetypefindother = fileurl.split('.').splice(-1)[0].toLocaleLowerCase();
        switch (this.getMainFileType(filetypefindother.toLocaleLowerCase())) {
          case CommonConstants.FILE_TYPE_EXCEL:
            return '../assets/images/excel.png';

          case CommonConstants.FILE_TYPE_KEY:
            return '../assets/images/key-icon.png';

          case CommonConstants.FILE_TYPE_NUMBERS:
            return '../assets/images/numbers-icon.png';

          case CommonConstants.FILE_TYPE_PAGES:
            return '../assets/images/pages-icon.png';

          case CommonConstants.FILE_TYPE_PDF:
            return '../assets/images/pdf-icon.png';

          case CommonConstants.FILE_TYPE_PPT:
            return '../assets/images/ppt-icon.png';

          case CommonConstants.FILE_TYPE_TXT:
            return '../assets/images/text-icon.png';
          default:
            return '../assets/images/doc.png';
        }

      default:
        return '../assets/images/doc.png';
    }
  }


  getMainFileType(extention: string): string {
    switch (extention) {
      case 'docx':
        return 'doc';
      case 'doc':
        return 'doc';
      case 'docm':
        return 'doc';
      case 'dotx':
        return 'doc';
      case 'dotm':
        return 'doc';
      case 'docb':
        return 'doc';
      case 'pdf':
        return 'pdf';
      case 'key':
        return 'key';
      case 'numbers':
        return 'numbers';
      case 'pages':
        return 'pages';
      case 'ppt':
        return 'ppt';
      case 'pot':
        return 'ppt';
      case 'pps':
        return 'ppt';
      case 'pptx':
        return 'ppt';
      case 'pptm':
        return 'ppt';
      case 'potx':
        return 'ppt';
      case 'potm':
        return 'ppt';
      case 'ppam':
        return 'ppt';
      case 'ppsx':
        return 'ppt';
      case 'ppsm':
        return 'ppt';
      case 'sldx':
        return 'ppt';
      case 'sldm':
        return 'ppt';

      case 'txt':
        return 'txt';
      case 'rtf':
        return 'txt';
      case 'tex':
        return 'txt';
      case 'xls':
        return 'excel';

      case 'xlt':
        return 'excel';
      case 'xlm':
        return 'excel';
      case 'xlsx':
        return 'excel';
      case 'xlsm':
        return 'excel';
      case 'xltx':
        return 'excel';
      case 'xltm':
        return 'excel';
      case 'xlsb':
        return 'excel';
      case 'xla':
        return 'excel';
      case 'xlam':
        return 'excel';
      case 'xll':
        return 'excel';
      case 'xlw':
        return 'excel';
      case 'csv':
        return 'excel';

    }
    return null;
  }


  getSubCategoryType(categoryId: string): Array<any> {
    let listOfItem: Array<any> = [];
    switch (categoryId) {
      case '1':
        listOfItem = ['Bygga nytt kök från grunden',
          'Delvis renovering av befintligt kök', 'Totalrenovering av befintligt kök'];
        return listOfItem;
      case '2':
        listOfItem = ['Bygga nytt badrum', 'Totalrenovering'];
        return listOfItem;
      case '3':
        listOfItem = ['Lägga nytt golv', 'Golvrenovering'];
        return listOfItem;
      case '4':
        listOfItem = ['Bygga ny vägg', 'Väggrenovering'];
        return listOfItem;
      case '5':
        listOfItem = ['Bygga nytt innertak', 'Renovering av innertak'];
        return listOfItem;
      case '6':
        listOfItem = ['Ny målning', 'Renovering målning inomhus'];
        return listOfItem;
      case '7':
        listOfItem = ['Nyinstallation av el', 'Reparation av el'];
        return listOfItem;
      case '8':
        listOfItem = ['Nyinstallation av VVS', 'Reparation av VVS'];
        return listOfItem;
      case '9':
        listOfItem = ['Nybyggnation', 'Renovering'];
        return listOfItem;
      case '10':
        listOfItem = ['Nyinstallation av fönster / dörrar', 'Renovering av fönster / dörrar'];
        return listOfItem;

    }
    return null;
  }

  getkitchenProjectType() {
    // tslint:disable-next-line:prefer-const
    let kitchenProjects: Array<any> = [];
    kitchenProjects = ['Bygga nytt kök från grunden',
      'Delvis renovering av befintligt kök', 'Totalrenovering av befintligt kök'];
    return kitchenProjects;
  }
  getTypeOfKitchenModule() {
    // tslint:disable-next-line:prefer-const
    let kitchenProjects: Array<any> = [];
    kitchenProjects = [
      'Tibrokök',
      'Vedum',
      'Kungsäter kök',
      'Arredo3',
      'Arrital Cucine',
      'ARXI - Tecnocucina',
      'Binova',
      'Canadaköket',
      'Claessonkök',
      'Creoform Kök',
      'Electrolux Home',
      'Epoq',
      'Himle Kök',
      'Härjedalskök',
      'IKEA',
      'Interhem',
      'Invita',
      'Kitchn',
      'Kvik',
      'Köksfabriken',
      'Marbodal',
      'Mälarkök',
      'Noblessa Kök',
      'Novaflex',
      'Perfect Inredning',
      'Siematic',
      'Smedstorp',
      'Strömsdal Kök & Interiör',
      'Unique Wood',
      'Apparat Kök',
      'Ballingslöv',
      'Bomanskök',
      'Bulthaup',
      'Geco',
      'Hagaköket',
      'HTH',
      'Infra Kök',
      'Kvänum Kök',
      'Köksskräddarn',
      'Lidhults Kök',
      'LIJO Kök',
      'Lundins Kök',
      'Länghems kök',
      'Mobalpa',
      'Modexa',
      'NordDesign',
      'Poggenpohl',
      'Puustelli',
      'Scandinavian Kitchen',
      'Storsjökök'
    ];
    return kitchenProjects;
  }


  getKitchenObjectByJson() {
    // tslint:disable-next-line:prefer-const
    let form1 = [{
      'question': 'Projektnamn',
      'sub_question': '',
      'required_oprion': true,
      'type': 'text',
      'modal': 'project_name',
      'max_limit': '0',
      'default_answer': 'Projektnamn',
      'value': null,
      'position': 'vertical',
      'screensize': 'half',
      'order': 1,
      'validationmodel': 'projectname',
      'validationmessage': 'Fältet är obligatoriskt',
      'version': 0

    },
    {
      'question': 'Vilken typ av projekt gäller det?',
      'sub_question': '',
      'required_oprion': true,
      'type': 'dropdown',
      'modal': 'kitchen_scope_kitchen_project_type',
      'max_limit': '',
      'default_answer': 'Välj',
      'value': ['Välj', 'Bygga nytt kök från grunden', 'Delvis renovering av befintligt kök', 'Totalrenovering av befintligt kök'],
      'position': 'vertical',
      'screensize': 'half',
      'order': 2,
      'validationmodel': 'projecttypevalue',
      'validationmessage': 'Fältet är obligatoriskt',
      'version': 0
    },
    {
      'question': 'Välj typ av kök?',
      'required_oprion': false,
      'type': 'dropdown',
      'modal': 'kitchen_scope_is_module',
      'max_limit': '',
      'default_answer': 'horizontal',
      'screensize': 'half',
      'value': ['Välj', 'Modul', 'Platsbyggd'],
      'position': 'left',
      'order': 3,
      'validationmodel': 'scopemodule',
      'validationmessage': '',
      'version': 0,
      'sub_question_1': {
        'question': 'Välj typ av kök?',
        'required_oprion': false,
        'type': 'dropdown',
        'modal': 'kitchen_scope_module_type',
        'max_limit': '',
        'default_answer': 'Välj typ av modul',
        'value': ['Välj typ av modul', ''],
        'position': 'right',
        'version': 0,
        'validationmodel': 'scopemoduletype',
        'validationmessage': '',
      }
    },
    {
      'question': 'Beskriv arbetet som ska utföras',
      'sub_question': '',
      'required_oprion': true,
      'type': 'textarea',
      'modal': 'scope_work_description',
      'max_limit': 1000,
      'default_answer': 'Beskriv här övergripande vad du vill göra. I nästa steg kan du om du vill specificera mer exakt vad som ska ingå.',
      'value': null,
      'position': 'vertical',
      'screensize': 'full',
      'order': 5,
      'validationmodel': 'workdescription',
      'validationmessage': 'Fältet är obligatoriskt',
      'version': 0

    },
    {
      'question': 'Vad ska inkluderas i prisförlaget?',
      'sub_question': '',
      'required_oprion': true,
      'type': 'dropdown',
      'modal': 'scope_price_offer',
      'max_limit': '0',
      'default_answer': 0,
      'value': ['Välj', 'Enbart arbete', 'Arbetskostnad och köksinredning', 'Arbetskostnad och byggmaterial',
        'Arbetskostnad, köksinredning samt byggmaterial'],
      'position': 'horizontal',
      'screensize': 'half',
      'order': 4,
      'validationmodel': 'scopepriceoffer',
      'validationmessage': '  Fältet är obligatoriskt',
      'version': 0
    },
    {
      'question': 'Vad ska inkluderas i prisförlaget?',
      'sub_question': '',
      'required_oprion': false,
      'type': 'dropdown',
      'modal': 'scope_project_budget',
      'max_limit': '0',
      'default_answer': 0,
      'value': ['Välj', '0 – 10 000 kr', '10 001 – 50 000 kr', '50 001 – 100 000 kr',
        '100 001 – 250 000 kr', '250 001 – 500 000 kr', ' > 500 000 kr'],
      'position': 'horizontal',
      'screensize': 'half',
      'order': 4,
      'validationmodel': 'scopeprojectbudget',
      'validationmessage': '  Fältet är obligatoriskt',
      'version': 0

    },
    {
      'question': 'Adress där arbetet ska utföras',
      'sub_question': '',
      'required_oprion': true,
      'type': 'address',
      'modal': 'scope_street_name',
      'max_limit': '0',
      'default_answer': 'Adress',
      'value': null,
      'position': 'vertical',
      'screensize': 'full',
      'order': 4,
      'validationmodel': 'projectstreetname',
      'validationmessage': '  Fältet är obligatoriskt',
      'version': 0

    },
    {
      'question': 'Bifoga filer',
      'sub_question': '',
      'required_oprion': false,
      'type': 'uploadimage',
      'modal': 'scope_upload_links',
      'max_limit': '0',
      'default_answer': 'Projektnamn',
      'value': null,
      'position': 'vertical',
      'screensize': 'full',
      'order': 4,
      'validationmodel': 'projectstreetname',
      'validationmessage': '  Fältet är obligatoriskt',
      'version': 0

    }];

    // tslint:disable-next-line:prefer-const
    let secondform: [
      {
        'question': 'Ungefärlig golvyta i kvm<',
        'sub_question': '',
        'required_oprion': false,
        'type': 'inputnumber',
        'modal': 'kitchen_details_floor_area',
        'max_limit': '0',
        'default_answer': 'Ungefärlig golvyta',
        'value': null,
        'position': 'horizontal',
        'screensize': 'half',
        'order': 1,
        'validationmodel': 'floorarea',
        'validationmessage': '  Fältet är obligatoriskt',
        'version': 0

      },
      {
        'question': 'Ungefärlig takhöjd i cm<',
        'sub_question': '',
        'required_oprion': false,
        'type': 'inputnumber',
        'modal': 'kitchen_details_ceiling_height',
        'max_limit': '0',
        'default_answer': 'Ungefärlig takhöjd (cm)',
        'value': null,
        'position': 'horizontal',
        'screensize': 'half',
        'order': 2,
        'validationmodel': 'ceilingarea',
        'validationmessage': '  Fältet är obligatoriskt',
        'version': 0

      },
      {
        'question': 'Finns lokal avstängning av vatten/vvs?',
        'sub_question': '',
        'required_oprion': false,
        'type': 'dropdown',
        'modal': 'kitchen_details_is_there_a_local_shutdown_of_water_plumbing',
        'max_limit': '0',
        'default_answer': 'Välj',
        'value': ['Välj', 'Ja', 'Nej', 'Osäker/Vill rådfråga'],
        'position': 'horizontal',
        'screensize': 'half',
        'order': 3,
        'validationmodel': 'waterplumping',
        'validationmessage': '  Fältet är obligatoriskt',
        'version': 0

      },
      {
        'question': 'Finns golvvärme?',
        'sub_question': '',
        'required_oprion': false,
        'type': 'dropdown',
        'modal': 'kitchen_details_is_floor_heating_available',
        'max_limit': '0',
        'default_answer': 'Välj',
        'value': ['Välj', 'Ja', 'Nej'],
        'position': 'horizontal',
        'screensize': 'half',
        'order': 3,
        'validationmodel': 'isfloorHeatingAvailable',
        'validationmessage': '  Fältet är obligatoriskt',
        'version': 0

      },
      {
        'question': 'Vill du flytta något utav följande från dess nuvarande position',
        'sub_question': '',
        'required_oprion': false,
        'type': 'multiselect',
        'modal': 'kitchen_details_do_you_want_to_move',
        'max_limit': '0',
        'default_answer': 'Välj',
        'value': ['Golvbrunn', 'Avlopp', 'Ventilation'],
        'position': 'vertical',
        'screensize': 'half',
        'order': 3,
        'validationmodel': '',
        'validationmessage': '  Fältet är obligatoriskt',
        'version': 0

      }];

    // tslint:disable-next-line:prefer-const
    let questionset: [
      {
        'question': 'Installation av köksinredning',
        'required_oprion': false,
        'type': 'checkbox',
        'modal': 'kitchen_details_is_choosen_work',
        'default_answer': 'Välj',
        'value': true,
        'order': 1,
        'version': 0,
        'disabledvalue': 'is_detailsChoosenwork'
        'searchvalue': 'kitchen_details_cw_is',
        'sub_question': [
          {
            'question': ' Bänkskåp',
            'type': 'checkbox',
            'modal': 'kitchen_details_cw_is_base_cabinets',
            'default_answer': 'Välj',
            'value': true,
            'order': 1,
            'version': 0,
            'sub_textbox': {
              'type': 'inputnumber',
              'modal': 'kitchen_details_cw_cabinets_qty',
              'default_answer': 'Kvantitet',
              'max_limit': '0',
              'version': 0
            }
          },
          {
            'question': ' Handtag',
            'type': 'checkbox',
            'modal': 'kitchen_details_cw_is_handle',
            'default_answer': 'Välj',
            'value': true,
            'order': 1,
            'version': 0,
            'sub_textbox': {
              'type': 'inputnumber',
              'modal': 'kitchen_details_cw_handle_qty',
              'default_answer': 'Kvantitet',
              'max_limit': '0',
              'version': 0
            }
          }
        ],
      }
    ];
    return form1;
  }

  //#endregion
  /** Response and Errors Receive ************************ Start  */

  // Response split
  private extractRequests(res: Response) {
    if (res['_body']) {
      const data = JSON.parse(res['_body']);
      if (data != null && data.value) { return data.value; }
      // tslint:disable-next-line:one-line
      else { return data; }
    }
  }

  // Error Handler
  private handleError(error: Response | any) {
    // check JWT expired error - if expired refresh page, this'll redirect to login automatically by auth guard
    if (error.message === 'No JWT present or has expired') {
      location.reload();
    }

    let errMsg: string;
    if (error instanceof Response) {
      // const body = error.json() || '';
      const err = JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    // console.error(errMsg);
    return Observable.throw(errMsg);
  }
  /** Response and Errors Receive ************************ END  */
}
