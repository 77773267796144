import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuyerModule } from './buyer/buyer.module';
import { BuyerEventManager } from './buyer/buyer-event.manager';
import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AuthenticationModule } from './authentication/authentication.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MobileRedirectionComponent } from './mobile-redirection/mobile-redirection.component';
import { CustomHttpInterceptorService } from './custominterceptor';
import localeSe from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeSe);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
 }

@NgModule({
  declarations: [
    AppComponent,
    MobileRedirectionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }}),
    AppRoutingModule,
    BuyerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AuthenticationModule
  ],
  providers: [
    { provide: LOCALE_ID,
    useValue: 'sv-SE'
  },
    BuyerEventManager, BuyerModule,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
