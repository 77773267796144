import { Component, OnInit, SimpleChanges, OnChanges, Input } from '@angular/core';
import { BuyerService } from 'src/app/buyer/service/buyer.service';
import { BuyerEventManager } from 'src/app/buyer/buyer-event.manager';

@Component({
  selector: 'app-common-scope',
  templateUrl: './common-scope.component.html',
  styleUrls: ['./common-scope.component.scss']
})
export class CommonScopeComponent implements OnInit, OnChanges  {

  @Input() data: any;
  @Input() questionObject: any;
  @Input() iseditedmessage: boolean;
  service_category_name: string;
  serviceCategories: Array<any> = [];
  categoryId: number;
  isshowMessage: boolean;
  questionResponseObject: any;
  formone: Array<any>;
  commonObject: any = {
    project_name: null,
    sub_category_position: null
  };

  constructor(private buyerService: BuyerService, private buyereventManager: BuyerEventManager) {
    this.buyereventManager.sendParentToChildEmitter.subscribe((mode) => {
      if (mode != null) {
        this.commonObject = mode;
        this.isshowMessage = this.iseditedmessage;
        this.service_category_name = this.commonObject.service_category_name;
        this.categoryId = this.commonObject.service_category_id;
      }
    });
  }

  ngOnInit() {
    // this.loadServiceCategories();
  }

  // Load All the Service categories
  loadServiceCategories() {
    this.buyerService.getServiceCategories().subscribe(response => {
      this.serviceCategories = response;
      if (this.categoryId != null || this.categoryId !== undefined) {
        this.getServiceCategoryName(this.categoryId);
      }
    }, err => {
      console.log(err);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.data != null || this.data !== undefined) {
    //   this.commonObject = this.data;
    //   this.isshowMessage = this.iseditedmessage;
    //   this.service_category_name = this.data.service_category_name;
    //   this.categoryId = this.data.service_category_id;
    // }

    if (this.questionObject != null) {
        this.questionResponseObject = JSON.parse(this.questionObject.question_set);
        this.formone = this.questionResponseObject.formone;
    }
  }

  getSubCategoryProjectType(index) {
    // tslint:disable-next-line:prefer-const
    let subcategorylist: Array<any> = [];
    if (this.categoryId != null || this.categoryId !== undefined) {
      subcategorylist = this.buyerService.getSubCategoryType(this.categoryId.toString());
      return subcategorylist[Number(index - 1)];
    }
   return '';
  }


  getValues(position, values) {
    if (this.commonObject[position] != null) {
      return values[Number(this.commonObject[position])];
    }
    return '';
  }

  // get Category Name
  getServiceCategoryName(index) {
    // tslint:disable-next-line:prefer-const
    let cate = this.serviceCategories.filter(item => item.service_category_id === Number(index));
    if (cate.length > 0) {
      return cate[0].service_category_name;
    } else {
      return '';
    }
  }


}
