import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-common-details',
  templateUrl: './common-details.component.html',
  styleUrls: ['./common-details.component.scss']
})
export class CommonDetailsComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() questionObject: any;
  version: number;
  questionResponseObject: any = {
    formone: [],
    formtwo: [],
    questionset: []
  };
  formtwo: Array<any>;
  commonObject: any = {
    project_name: null,
    sub_category_position: null
  };

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data !== null || this.data !== undefined) {
      this.commonObject = this.data;
    }
    if (this.questionObject != null) {
      this.questionResponseObject = JSON.parse(this.questionObject.question_set);
      this.formtwo = this.questionResponseObject.formtwo;
    }
  }

  getValues(position, values) {
    if (this.commonObject[position] != null) {
      return values[Number(this.commonObject[position])];
    }
    return '';
  }

  get_local_shutdown_of_water_plumbing(index) {
    if (Number(index) === 1) {
      return 'Ja';
    } else if (Number(index) === 2) {
      return 'Nej';
    } else if (Number(index) === 3) {
      return 'Osäker/Vill rådfråga';
    } else {
      return '';
    }
  }

  getseperatedArray(item) {
    if (item != null) {
      return item.split(',');
    } else {
      return [];
    }
  }

  kitchenchoosenwork_changesub(item, item2, item3) {

  }

  async checkVersion(item, version) {
    await Object.keys(this.commonObject).forEach(e => {
      if (e.indexOf(item) > -1) {
        if (e.indexOf('_version') > -1) {
          if (this.commonObject[e] > 1) {
            this[version] = true;
          }
        }
      }
    });
  }

}
