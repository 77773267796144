import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireAction } from 'angularfire2/database';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { FirebaseConstants } from './firebase-constants';
import { map } from 'rxjs/operators';
import { MessageModel } from './message.model';


@Injectable()
export class FirebaseHelperService {

  private messagesRef: AngularFireList<MessageModel>; // to set messages
  public messages: Observable<any[]>; // to get messages


  constructor(private db: AngularFireDatabase) { }

  //#region start for External Chat

  // save message to firebase database
  public sendMessage(projectId: number, sellerId: number, sellerUserId: number, messageObject: MessageModel) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    this.messagesRef = this.db.list(path);
    messageObject.sentTime = firebase.database.ServerValue.TIMESTAMP;
    this.messagesRef.push(messageObject);
    this.updateExternalMessageLog(projectId, sellerId, sellerUserId);
  }

  // Updating Log Path For External chat Messages
  public updateExternalMessageLog(projectId: number, sellerId: number, sellerUserId: number) {
    // tslint:disable-next-line:prefer-const
    let logpath = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Log' + '/' + sellerUserId + '/';
    this.db.list(logpath).set('lastReadTime', firebase.database.ServerValue.TIMESTAMP);
  }

  // get all messages from firebase database
  public getAllMessages(projectId: number, sellerId: number, buyerId: number) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    return this.db.list(path, ref => ref.orderByChild('sentTime')).snapshotChanges();
  }

  // get only latest message from firebase database
  public getLatestMessages(projectId: number, sellerId: number, buyerId: number) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    return this.db.list(path, ref => ref.orderByChild('sentTime').limitToLast(1)).snapshotChanges([]);
  }

  // get Unread Message Count for Each Project
  public getLatestLogTime(projectId: number, sellerId: number, sellerUserId: number) {
    // tslint:disable-next-line:prefer-const
    // let logpath = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Log' + '/' + sellerUserId;
    // return this.db.list(logpath, ref => ref.limitToLast(1)).snapshotChanges();
    // tslint:disable-next-line:prefer-const
    let logpath = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Log' + '/' + sellerUserId;
    return this.db.list(logpath, ref => ref.limitToLast(1)).query.once('value');
  }

  // get Unread Message Count for Each Project
  public getLatestUnReadMessages(projectId: number, sellerId: number, logtime) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    return this.db.list(path, ref => ref.orderByChild('sentTime').startAt(logtime)).query.once('value');
    // return this.db.list(path, ref => ref.orderByChild('sentTime').startAt(logtime)).snapshotChanges().pipe(map(items => {
    //   return items.map(a => {
    //     const data = a.payload.val();
    //     const key = a.payload.key;
    //     return { key, data };
    //   });
    // }));
  }

  //#endregion End for External Chat

  // IMPORTANT !!!! REMOVE THIS AFTER BUYER CHAT IMPLEMENT THIS IS TEMP TO CREATE SAMPLE BUYER SIDE CHAT
  // get filtered messages from firebase database
  public filterMessages(projectId: number, sellerId: number, buyerId: number, endAtTime: string, noOfMessages: number) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.EX_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';

    // first load - load latest messages
    // if (endAtTime == '') {
    //     this.messages = this.db.list(path, ref => ref.orderByChild('sentTime').limitToLast(noOfMessages)).valueChanges();
    // } else {
    // tslint:disable-next-line:max-line-length
    //     this.messages = this.db.list(path, ref => ref.orderByChild('sentTime').endAt(endAtTime).limitToLast(noOfMessages)).valueChanges();
    // }

    this.messages = this.db.list(path, ref => ref.orderByChild('sentTime')).valueChanges();
  }

  public filterMessagesByCoChat(projectId: number, sellerId: number, buyerId: number, endAtTime: string, noOfMessages: number) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';

    this.messages = this.db.list(path, ref => ref.orderByChild('sentTime')).valueChanges();
  }

  // Send message Co Workers to firebase database.
  public sendMessageToCoWorkers(projectId: number, sellerId: number, sellerUserId: number, messageObject: MessageModel) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    this.messagesRef = this.db.list(path);
    messageObject.sentTime = firebase.database.ServerValue.TIMESTAMP;
    const messagekeyId = this.messagesRef.push(messageObject).key;
    this.updateExternalMessageCoworkerLog(projectId, sellerId, sellerUserId);
    this.updateMessageReadStatusCoWorker(projectId, sellerId, sellerUserId, messagekeyId, 1);
  }

  // Updating Log Path For External chat Messages
  public updateExternalMessageCoworkerLog(projectId: number, sellerId: number, sellerUserId: number) {
    // tslint:disable-next-line:prefer-const
    let logpath = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Log' + '/' + sellerUserId + '/';
    this.db.list(logpath).set('lastReadTime', firebase.database.ServerValue.TIMESTAMP);
  }

  // Update Message Read Status
  public updateMessageReadStatusCoWorker(projectId: number, sellerId: number, userId: number, key: string, value: number) {
    // tslint:disable-next-line:prefer-const
    let logpathstatus = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'MessageStatus' + '/'
      + key + '_' + userId + '/';
    this.db.list(logpathstatus).set('status', value);
  }


  // get all messages from firebase database
  public getAllCoWorkerMessages(projectId: number, sellerId: number) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    return this.db.list(path, ref => ref.orderByChild('sentTime')).snapshotChanges();
  }
  // get only latest message from firebase database
  public getLatestCoWorkerMessages(projectId: number, sellerId: number) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    return this.db.list(path, ref => ref.orderByChild('sentTime').limitToLast(1)).snapshotChanges([]);
  }

  // get Unread Message Count for Each Project CoWorkers
  public getLatestCoWorkerLogTime(projectId: number, sellerId: number, sellerUserId: number) {
    // tslint:disable-next-line:prefer-const
    let logpath = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Log' + '/' + sellerUserId;
    return this.db.list(logpath, ref => ref.limitToLast(1)).snapshotChanges();
  }

  // get Unread Message Count for Each Project CoWorkers
  public getLatestCoWorkerUnReadMessages(projectId: number, sellerId: number, logtime) {
    // tslint:disable-next-line:prefer-const
    let path = FirebaseConstants.IN_MESSAGES_NODE + projectId + '_' + sellerId + '/' + 'Message';
    return this.db.list(path, ref => ref.orderByChild('sentTime').startAt(logtime)).snapshotChanges().pipe(map(items => {
      return items.map(a => {
        const data = a.payload.val();
        const key = a.payload.key;
        return { key, data };
      });
    }));
  }


}
