import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BuyerService } from '../../../service/buyer.service';

@Component({
  selector: 'app-sub-attachments',
  templateUrl: './sub-attachments.component.html',
  styleUrls: ['./sub-attachments.component.scss']
})
export class SubAttachmentsComponent implements OnInit, OnChanges {
  @Input() uploadlinks: Array<any> = [];
  upload_links: Array<any> = [];
  constructor(private buyerService: BuyerService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Array.isArray(this.uploadlinks)) {
      this.upload_links = this.uploadlinks;
    } else {
      this.upload_links = String(this.uploadlinks).split(',');
    }
  }


   // Get the Thummail
   getFileThumail(url) {
    // tslint:disable-next-line:prefer-const
    let extension = url.split('.').splice(-1)[0].toLocaleLowerCase();
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif' || extension === 'svg') {
      return url;
    }
    let mainFileType = this.buyerService.getMainFileType(extension);
     if (mainFileType == null) {
       if (extension === 'mp3' || extension === 'ogg' || extension === 'wav'
         || extension === 'webm' || extension === 'mmf' || extension === 'm4p' || extension === 'msv') {
         mainFileType = 'audio';
       } else if (extension === 'flv'
         || extension === 'mov' || extension === 'mp4' || extension === 'avi' || extension === 'mpeg' || extension === 'wmv') {
         mainFileType = 'video';
       } else {
         mainFileType = 'other';
       }
       return this.buyerService.getThumbnailImages(mainFileType, url);
     } else {
      return this.buyerService.getThumbnailImages(mainFileType, url);
     }

  }

  getFileNameSplit(url) {
    // tslint:disable-next-line:prefer-const
    let value = url.split('_X_').pop();
    return value;
  }


}
